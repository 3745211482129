import {
  AuthError,
  type AuthenticationResult,
  type EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  OIDC_DEFAULT_SCOPES,
  type PopupRequest,
  type SilentRequest,
  type SsoSilentRequest,
} from '@azure/msal-browser'
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useCallback, useEffect, useRef } from 'react'

import { makeStateVariable, useStateVariable } from 'utils/state/state'

// Auth State is captured in a global state variable. Primarily used for setting errors from
// the outside eg if token acquisition fails during AdminAPI-Calls or the AdminAPI returns
// unauthenticated errors
export const authState = makeStateVariable<[AuthenticationResult | null, AuthError | null]>([null, null])

export type AuthenticationHook = {
  acquireToken: (
    callbackInteractionType?: InteractionType | undefined,
    callbackRequest?: SilentRequest | undefined,
  ) => Promise<AuthenticationResult | null>
  error: AuthError | null
  login: (
    callbackInteractionType?: InteractionType | undefined,
    callbackRequest?: PopupRequest | SilentRequest,
  ) => Promise<AuthenticationResult | null>
  result: AuthenticationResult | null
}

/**
 * Custom implementation fo the `useMsalAuthentication`-Hook from `msal-react` which does not
 * clear its state correctly in all cases. It also provides a clearer interface and is easier to mock
 * in unit-tests.
 *
 * Example of edge-case not handled by original hook:
 * - user leaves window open over night, access and refresh tokens expire
 * - user comes back, reauthenticates in popup,
 * - error state of built-in hook is not cleared because it does not handle EventType.ACQUIRE_TOKEN_SUCCESS
 * - does not work well with our flow
 */
export function useAuthentication(
  interactionType: InteractionType,
  authenticationRequest: PopupRequest | SilentRequest,
): AuthenticationHook {
  const { inProgress, instance, logger } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const account = useAccount()
  const [result, error] = useStateVariable(authState)

  // Used to prevent state updates after unmount
  const mounted = useRef(true)
  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  // Boolean used to check if interaction is in progress in acquireTokenSilent fallback. Use Ref instead of state to prevent acquireToken function from being regenerated on each change to interactionInProgress value
  const interactionInProgress = useRef(inProgress !== InteractionStatus.None)
  useEffect(() => {
    interactionInProgress.current = inProgress !== InteractionStatus.None
  }, [inProgress])

  // Flag used to control when the hook calls login/acquireToken
  const shouldAcquireToken = useRef(true)
  useEffect(() => {
    if (error) {
      // Errors should be handled by consuming component
      shouldAcquireToken.current = false
      return
    }

    if (result) {
      // Token has already been acquired, consuming component/application is responsible for renewing
      shouldAcquireToken.current = false
      return
    }
  }, [error, result])

  const login = useCallback(
    async (
      callbackInteractionType?: InteractionType,
      callbackRequest?: PopupRequest,
    ): Promise<AuthenticationResult | null> => {
      const loginType = callbackInteractionType || interactionType
      const loginRequest = callbackRequest || authenticationRequest
      logger.verbose('useMsalAuthentication - Calling loginPopup')
      switch (loginType) {
        case InteractionType.Popup: {
          logger.verbose('useMsalAuthentication - Calling loginPopup')
          return instance.loginPopup(loginRequest as PopupRequest)
        }
        case InteractionType.Silent: {
          logger.verbose('useMsalAuthentication - Calling ssoSilent')
          return instance.ssoSilent(loginRequest as SsoSilentRequest)
        }
        default: {
          throw new AuthError('invalid_interaction_type', 'Invalid interaction type provided to login function')
        }
      }
    },
    [instance, interactionType, authenticationRequest, logger],
  )

  const acquireToken = useCallback(
    async (
      callbackInteractionType?: InteractionType,
      callbackRequest?: SilentRequest,
    ): Promise<AuthenticationResult | null> => {
      const fallbackInteractionType = callbackInteractionType || interactionType
      let tokenRequest: SilentRequest

      if (callbackRequest) {
        logger.trace('useMsalAuthentication - acquireToken - Using request provided in the callback')
        tokenRequest = {
          ...callbackRequest,
        }
      } else if (authenticationRequest) {
        logger.trace('useMsalAuthentication - acquireToken - Using request provided in the hook')
        tokenRequest = {
          ...authenticationRequest,
          scopes: authenticationRequest.scopes || OIDC_DEFAULT_SCOPES,
        }
      } else {
        logger.trace('useMsalAuthentication - acquireToken - No request object provided, using default request.')
        tokenRequest = {
          scopes: OIDC_DEFAULT_SCOPES,
        }
      }

      if (!tokenRequest.account && account) {
        logger.trace('useMsalAuthentication - acquireToken - Attaching account to request')
        tokenRequest.account = account
      }

      const getToken = async (): Promise<AuthenticationResult | null> => {
        logger.verbose('useMsalAuthentication - Calling acquireTokenSilent')
        return instance.acquireTokenSilent(tokenRequest).catch(async (error_: AuthError) => {
          if (error_ instanceof InteractionRequiredAuthError) {
            if (interactionInProgress.current) {
              logger.error(
                'useMsalAuthentication - Interaction required but is already in progress. Please try again, if needed, after interaction completes.',
              )
              // ! same as in useMsalAuthentication, but built-in error not exposed by library
              throw new AuthError(
                'unable_to_fallback_to_interaction',
                'Interaction is required but another interaction is already in progress. Please try again when the current interaction is complete.',
              )
            }
            logger.error('useMsalAuthentication - Interaction required, falling back to interaction')
            return login(fallbackInteractionType, tokenRequest)
          }

          throw error_
        })
      }

      return getToken()
        .then((response: AuthenticationResult | null) => {
          if (mounted.current) {
            authState([response, null])
          }
          return response
        })
        .catch((error_: AuthError) => {
          if (mounted.current) {
            authState([null, error_])
          }
          throw error_
        })
    },
    [instance, logger, authenticationRequest, interactionType, account, login],
  )

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS: {
          if (message.payload) {
            authState([message.payload as AuthenticationResult, null])
          }
          break
        }
        case EventType.LOGIN_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE:
        case EventType.SSO_SILENT_FAILURE: {
          if (message.error) {
            authState([null, message.error as AuthError])
          }
          break
        }
      }
    })
    logger.verbose(`useMsalAuthentication - Registered event callback with id: ${callbackId}`)

    return () => {
      if (callbackId) {
        logger.verbose(`useMsalAuthentication - Removing event callback ${callbackId}`)
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance, logger])

  useEffect(() => {
    if (shouldAcquireToken.current && inProgress === InteractionStatus.None) {
      shouldAcquireToken.current = false
      if (!isAuthenticated) {
        logger.info('useMsalAuthentication - No user is authenticated, attempting to login')
        login().catch(() => {
          // Errors are saved in state above
          return
        })
      } else if (account) {
        logger.info('useMsalAuthentication - User is authenticated, attempting to acquire token')
        acquireToken().catch(() => {
          // Errors are saved in state above
          return
        })
      }
    }
  }, [isAuthenticated, account, inProgress, login, acquireToken, logger])

  return { acquireToken, error, login, result }
}
