import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const DraftEditorFragmentDoc = gql`
    fragment DraftEditor on AdminUser {
  id
  name
  email
}
    `
export const AllLocalesFragmentDoc = gql`
    fragment AllLocales on TranslatedString {
  de
  en
  ja
}
    `
export const PictureInfoFragmentDoc = gql`
    fragment PictureInfo on Picture {
  id
  type
  url
}
    `
export const ReferencedPicturesFragmentDoc = gql`
    fragment ReferencedPictures on PictureConnection {
  edges {
    status
    node {
      ...PictureInfo
    }
  }
}
    ${PictureInfoFragmentDoc}`
export const BannerDraftInfoFragmentDoc = gql`
    fragment BannerDraftInfo on BannerDraft {
  editor {
    ...DraftEditor
  }
  title {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  link {
    ...AllLocales
  }
  updatedAt
  pictures {
    ...ReferencedPictures
  }
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}`
export const BannerInfoFragmentDoc = gql`
    fragment BannerInfo on Banner {
  editor {
    ...DraftEditor
  }
  title {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  link {
    ...AllLocales
  }
  updatedAt
  pictures {
    ...ReferencedPictures
  }
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}`
export const BannerDataFragmentDoc = gql`
    fragment BannerData on BannerData {
  id
  status
  draft {
    ...BannerDraftInfo
  }
  published {
    ...BannerInfo
  }
}
    ${BannerDraftInfoFragmentDoc}
${BannerInfoFragmentDoc}`
export const ContentContainerItemsFragmentDoc = gql`
    fragment ContentContainerItems on Node {
  id
}
    `
export const CuratedContentDraftInfoFragmentDoc = gql`
    fragment CuratedContentDraftInfo on CuratedContentDraft {
  editor {
    ...DraftEditor
  }
  updatedAt
  slug
  contentContainers {
    ...ContentContainerItems
  }
}
    ${DraftEditorFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CuratedContentInfoFragmentDoc = gql`
    fragment CuratedContentInfo on CuratedContent {
  editor {
    ...DraftEditor
  }
  slug
  contentContainers {
    ...ContentContainerItems
  }
}
    ${DraftEditorFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CuratedContentDataFragmentDoc = gql`
    fragment CuratedContentData on CuratedContentData {
  id
  status
  draft {
    ...CuratedContentDraftInfo
  }
  published {
    ...CuratedContentInfo
  }
}
    ${CuratedContentDraftInfoFragmentDoc}
${CuratedContentInfoFragmentDoc}`
export const CuratedSliderItemsFragmentDoc = gql`
    fragment CuratedSliderItems on Node {
  id
  ... on CoreArtist {
    name {
      en
    }
    pictures {
      ...ReferencedPictures
    }
  }
  ... on CoreGroup {
    name {
      en
    }
    pictures {
      ...ReferencedPictures
    }
  }
  ... on Album {
    title {
      en
    }
    pictures {
      edges {
        node {
          url
        }
      }
    }
  }
  ... on LiveConcert {
    title {
      en
    }
    pictures {
      ...ReferencedPictures
    }
  }
  ... on VodConcert {
    title {
      en
    }
    pictures {
      ...ReferencedPictures
    }
  }
  ... on Video {
    title {
      en
    }
    pictures {
      ...ReferencedPictures
    }
  }
  ... on CorePartner {
    name {
      en
    }
    type
    pictures {
      ...ReferencedPictures
    }
  }
}
    ${ReferencedPicturesFragmentDoc}`
export const CuratedSliderDraftInfoFragmentDoc = gql`
    fragment CuratedSliderDraftInfo on CuratedSliderDraft {
  editor {
    ...DraftEditor
  }
  headline {
    ...AllLocales
  }
  updatedAt
  type
  items {
    ...CuratedSliderItems
  }
  partyId
  visibleFor
  description
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${CuratedSliderItemsFragmentDoc}`
export const CuratedSliderInfoFragmentDoc = gql`
    fragment CuratedSliderInfo on CuratedSlider {
  editor {
    ...DraftEditor
  }
  headline {
    ...AllLocales
  }
  updatedAt
  type
  items {
    ...CuratedSliderItems
  }
  partyId
  visibleFor
  description
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${CuratedSliderItemsFragmentDoc}`
export const CuratedSliderDataFragmentDoc = gql`
    fragment CuratedSliderData on CuratedSliderData {
  id
  status
  draft {
    ...CuratedSliderDraftInfo
  }
  published {
    ...CuratedSliderInfo
  }
}
    ${CuratedSliderDraftInfoFragmentDoc}
${CuratedSliderInfoFragmentDoc}`
export const ReferencedItemFragmentDoc = gql`
    fragment ReferencedItem on Node {
  id
  ... on LiveConcert {
    isrc
    title {
      en
    }
  }
  ... on VodConcert {
    isrc
    title {
      en
    }
    airDate
  }
  ... on Video {
    title {
      en
    }
    type
  }
  ... on Album {
    title {
      en
    }
    upc
    releaseDate
  }
}
    `
export const StageItemFragmentDoc = gql`
    fragment StageItem on StageItem {
  title {
    ...AllLocales
  }
  subtitle {
    ...AllLocales
  }
  label {
    ...AllLocales
  }
  item {
    ...ReferencedItem
  }
}
    ${AllLocalesFragmentDoc}
${ReferencedItemFragmentDoc}`
export const StageDraftFragmentDoc = gql`
    fragment StageDraft on StageDraft {
  slug
  items {
    ...StageItem
  }
}
    ${StageItemFragmentDoc}`
export const StageFragmentDoc = gql`
    fragment Stage on Stage {
  id
  slug
  items {
    ...StageItem
  }
}
    ${StageItemFragmentDoc}`
export const StageDataFragmentDoc = gql`
    fragment StageData on StageData {
  id
  status
  draft {
    ...StageDraft
  }
  published {
    ...Stage
  }
}
    ${StageDraftFragmentDoc}
${StageFragmentDoc}`
export const PublicationErrorFieldsFragmentDoc = gql`
    fragment PublicationErrorFields on PublicationError {
  reason
}
    `
export const UniqueIsrcPublicationErrorFieldsFragmentDoc = gql`
    fragment UniqueIsrcPublicationErrorFields on UniqueIsrcPublicationError {
  reason
  isrc
  conflictingNodes {
    id
    type
  }
}
    `
export const ValidationErrorFieldsFragmentDoc = gql`
    fragment ValidationErrorFields on ValidationError {
  field
  validation
}
    `
export const AdminApiErrorFragmentDoc = gql`
    fragment AdminApiError on ApiError {
  __typename
  message
  path
  ... on PublicationError {
    ...PublicationErrorFields
  }
  ... on UniqueIsrcPublicationError {
    ...UniqueIsrcPublicationErrorFields
  }
  ... on ValidationError {
    ...ValidationErrorFields
  }
  ... on AuthenticationError {
    code
  }
}
    ${PublicationErrorFieldsFragmentDoc}
${UniqueIsrcPublicationErrorFieldsFragmentDoc}
${ValidationErrorFieldsFragmentDoc}`
export const ReferencedPartnersFragmentDoc = gql`
    fragment ReferencedPartners on CorePartnerConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
      type
    }
  }
}
    `
export const ReferencedGroupsFragmentDoc = gql`
    fragment ReferencedGroups on CoreGroupConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const ReferencedArtistsFragmentDoc = gql`
    fragment ReferencedArtists on CoreArtistConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const ReferencedArtistsWithRoleFragmentDoc = gql`
    fragment ReferencedArtistsWithRole on CoreArtistWithRoleConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
    role {
      id
      type
      subtype
    }
  }
}
    `
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on CoreRole {
  id
  type
  subtype
  displayName
}
    `
export const ReferencedRolesFragmentDoc = gql`
    fragment ReferencedRoles on CoreRoleConnection {
  edges {
    status
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const ReferencedListRolesFragmentDoc = gql`
    fragment ReferencedListRoles on CoreRoleListConnection {
  edges {
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const ReferencedAlbumsFragmentDoc = gql`
    fragment ReferencedAlbums on AlbumConnection {
  edges {
    status
    node {
      id
      title {
        ...AllLocales
      }
    }
  }
}
    ${AllLocalesFragmentDoc}`
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `
export const StreamInfoFragmentDoc = gql`
    fragment StreamInfo on Stream {
  id
  url
  mvVideoUrl
}
    `
export const SaveBannerDraftDocument = gql`
    mutation saveBannerDraft($input: CreateOrUpdateBannerDraftInput!) {
  createOrUpdateBannerDraft(input: $input) {
    result {
      ...BannerData
    }
  }
}
    ${BannerDataFragmentDoc}`
export type SaveBannerDraftMutationFn = Apollo.MutationFunction<
  Types.SaveBannerDraftMutation,
  Types.SaveBannerDraftMutationVariables
>

/**
 * __useSaveBannerDraftMutation__
 *
 * To run a mutation, you first call `useSaveBannerDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBannerDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBannerDraftMutation, { data, loading, error }] = useSaveBannerDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBannerDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveBannerDraftMutation, Types.SaveBannerDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveBannerDraftMutation, Types.SaveBannerDraftMutationVariables>(
    SaveBannerDraftDocument,
    options,
  )
}
export type SaveBannerDraftMutationHookResult = ReturnType<typeof useSaveBannerDraftMutation>
export type SaveBannerDraftMutationResult = Apollo.MutationResult<Types.SaveBannerDraftMutation>
export type SaveBannerDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveBannerDraftMutation,
  Types.SaveBannerDraftMutationVariables
>
export const PublishBannerDocument = gql`
    mutation publishBanner($input: PublishBannerInput!) {
  publishBanner(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...BannerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${BannerDataFragmentDoc}`
export type PublishBannerMutationFn = Apollo.MutationFunction<
  Types.PublishBannerMutation,
  Types.PublishBannerMutationVariables
>

/**
 * __usePublishBannerMutation__
 *
 * To run a mutation, you first call `usePublishBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishBannerMutation, { data, loading, error }] = usePublishBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishBannerMutation, Types.PublishBannerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishBannerMutation, Types.PublishBannerMutationVariables>(
    PublishBannerDocument,
    options,
  )
}
export type PublishBannerMutationHookResult = ReturnType<typeof usePublishBannerMutation>
export type PublishBannerMutationResult = Apollo.MutationResult<Types.PublishBannerMutation>
export type PublishBannerMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishBannerMutation,
  Types.PublishBannerMutationVariables
>
export const UnpublishBannerDocument = gql`
    mutation unpublishBanner($input: UnpublishBannerInput!) {
  unpublishBanner(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...BannerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${BannerDataFragmentDoc}`
export type UnpublishBannerMutationFn = Apollo.MutationFunction<
  Types.UnpublishBannerMutation,
  Types.UnpublishBannerMutationVariables
>

/**
 * __useUnpublishBannerMutation__
 *
 * To run a mutation, you first call `useUnpublishBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishBannerMutation, { data, loading, error }] = useUnpublishBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishBannerMutation, Types.UnpublishBannerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishBannerMutation, Types.UnpublishBannerMutationVariables>(
    UnpublishBannerDocument,
    options,
  )
}
export type UnpublishBannerMutationHookResult = ReturnType<typeof useUnpublishBannerMutation>
export type UnpublishBannerMutationResult = Apollo.MutationResult<Types.UnpublishBannerMutation>
export type UnpublishBannerMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishBannerMutation,
  Types.UnpublishBannerMutationVariables
>
export const DeleteBannerDraftDocument = gql`
    mutation deleteBannerDraft($input: DeleteBannerDraftInput!) {
  deleteBannerDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...BannerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${BannerDataFragmentDoc}`
export type DeleteBannerDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteBannerDraftMutation,
  Types.DeleteBannerDraftMutationVariables
>

/**
 * __useDeleteBannerDraftMutation__
 *
 * To run a mutation, you first call `useDeleteBannerDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannerDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannerDraftMutation, { data, loading, error }] = useDeleteBannerDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBannerDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteBannerDraftMutation, Types.DeleteBannerDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteBannerDraftMutation, Types.DeleteBannerDraftMutationVariables>(
    DeleteBannerDraftDocument,
    options,
  )
}
export type DeleteBannerDraftMutationHookResult = ReturnType<typeof useDeleteBannerDraftMutation>
export type DeleteBannerDraftMutationResult = Apollo.MutationResult<Types.DeleteBannerDraftMutation>
export type DeleteBannerDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteBannerDraftMutation,
  Types.DeleteBannerDraftMutationVariables
>
export const GetBannerByIdDocument = gql`
    query getBannerById($id: ID!) {
  banner(id: $id) {
    ...BannerData
  }
}
    ${BannerDataFragmentDoc}`

/**
 * __useGetBannerByIdQuery__
 *
 * To run a query within a React component, call `useGetBannerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBannerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables> &
    ({ variables: Types.GetBannerByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>(GetBannerByIdDocument, options)
}
export function useGetBannerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>(
    GetBannerByIdDocument,
    options,
  )
}
export function useGetBannerByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>(
    GetBannerByIdDocument,
    options,
  )
}
export type GetBannerByIdQueryHookResult = ReturnType<typeof useGetBannerByIdQuery>
export type GetBannerByIdLazyQueryHookResult = ReturnType<typeof useGetBannerByIdLazyQuery>
export type GetBannerByIdSuspenseQueryHookResult = ReturnType<typeof useGetBannerByIdSuspenseQuery>
export type GetBannerByIdQueryResult = Apollo.QueryResult<Types.GetBannerByIdQuery, Types.GetBannerByIdQueryVariables>
export const SaveCuratedPageDraftDocument = gql`
    mutation saveCuratedPageDraft($input: CreateOrUpdateCuratedContentDraftInput!) {
  createOrUpdateCuratedContentDraft(input: $input) {
    result {
      ...CuratedContentData
    }
  }
}
    ${CuratedContentDataFragmentDoc}`
export type SaveCuratedPageDraftMutationFn = Apollo.MutationFunction<
  Types.SaveCuratedPageDraftMutation,
  Types.SaveCuratedPageDraftMutationVariables
>

/**
 * __useSaveCuratedPageDraftMutation__
 *
 * To run a mutation, you first call `useSaveCuratedPageDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCuratedPageDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCuratedPageDraftMutation, { data, loading, error }] = useSaveCuratedPageDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCuratedPageDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveCuratedPageDraftMutation,
    Types.SaveCuratedPageDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveCuratedPageDraftMutation, Types.SaveCuratedPageDraftMutationVariables>(
    SaveCuratedPageDraftDocument,
    options,
  )
}
export type SaveCuratedPageDraftMutationHookResult = ReturnType<typeof useSaveCuratedPageDraftMutation>
export type SaveCuratedPageDraftMutationResult = Apollo.MutationResult<Types.SaveCuratedPageDraftMutation>
export type SaveCuratedPageDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveCuratedPageDraftMutation,
  Types.SaveCuratedPageDraftMutationVariables
>
export const PublishCuratedPageDocument = gql`
    mutation publishCuratedPage($input: PublishCuratedContentInput!) {
  publishCuratedContent(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CuratedContentData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CuratedContentDataFragmentDoc}`
export type PublishCuratedPageMutationFn = Apollo.MutationFunction<
  Types.PublishCuratedPageMutation,
  Types.PublishCuratedPageMutationVariables
>

/**
 * __usePublishCuratedPageMutation__
 *
 * To run a mutation, you first call `usePublishCuratedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCuratedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCuratedPageMutation, { data, loading, error }] = usePublishCuratedPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishCuratedPageMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishCuratedPageMutation, Types.PublishCuratedPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishCuratedPageMutation, Types.PublishCuratedPageMutationVariables>(
    PublishCuratedPageDocument,
    options,
  )
}
export type PublishCuratedPageMutationHookResult = ReturnType<typeof usePublishCuratedPageMutation>
export type PublishCuratedPageMutationResult = Apollo.MutationResult<Types.PublishCuratedPageMutation>
export type PublishCuratedPageMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishCuratedPageMutation,
  Types.PublishCuratedPageMutationVariables
>
export const GetCuratedPageByIdDocument = gql`
    query getCuratedPageById($id: ID!) {
  curatedContent(id: $id) {
    ...CuratedContentData
  }
}
    ${CuratedContentDataFragmentDoc}`

/**
 * __useGetCuratedPageByIdQuery__
 *
 * To run a query within a React component, call `useGetCuratedPageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCuratedPageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCuratedPageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCuratedPageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables> &
    ({ variables: Types.GetCuratedPageByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables>(
    GetCuratedPageByIdDocument,
    options,
  )
}
export function useGetCuratedPageByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables>(
    GetCuratedPageByIdDocument,
    options,
  )
}
export function useGetCuratedPageByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetCuratedPageByIdQuery, Types.GetCuratedPageByIdQueryVariables>(
    GetCuratedPageByIdDocument,
    options,
  )
}
export type GetCuratedPageByIdQueryHookResult = ReturnType<typeof useGetCuratedPageByIdQuery>
export type GetCuratedPageByIdLazyQueryHookResult = ReturnType<typeof useGetCuratedPageByIdLazyQuery>
export type GetCuratedPageByIdSuspenseQueryHookResult = ReturnType<typeof useGetCuratedPageByIdSuspenseQuery>
export type GetCuratedPageByIdQueryResult = Apollo.QueryResult<
  Types.GetCuratedPageByIdQuery,
  Types.GetCuratedPageByIdQueryVariables
>
export const GetAllCuratedPagesDocument = gql`
    query getAllCuratedPages($after: String, $before: String, $first: Int, $last: Int) {
  curatedContents(after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...CuratedContentData
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${CuratedContentDataFragmentDoc}`

/**
 * __useGetAllCuratedPagesQuery__
 *
 * To run a query within a React component, call `useGetAllCuratedPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCuratedPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCuratedPagesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllCuratedPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>(
    GetAllCuratedPagesDocument,
    options,
  )
}
export function useGetAllCuratedPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>(
    GetAllCuratedPagesDocument,
    options,
  )
}
export function useGetAllCuratedPagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetAllCuratedPagesQuery, Types.GetAllCuratedPagesQueryVariables>(
    GetAllCuratedPagesDocument,
    options,
  )
}
export type GetAllCuratedPagesQueryHookResult = ReturnType<typeof useGetAllCuratedPagesQuery>
export type GetAllCuratedPagesLazyQueryHookResult = ReturnType<typeof useGetAllCuratedPagesLazyQuery>
export type GetAllCuratedPagesSuspenseQueryHookResult = ReturnType<typeof useGetAllCuratedPagesSuspenseQuery>
export type GetAllCuratedPagesQueryResult = Apollo.QueryResult<
  Types.GetAllCuratedPagesQuery,
  Types.GetAllCuratedPagesQueryVariables
>
export const SaveSliderDraftDocument = gql`
    mutation saveSliderDraft($input: CreateOrUpdateCuratedSliderDraftInput!) {
  createOrUpdateCuratedSliderDraft(input: $input) {
    result {
      ...CuratedSliderData
    }
  }
}
    ${CuratedSliderDataFragmentDoc}`
export type SaveSliderDraftMutationFn = Apollo.MutationFunction<
  Types.SaveSliderDraftMutation,
  Types.SaveSliderDraftMutationVariables
>

/**
 * __useSaveSliderDraftMutation__
 *
 * To run a mutation, you first call `useSaveSliderDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSliderDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSliderDraftMutation, { data, loading, error }] = useSaveSliderDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSliderDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveSliderDraftMutation, Types.SaveSliderDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveSliderDraftMutation, Types.SaveSliderDraftMutationVariables>(
    SaveSliderDraftDocument,
    options,
  )
}
export type SaveSliderDraftMutationHookResult = ReturnType<typeof useSaveSliderDraftMutation>
export type SaveSliderDraftMutationResult = Apollo.MutationResult<Types.SaveSliderDraftMutation>
export type SaveSliderDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveSliderDraftMutation,
  Types.SaveSliderDraftMutationVariables
>
export const PublishSliderDocument = gql`
    mutation publishSlider($input: PublishCuratedSliderInput!) {
  publishCuratedSlider(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CuratedSliderData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CuratedSliderDataFragmentDoc}`
export type PublishSliderMutationFn = Apollo.MutationFunction<
  Types.PublishSliderMutation,
  Types.PublishSliderMutationVariables
>

/**
 * __usePublishSliderMutation__
 *
 * To run a mutation, you first call `usePublishSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSliderMutation, { data, loading, error }] = usePublishSliderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishSliderMutation, Types.PublishSliderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishSliderMutation, Types.PublishSliderMutationVariables>(
    PublishSliderDocument,
    options,
  )
}
export type PublishSliderMutationHookResult = ReturnType<typeof usePublishSliderMutation>
export type PublishSliderMutationResult = Apollo.MutationResult<Types.PublishSliderMutation>
export type PublishSliderMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishSliderMutation,
  Types.PublishSliderMutationVariables
>
export const UnpublishSliderDocument = gql`
    mutation unpublishSlider($input: UnpublishCuratedSliderInput!) {
  unpublishCuratedSlider(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CuratedSliderData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CuratedSliderDataFragmentDoc}`
export type UnpublishSliderMutationFn = Apollo.MutationFunction<
  Types.UnpublishSliderMutation,
  Types.UnpublishSliderMutationVariables
>

/**
 * __useUnpublishSliderMutation__
 *
 * To run a mutation, you first call `useUnpublishSliderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishSliderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishSliderMutation, { data, loading, error }] = useUnpublishSliderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishSliderMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishSliderMutation, Types.UnpublishSliderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishSliderMutation, Types.UnpublishSliderMutationVariables>(
    UnpublishSliderDocument,
    options,
  )
}
export type UnpublishSliderMutationHookResult = ReturnType<typeof useUnpublishSliderMutation>
export type UnpublishSliderMutationResult = Apollo.MutationResult<Types.UnpublishSliderMutation>
export type UnpublishSliderMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishSliderMutation,
  Types.UnpublishSliderMutationVariables
>
export const DeleteSliderDraftDocument = gql`
    mutation deleteSliderDraft($input: DeleteCuratedSliderDraftInput!) {
  deleteCuratedSliderDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CuratedSliderData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CuratedSliderDataFragmentDoc}`
export type DeleteSliderDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteSliderDraftMutation,
  Types.DeleteSliderDraftMutationVariables
>

/**
 * __useDeleteSliderDraftMutation__
 *
 * To run a mutation, you first call `useDeleteSliderDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSliderDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSliderDraftMutation, { data, loading, error }] = useDeleteSliderDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSliderDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteSliderDraftMutation, Types.DeleteSliderDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteSliderDraftMutation, Types.DeleteSliderDraftMutationVariables>(
    DeleteSliderDraftDocument,
    options,
  )
}
export type DeleteSliderDraftMutationHookResult = ReturnType<typeof useDeleteSliderDraftMutation>
export type DeleteSliderDraftMutationResult = Apollo.MutationResult<Types.DeleteSliderDraftMutation>
export type DeleteSliderDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSliderDraftMutation,
  Types.DeleteSliderDraftMutationVariables
>
export const GetCuratedSliderByIdDocument = gql`
    query getCuratedSliderById($id: ID!) {
  curatedSlider(id: $id) {
    ...CuratedSliderData
  }
}
    ${CuratedSliderDataFragmentDoc}`

/**
 * __useGetCuratedSliderByIdQuery__
 *
 * To run a query within a React component, call `useGetCuratedSliderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCuratedSliderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCuratedSliderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCuratedSliderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables> &
    ({ variables: Types.GetCuratedSliderByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables>(
    GetCuratedSliderByIdDocument,
    options,
  )
}
export function useGetCuratedSliderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables>(
    GetCuratedSliderByIdDocument,
    options,
  )
}
export function useGetCuratedSliderByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetCuratedSliderByIdQuery, Types.GetCuratedSliderByIdQueryVariables>(
    GetCuratedSliderByIdDocument,
    options,
  )
}
export type GetCuratedSliderByIdQueryHookResult = ReturnType<typeof useGetCuratedSliderByIdQuery>
export type GetCuratedSliderByIdLazyQueryHookResult = ReturnType<typeof useGetCuratedSliderByIdLazyQuery>
export type GetCuratedSliderByIdSuspenseQueryHookResult = ReturnType<typeof useGetCuratedSliderByIdSuspenseQuery>
export type GetCuratedSliderByIdQueryResult = Apollo.QueryResult<
  Types.GetCuratedSliderByIdQuery,
  Types.GetCuratedSliderByIdQueryVariables
>
export const GetStageByIdDocument = gql`
    query getStageById($slug: CuratedContentSlug!) {
  stage(slug: $slug) {
    ...StageData
  }
}
    ${StageDataFragmentDoc}`

/**
 * __useGetStageByIdQuery__
 *
 * To run a query within a React component, call `useGetStageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStageByIdQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetStageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables> &
    ({ variables: Types.GetStageByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>(GetStageByIdDocument, options)
}
export function useGetStageByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>(GetStageByIdDocument, options)
}
export function useGetStageByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>(
    GetStageByIdDocument,
    options,
  )
}
export type GetStageByIdQueryHookResult = ReturnType<typeof useGetStageByIdQuery>
export type GetStageByIdLazyQueryHookResult = ReturnType<typeof useGetStageByIdLazyQuery>
export type GetStageByIdSuspenseQueryHookResult = ReturnType<typeof useGetStageByIdSuspenseQuery>
export type GetStageByIdQueryResult = Apollo.QueryResult<Types.GetStageByIdQuery, Types.GetStageByIdQueryVariables>
export const SaveStageDraftDocument = gql`
    mutation saveStageDraft($input: CreateOrUpdateStageDraftInput!) {
  createOrUpdateStageDraft(input: $input) {
    result {
      ...StageData
    }
  }
}
    ${StageDataFragmentDoc}`
export type SaveStageDraftMutationFn = Apollo.MutationFunction<
  Types.SaveStageDraftMutation,
  Types.SaveStageDraftMutationVariables
>

/**
 * __useSaveStageDraftMutation__
 *
 * To run a mutation, you first call `useSaveStageDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStageDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStageDraftMutation, { data, loading, error }] = useSaveStageDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveStageDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveStageDraftMutation, Types.SaveStageDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveStageDraftMutation, Types.SaveStageDraftMutationVariables>(
    SaveStageDraftDocument,
    options,
  )
}
export type SaveStageDraftMutationHookResult = ReturnType<typeof useSaveStageDraftMutation>
export type SaveStageDraftMutationResult = Apollo.MutationResult<Types.SaveStageDraftMutation>
export type SaveStageDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveStageDraftMutation,
  Types.SaveStageDraftMutationVariables
>
export const PublishStageDocument = gql`
    mutation publishStage($input: PublishStageInput!) {
  publishStage(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...StageData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${StageDataFragmentDoc}`
export type PublishStageMutationFn = Apollo.MutationFunction<
  Types.PublishStageMutation,
  Types.PublishStageMutationVariables
>

/**
 * __usePublishStageMutation__
 *
 * To run a mutation, you first call `usePublishStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStageMutation, { data, loading, error }] = usePublishStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishStageMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishStageMutation, Types.PublishStageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishStageMutation, Types.PublishStageMutationVariables>(
    PublishStageDocument,
    options,
  )
}
export type PublishStageMutationHookResult = ReturnType<typeof usePublishStageMutation>
export type PublishStageMutationResult = Apollo.MutationResult<Types.PublishStageMutation>
export type PublishStageMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishStageMutation,
  Types.PublishStageMutationVariables
>
export const GetAllStagesDocument = gql`
    query getAllStages($after: String, $before: String, $first: Int, $last: Int) {
  stages(after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...StageData
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${StageDataFragmentDoc}`

/**
 * __useGetAllStagesQuery__
 *
 * To run a query within a React component, call `useGetAllStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStagesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllStagesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>(GetAllStagesDocument, options)
}
export function useGetAllStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>(GetAllStagesDocument, options)
}
export function useGetAllStagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>(
    GetAllStagesDocument,
    options,
  )
}
export type GetAllStagesQueryHookResult = ReturnType<typeof useGetAllStagesQuery>
export type GetAllStagesLazyQueryHookResult = ReturnType<typeof useGetAllStagesLazyQuery>
export type GetAllStagesSuspenseQueryHookResult = ReturnType<typeof useGetAllStagesSuspenseQuery>
export type GetAllStagesQueryResult = Apollo.QueryResult<Types.GetAllStagesQuery, Types.GetAllStagesQueryVariables>
