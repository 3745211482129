import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const AllLocalesFragmentDoc = gql`
    fragment AllLocales on TranslatedString {
  de
  en
  ja
}
    `
export const DraftEditorFragmentDoc = gql`
    fragment DraftEditor on AdminUser {
  id
  name
  email
}
    `
export const PictureInfoFragmentDoc = gql`
    fragment PictureInfo on Picture {
  id
  type
  url
}
    `
export const ReferencedPicturesFragmentDoc = gql`
    fragment ReferencedPictures on PictureConnection {
  edges {
    status
    node {
      ...PictureInfo
    }
  }
}
    ${PictureInfoFragmentDoc}`
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on CoreRole {
  id
  type
  subtype
  displayName
}
    `
export const ReferencedRolesFragmentDoc = gql`
    fragment ReferencedRoles on CoreRoleConnection {
  edges {
    status
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const ContentContainerItemsFragmentDoc = gql`
    fragment ContentContainerItems on Node {
  id
}
    `
export const ArtistDraftInfoFragmentDoc = gql`
    fragment ArtistDraftInfo on CoreArtistDraft {
  biography {
    ...AllLocales
  }
  courtesyOf
  dateOfBirth
  dateOfDeath
  name {
    ...AllLocales
  }
  sortName {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  partyId
  title {
    ...AllLocales
  }
  epoch {
    id
    title {
      ...AllLocales
    }
  }
  editor {
    ...DraftEditor
  }
  updatedAt
  pictures {
    ...ReferencedPictures
  }
  roles {
    ...ReferencedRoles
  }
  contentContainers {
    ...ContentContainerItems
  }
  photographer
}
    ${AllLocalesFragmentDoc}
${DraftEditorFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ReferencedRolesFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const ArtistInfoFragmentDoc = gql`
    fragment ArtistInfo on CoreArtist {
  biography {
    ...AllLocales
  }
  courtesyOf
  dateOfBirth
  dateOfDeath
  name {
    ...AllLocales
  }
  sortName {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  partyId
  title {
    ...AllLocales
  }
  epoch {
    id
    title {
      ...AllLocales
    }
  }
  pictures {
    ...ReferencedPictures
  }
  roles {
    ...ReferencedRoles
  }
  editor {
    ...DraftEditor
  }
  updatedAt
  contentContainers {
    ...ContentContainerItems
  }
  photographer
}
    ${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ReferencedRolesFragmentDoc}
${DraftEditorFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CoreArtistDataFragmentDoc = gql`
    fragment CoreArtistData on CoreArtistData {
  id
  status
  draft {
    ...ArtistDraftInfo
  }
  published {
    ...ArtistInfo
  }
}
    ${ArtistDraftInfoFragmentDoc}
${ArtistInfoFragmentDoc}`
export const EpochListInfoFragmentDoc = gql`
    fragment EpochListInfo on CoreEpoch {
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const EpochDraftListInfoFragmentDoc = gql`
    fragment EpochDraftListInfo on CoreEpochDraft {
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const EpochDraftFragmentDoc = gql`
    fragment EpochDraft on CoreEpochDraft {
  editor {
    ...DraftEditor
  }
  updatedAt
  title {
    ...AllLocales
  }
  subtitle {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  startYear
  endYear
  pictures {
    ...ReferencedPictures
  }
  contentContainers {
    ...ContentContainerItems
  }
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const EpochFragmentDoc = gql`
    fragment Epoch on CoreEpoch {
  title {
    ...AllLocales
  }
  subtitle {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  startYear
  endYear
  pictures {
    ...ReferencedPictures
  }
  contentContainers {
    ...ContentContainerItems
  }
  editor {
    ...DraftEditor
  }
  updatedAt
}
    ${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ContentContainerItemsFragmentDoc}
${DraftEditorFragmentDoc}`
export const CoreEpochDataFragmentDoc = gql`
    fragment CoreEpochData on CoreEpochData {
  id
  status
  draft {
    ...EpochDraft
  }
  published {
    ...Epoch
  }
}
    ${EpochDraftFragmentDoc}
${EpochFragmentDoc}`
export const CoreGenreListInfoFragmentDoc = gql`
    fragment CoreGenreListInfo on CoreGenre {
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const CoreGenreDraftListInfoFragmentDoc = gql`
    fragment CoreGenreDraftListInfo on CoreGenreDraft {
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const CoreGenreDraftFragmentDoc = gql`
    fragment CoreGenreDraft on CoreGenreDraft {
  editor {
    ...DraftEditor
  }
  updatedAt
  title {
    ...AllLocales
  }
  pictures {
    ...ReferencedPictures
  }
  contentContainers {
    ...ContentContainerItems
  }
}
    ${DraftEditorFragmentDoc}
${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CoreGenreFragmentDoc = gql`
    fragment CoreGenre on CoreGenre {
  title {
    ...AllLocales
  }
  pictures {
    ...ReferencedPictures
  }
  contentContainers {
    ...ContentContainerItems
  }
  editor {
    ...DraftEditor
  }
  updatedAt
}
    ${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ContentContainerItemsFragmentDoc}
${DraftEditorFragmentDoc}`
export const CoreGenreDataFragmentDoc = gql`
    fragment CoreGenreData on CoreGenreData {
  id
  status
  draft {
    ...CoreGenreDraft
  }
  published {
    ...CoreGenre
  }
}
    ${CoreGenreDraftFragmentDoc}
${CoreGenreFragmentDoc}`
export const GroupDraftInfoFragmentDoc = gql`
    fragment GroupDraftInfo on CoreGroupDraft {
  partyId
  name {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  type
  editor {
    ...DraftEditor
  }
  updatedAt
  pictures {
    ...ReferencedPictures
  }
  footnote {
    ...AllLocales
  }
  photographer
  contentContainers {
    ...ContentContainerItems
  }
}
    ${AllLocalesFragmentDoc}
${DraftEditorFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const GroupInfoFragmentDoc = gql`
    fragment GroupInfo on CoreGroup {
  partyId
  name {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  type
  pictures {
    ...ReferencedPictures
  }
  footnote {
    ...AllLocales
  }
  editor {
    ...DraftEditor
  }
  updatedAt
  photographer
  contentContainers {
    ...ContentContainerItems
  }
}
    ${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${DraftEditorFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CoreGroupDataFragmentDoc = gql`
    fragment CoreGroupData on CoreGroupData {
  id
  status
  draft {
    ...GroupDraftInfo
  }
  published {
    ...GroupInfo
  }
}
    ${GroupDraftInfoFragmentDoc}
${GroupInfoFragmentDoc}`
export const ReferencedAlbumsFragmentDoc = gql`
    fragment ReferencedAlbums on AlbumConnection {
  edges {
    status
    node {
      id
      title {
        ...AllLocales
      }
    }
  }
}
    ${AllLocalesFragmentDoc}`
export const PartnerDraftInfoFragmentDoc = gql`
    fragment PartnerDraftInfo on CorePartnerDraft {
  name {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  editor {
    ...DraftEditor
  }
  updatedAt
  type
  subtype
  pictures {
    ...ReferencedPictures
  }
  albums {
    ...ReferencedAlbums
  }
  photographer
  contentContainers {
    ...ContentContainerItems
  }
}
    ${AllLocalesFragmentDoc}
${DraftEditorFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ReferencedAlbumsFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const PartnerInfoFragmentDoc = gql`
    fragment PartnerInfo on CorePartner {
  name {
    ...AllLocales
  }
  sortLetter {
    ...AllLocales
  }
  description {
    ...AllLocales
  }
  type
  subtype
  pictures {
    ...ReferencedPictures
  }
  albums {
    ...ReferencedAlbums
  }
  editor {
    ...DraftEditor
  }
  photographer
  contentContainers {
    ...ContentContainerItems
  }
  updatedAt
}
    ${AllLocalesFragmentDoc}
${ReferencedPicturesFragmentDoc}
${ReferencedAlbumsFragmentDoc}
${DraftEditorFragmentDoc}
${ContentContainerItemsFragmentDoc}`
export const CorePartnerDataFragmentDoc = gql`
    fragment CorePartnerData on CorePartnerData {
  id
  status
  draft {
    ...PartnerDraftInfo
  }
  published {
    ...PartnerInfo
  }
}
    ${PartnerDraftInfoFragmentDoc}
${PartnerInfoFragmentDoc}`
export const PartnerAlbumInfoFragmentDoc = gql`
    fragment PartnerAlbumInfo on Album {
  id
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const ReferencedArtistsFragmentDoc = gql`
    fragment ReferencedArtists on CoreArtistConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const MovementFragmentDoc = gql`
    fragment Movement on CoreMovement {
  id
  title {
    ...AllLocales
  }
}
    ${AllLocalesFragmentDoc}`
export const WorkDraftInfoFragmentDoc = gql`
    fragment WorkDraftInfo on CoreWorkDraft {
  composers {
    ...ReferencedArtists
  }
  arrangers {
    ...ReferencedArtists
  }
  librettists {
    ...ReferencedArtists
  }
  r2Work {
    id
    r2Id
    title
  }
  copyright
  epochs {
    id
    title {
      en
    }
  }
  genre {
    id
    title {
      en
    }
  }
  publisher
  title {
    ...AllLocales
  }
  yearOfComposition
  editor {
    ...DraftEditor
  }
  updatedAt
  movements {
    ...Movement
  }
  publicDomain
  r2idNote
}
    ${ReferencedArtistsFragmentDoc}
${AllLocalesFragmentDoc}
${DraftEditorFragmentDoc}
${MovementFragmentDoc}`
export const WorkInfoFragmentDoc = gql`
    fragment WorkInfo on CoreWork {
  composers {
    ...ReferencedArtists
  }
  arrangers {
    ...ReferencedArtists
  }
  librettists {
    ...ReferencedArtists
  }
  r2Work {
    id
    r2Id
    title
  }
  copyright
  epochs {
    id
    title {
      en
    }
  }
  genre {
    id
    title {
      en
    }
  }
  publisher
  title {
    ...AllLocales
  }
  yearOfComposition
  movements {
    ...Movement
  }
  publicDomain
  r2idNote
  editor {
    ...DraftEditor
  }
  updatedAt
}
    ${ReferencedArtistsFragmentDoc}
${AllLocalesFragmentDoc}
${MovementFragmentDoc}
${DraftEditorFragmentDoc}`
export const CoreWorkDataFragmentDoc = gql`
    fragment CoreWorkData on CoreWorkData {
  id
  status
  draft {
    ...WorkDraftInfo
  }
  published {
    ...WorkInfo
  }
}
    ${WorkDraftInfoFragmentDoc}
${WorkInfoFragmentDoc}`
export const PublicationErrorFieldsFragmentDoc = gql`
    fragment PublicationErrorFields on PublicationError {
  reason
}
    `
export const UniqueIsrcPublicationErrorFieldsFragmentDoc = gql`
    fragment UniqueIsrcPublicationErrorFields on UniqueIsrcPublicationError {
  reason
  isrc
  conflictingNodes {
    id
    type
  }
}
    `
export const ValidationErrorFieldsFragmentDoc = gql`
    fragment ValidationErrorFields on ValidationError {
  field
  validation
}
    `
export const AdminApiErrorFragmentDoc = gql`
    fragment AdminApiError on ApiError {
  __typename
  message
  path
  ... on PublicationError {
    ...PublicationErrorFields
  }
  ... on UniqueIsrcPublicationError {
    ...UniqueIsrcPublicationErrorFields
  }
  ... on ValidationError {
    ...ValidationErrorFields
  }
  ... on AuthenticationError {
    code
  }
}
    ${PublicationErrorFieldsFragmentDoc}
${UniqueIsrcPublicationErrorFieldsFragmentDoc}
${ValidationErrorFieldsFragmentDoc}`
export const ReferencedPartnersFragmentDoc = gql`
    fragment ReferencedPartners on CorePartnerConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
      type
    }
  }
}
    `
export const ReferencedGroupsFragmentDoc = gql`
    fragment ReferencedGroups on CoreGroupConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
  }
}
    `
export const ReferencedArtistsWithRoleFragmentDoc = gql`
    fragment ReferencedArtistsWithRole on CoreArtistWithRoleConnection {
  edges {
    status
    node {
      id
      name {
        en
      }
    }
    role {
      id
      type
      subtype
    }
  }
}
    `
export const ReferencedListRolesFragmentDoc = gql`
    fragment ReferencedListRoles on CoreRoleListConnection {
  edges {
    node {
      ...RoleInfo
    }
  }
}
    ${RoleInfoFragmentDoc}`
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `
export const StreamInfoFragmentDoc = gql`
    fragment StreamInfo on Stream {
  id
  url
  mvVideoUrl
}
    `
export const GetArtistByIdDocument = gql`
    query getArtistById($id: ID!) {
  coreArtist(id: $id) {
    ...CoreArtistData
  }
}
    ${CoreArtistDataFragmentDoc}`

/**
 * __useGetArtistByIdQuery__
 *
 * To run a query within a React component, call `useGetArtistByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtistByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables> &
    ({ variables: Types.GetArtistByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>(GetArtistByIdDocument, options)
}
export function useGetArtistByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>(
    GetArtistByIdDocument,
    options,
  )
}
export function useGetArtistByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>(
    GetArtistByIdDocument,
    options,
  )
}
export type GetArtistByIdQueryHookResult = ReturnType<typeof useGetArtistByIdQuery>
export type GetArtistByIdLazyQueryHookResult = ReturnType<typeof useGetArtistByIdLazyQuery>
export type GetArtistByIdSuspenseQueryHookResult = ReturnType<typeof useGetArtistByIdSuspenseQuery>
export type GetArtistByIdQueryResult = Apollo.QueryResult<Types.GetArtistByIdQuery, Types.GetArtistByIdQueryVariables>
export const SaveArtistDraftDocument = gql`
    mutation saveArtistDraft($input: CreateOrUpdateCoreArtistDraftInput!) {
  createOrUpdateCoreArtistDraft(input: $input) {
    result {
      ...CoreArtistData
    }
  }
}
    ${CoreArtistDataFragmentDoc}`
export type SaveArtistDraftMutationFn = Apollo.MutationFunction<
  Types.SaveArtistDraftMutation,
  Types.SaveArtistDraftMutationVariables
>

/**
 * __useSaveArtistDraftMutation__
 *
 * To run a mutation, you first call `useSaveArtistDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveArtistDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveArtistDraftMutation, { data, loading, error }] = useSaveArtistDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveArtistDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveArtistDraftMutation, Types.SaveArtistDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveArtistDraftMutation, Types.SaveArtistDraftMutationVariables>(
    SaveArtistDraftDocument,
    options,
  )
}
export type SaveArtistDraftMutationHookResult = ReturnType<typeof useSaveArtistDraftMutation>
export type SaveArtistDraftMutationResult = Apollo.MutationResult<Types.SaveArtistDraftMutation>
export type SaveArtistDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveArtistDraftMutation,
  Types.SaveArtistDraftMutationVariables
>
export const PublishArtistDocument = gql`
    mutation publishArtist($input: PublishCoreArtistInput!) {
  publishCoreArtist(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreArtistData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreArtistDataFragmentDoc}`
export type PublishArtistMutationFn = Apollo.MutationFunction<
  Types.PublishArtistMutation,
  Types.PublishArtistMutationVariables
>

/**
 * __usePublishArtistMutation__
 *
 * To run a mutation, you first call `usePublishArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishArtistMutation, { data, loading, error }] = usePublishArtistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishArtistMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishArtistMutation, Types.PublishArtistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishArtistMutation, Types.PublishArtistMutationVariables>(
    PublishArtistDocument,
    options,
  )
}
export type PublishArtistMutationHookResult = ReturnType<typeof usePublishArtistMutation>
export type PublishArtistMutationResult = Apollo.MutationResult<Types.PublishArtistMutation>
export type PublishArtistMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishArtistMutation,
  Types.PublishArtistMutationVariables
>
export const UnpublishArtistDocument = gql`
    mutation unpublishArtist($input: UnpublishCoreArtistInput!) {
  unpublishCoreArtist(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreArtistData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreArtistDataFragmentDoc}`
export type UnpublishArtistMutationFn = Apollo.MutationFunction<
  Types.UnpublishArtistMutation,
  Types.UnpublishArtistMutationVariables
>

/**
 * __useUnpublishArtistMutation__
 *
 * To run a mutation, you first call `useUnpublishArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishArtistMutation, { data, loading, error }] = useUnpublishArtistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishArtistMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishArtistMutation, Types.UnpublishArtistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishArtistMutation, Types.UnpublishArtistMutationVariables>(
    UnpublishArtistDocument,
    options,
  )
}
export type UnpublishArtistMutationHookResult = ReturnType<typeof useUnpublishArtistMutation>
export type UnpublishArtistMutationResult = Apollo.MutationResult<Types.UnpublishArtistMutation>
export type UnpublishArtistMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishArtistMutation,
  Types.UnpublishArtistMutationVariables
>
export const DeleteArtistDraftDocument = gql`
    mutation deleteArtistDraft($input: DeleteCoreArtistDraftInput!) {
  deleteCoreArtistDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreArtistData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreArtistDataFragmentDoc}`
export type DeleteArtistDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteArtistDraftMutation,
  Types.DeleteArtistDraftMutationVariables
>

/**
 * __useDeleteArtistDraftMutation__
 *
 * To run a mutation, you first call `useDeleteArtistDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtistDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtistDraftMutation, { data, loading, error }] = useDeleteArtistDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArtistDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteArtistDraftMutation, Types.DeleteArtistDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteArtistDraftMutation, Types.DeleteArtistDraftMutationVariables>(
    DeleteArtistDraftDocument,
    options,
  )
}
export type DeleteArtistDraftMutationHookResult = ReturnType<typeof useDeleteArtistDraftMutation>
export type DeleteArtistDraftMutationResult = Apollo.MutationResult<Types.DeleteArtistDraftMutation>
export type DeleteArtistDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteArtistDraftMutation,
  Types.DeleteArtistDraftMutationVariables
>
export const GetEpochByIdDocument = gql`
    query getEpochById($id: ID!) {
  coreEpoch(id: $id) {
    ...CoreEpochData
  }
}
    ${CoreEpochDataFragmentDoc}`

/**
 * __useGetEpochByIdQuery__
 *
 * To run a query within a React component, call `useGetEpochByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpochByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpochByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEpochByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables> &
    ({ variables: Types.GetEpochByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>(GetEpochByIdDocument, options)
}
export function useGetEpochByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>(GetEpochByIdDocument, options)
}
export function useGetEpochByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>(
    GetEpochByIdDocument,
    options,
  )
}
export type GetEpochByIdQueryHookResult = ReturnType<typeof useGetEpochByIdQuery>
export type GetEpochByIdLazyQueryHookResult = ReturnType<typeof useGetEpochByIdLazyQuery>
export type GetEpochByIdSuspenseQueryHookResult = ReturnType<typeof useGetEpochByIdSuspenseQuery>
export type GetEpochByIdQueryResult = Apollo.QueryResult<Types.GetEpochByIdQuery, Types.GetEpochByIdQueryVariables>
export const GetAllEpochsDocument = gql`
    query getAllEpochs($after: String, $before: String, $first: Int, $last: Int) {
  coreEpochs(after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        id
        status
        published {
          ...EpochListInfo
        }
        draft {
          ...EpochDraftListInfo
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${EpochListInfoFragmentDoc}
${EpochDraftListInfoFragmentDoc}`

/**
 * __useGetAllEpochsQuery__
 *
 * To run a query within a React component, call `useGetAllEpochsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEpochsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEpochsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllEpochsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>(GetAllEpochsDocument, options)
}
export function useGetAllEpochsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>(GetAllEpochsDocument, options)
}
export function useGetAllEpochsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>(
    GetAllEpochsDocument,
    options,
  )
}
export type GetAllEpochsQueryHookResult = ReturnType<typeof useGetAllEpochsQuery>
export type GetAllEpochsLazyQueryHookResult = ReturnType<typeof useGetAllEpochsLazyQuery>
export type GetAllEpochsSuspenseQueryHookResult = ReturnType<typeof useGetAllEpochsSuspenseQuery>
export type GetAllEpochsQueryResult = Apollo.QueryResult<Types.GetAllEpochsQuery, Types.GetAllEpochsQueryVariables>
export const SaveEpochDraftDocument = gql`
    mutation saveEpochDraft($input: CreateOrUpdateCoreEpochDraftInput!) {
  createOrUpdateCoreEpochDraft(input: $input) {
    result {
      id
      draft {
        ...EpochDraft
      }
    }
  }
}
    ${EpochDraftFragmentDoc}`
export type SaveEpochDraftMutationFn = Apollo.MutationFunction<
  Types.SaveEpochDraftMutation,
  Types.SaveEpochDraftMutationVariables
>

/**
 * __useSaveEpochDraftMutation__
 *
 * To run a mutation, you first call `useSaveEpochDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEpochDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEpochDraftMutation, { data, loading, error }] = useSaveEpochDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEpochDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveEpochDraftMutation, Types.SaveEpochDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveEpochDraftMutation, Types.SaveEpochDraftMutationVariables>(
    SaveEpochDraftDocument,
    options,
  )
}
export type SaveEpochDraftMutationHookResult = ReturnType<typeof useSaveEpochDraftMutation>
export type SaveEpochDraftMutationResult = Apollo.MutationResult<Types.SaveEpochDraftMutation>
export type SaveEpochDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveEpochDraftMutation,
  Types.SaveEpochDraftMutationVariables
>
export const PublishEpochDocument = gql`
    mutation publishEpoch($input: PublishCoreEpochInput!) {
  publishCoreEpoch(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreEpochData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreEpochDataFragmentDoc}`
export type PublishEpochMutationFn = Apollo.MutationFunction<
  Types.PublishEpochMutation,
  Types.PublishEpochMutationVariables
>

/**
 * __usePublishEpochMutation__
 *
 * To run a mutation, you first call `usePublishEpochMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEpochMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEpochMutation, { data, loading, error }] = usePublishEpochMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishEpochMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishEpochMutation, Types.PublishEpochMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishEpochMutation, Types.PublishEpochMutationVariables>(
    PublishEpochDocument,
    options,
  )
}
export type PublishEpochMutationHookResult = ReturnType<typeof usePublishEpochMutation>
export type PublishEpochMutationResult = Apollo.MutationResult<Types.PublishEpochMutation>
export type PublishEpochMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishEpochMutation,
  Types.PublishEpochMutationVariables
>
export const UnpublishEpochDocument = gql`
    mutation unpublishEpoch($input: UnpublishCoreEpochInput!) {
  unpublishCoreEpoch(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreEpochData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreEpochDataFragmentDoc}`
export type UnpublishEpochMutationFn = Apollo.MutationFunction<
  Types.UnpublishEpochMutation,
  Types.UnpublishEpochMutationVariables
>

/**
 * __useUnpublishEpochMutation__
 *
 * To run a mutation, you first call `useUnpublishEpochMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishEpochMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishEpochMutation, { data, loading, error }] = useUnpublishEpochMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishEpochMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishEpochMutation, Types.UnpublishEpochMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishEpochMutation, Types.UnpublishEpochMutationVariables>(
    UnpublishEpochDocument,
    options,
  )
}
export type UnpublishEpochMutationHookResult = ReturnType<typeof useUnpublishEpochMutation>
export type UnpublishEpochMutationResult = Apollo.MutationResult<Types.UnpublishEpochMutation>
export type UnpublishEpochMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishEpochMutation,
  Types.UnpublishEpochMutationVariables
>
export const DeleteEpochDraftDocument = gql`
    mutation deleteEpochDraft($input: DeleteCoreEpochDraftInput!) {
  deleteCoreEpochDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreEpochData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreEpochDataFragmentDoc}`
export type DeleteEpochDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteEpochDraftMutation,
  Types.DeleteEpochDraftMutationVariables
>

/**
 * __useDeleteEpochDraftMutation__
 *
 * To run a mutation, you first call `useDeleteEpochDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEpochDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEpochDraftMutation, { data, loading, error }] = useDeleteEpochDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEpochDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteEpochDraftMutation, Types.DeleteEpochDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteEpochDraftMutation, Types.DeleteEpochDraftMutationVariables>(
    DeleteEpochDraftDocument,
    options,
  )
}
export type DeleteEpochDraftMutationHookResult = ReturnType<typeof useDeleteEpochDraftMutation>
export type DeleteEpochDraftMutationResult = Apollo.MutationResult<Types.DeleteEpochDraftMutation>
export type DeleteEpochDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteEpochDraftMutation,
  Types.DeleteEpochDraftMutationVariables
>
export const GetGenreByIdDocument = gql`
    query getGenreById($id: ID!) {
  coreGenre(id: $id) {
    ...CoreGenreData
  }
}
    ${CoreGenreDataFragmentDoc}`

/**
 * __useGetGenreByIdQuery__
 *
 * To run a query within a React component, call `useGetGenreByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenreByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenreByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGenreByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables> &
    ({ variables: Types.GetGenreByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>(GetGenreByIdDocument, options)
}
export function useGetGenreByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>(GetGenreByIdDocument, options)
}
export function useGetGenreByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>(
    GetGenreByIdDocument,
    options,
  )
}
export type GetGenreByIdQueryHookResult = ReturnType<typeof useGetGenreByIdQuery>
export type GetGenreByIdLazyQueryHookResult = ReturnType<typeof useGetGenreByIdLazyQuery>
export type GetGenreByIdSuspenseQueryHookResult = ReturnType<typeof useGetGenreByIdSuspenseQuery>
export type GetGenreByIdQueryResult = Apollo.QueryResult<Types.GetGenreByIdQuery, Types.GetGenreByIdQueryVariables>
export const GetAllGenresDocument = gql`
    query getAllGenres($after: String, $before: String, $first: Int, $last: Int) {
  coreGenres(after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        id
        status
        published {
          ...CoreGenreListInfo
        }
        draft {
          ...CoreGenreDraftListInfo
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${CoreGenreListInfoFragmentDoc}
${CoreGenreDraftListInfoFragmentDoc}`

/**
 * __useGetAllGenresQuery__
 *
 * To run a query within a React component, call `useGetAllGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGenresQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>(GetAllGenresDocument, options)
}
export function useGetAllGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>(GetAllGenresDocument, options)
}
export function useGetAllGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>(
    GetAllGenresDocument,
    options,
  )
}
export type GetAllGenresQueryHookResult = ReturnType<typeof useGetAllGenresQuery>
export type GetAllGenresLazyQueryHookResult = ReturnType<typeof useGetAllGenresLazyQuery>
export type GetAllGenresSuspenseQueryHookResult = ReturnType<typeof useGetAllGenresSuspenseQuery>
export type GetAllGenresQueryResult = Apollo.QueryResult<Types.GetAllGenresQuery, Types.GetAllGenresQueryVariables>
export const SaveGenreDraftDocument = gql`
    mutation saveGenreDraft($input: CreateOrUpdateCoreGenreDraftInput!) {
  createOrUpdateCoreGenreDraft(input: $input) {
    result {
      id
      draft {
        ...CoreGenreDraft
      }
    }
  }
}
    ${CoreGenreDraftFragmentDoc}`
export type SaveGenreDraftMutationFn = Apollo.MutationFunction<
  Types.SaveGenreDraftMutation,
  Types.SaveGenreDraftMutationVariables
>

/**
 * __useSaveGenreDraftMutation__
 *
 * To run a mutation, you first call `useSaveGenreDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGenreDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGenreDraftMutation, { data, loading, error }] = useSaveGenreDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveGenreDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveGenreDraftMutation, Types.SaveGenreDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveGenreDraftMutation, Types.SaveGenreDraftMutationVariables>(
    SaveGenreDraftDocument,
    options,
  )
}
export type SaveGenreDraftMutationHookResult = ReturnType<typeof useSaveGenreDraftMutation>
export type SaveGenreDraftMutationResult = Apollo.MutationResult<Types.SaveGenreDraftMutation>
export type SaveGenreDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveGenreDraftMutation,
  Types.SaveGenreDraftMutationVariables
>
export const PublishGenreDocument = gql`
    mutation publishGenre($input: PublishCoreGenreInput!) {
  publishCoreGenre(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGenreData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGenreDataFragmentDoc}`
export type PublishGenreMutationFn = Apollo.MutationFunction<
  Types.PublishGenreMutation,
  Types.PublishGenreMutationVariables
>

/**
 * __usePublishGenreMutation__
 *
 * To run a mutation, you first call `usePublishGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishGenreMutation, { data, loading, error }] = usePublishGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishGenreMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishGenreMutation, Types.PublishGenreMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishGenreMutation, Types.PublishGenreMutationVariables>(
    PublishGenreDocument,
    options,
  )
}
export type PublishGenreMutationHookResult = ReturnType<typeof usePublishGenreMutation>
export type PublishGenreMutationResult = Apollo.MutationResult<Types.PublishGenreMutation>
export type PublishGenreMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishGenreMutation,
  Types.PublishGenreMutationVariables
>
export const UnpublishGenreDocument = gql`
    mutation unpublishGenre($input: UnpublishCoreGenreInput!) {
  unpublishCoreGenre(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGenreData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGenreDataFragmentDoc}`
export type UnpublishGenreMutationFn = Apollo.MutationFunction<
  Types.UnpublishGenreMutation,
  Types.UnpublishGenreMutationVariables
>

/**
 * __useUnpublishGenreMutation__
 *
 * To run a mutation, you first call `useUnpublishGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishGenreMutation, { data, loading, error }] = useUnpublishGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishGenreMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishGenreMutation, Types.UnpublishGenreMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishGenreMutation, Types.UnpublishGenreMutationVariables>(
    UnpublishGenreDocument,
    options,
  )
}
export type UnpublishGenreMutationHookResult = ReturnType<typeof useUnpublishGenreMutation>
export type UnpublishGenreMutationResult = Apollo.MutationResult<Types.UnpublishGenreMutation>
export type UnpublishGenreMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishGenreMutation,
  Types.UnpublishGenreMutationVariables
>
export const DeleteGenreDraftDocument = gql`
    mutation deleteGenreDraft($input: DeleteCoreGenreDraftInput!) {
  deleteCoreGenreDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGenreData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGenreDataFragmentDoc}`
export type DeleteGenreDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteGenreDraftMutation,
  Types.DeleteGenreDraftMutationVariables
>

/**
 * __useDeleteGenreDraftMutation__
 *
 * To run a mutation, you first call `useDeleteGenreDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGenreDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGenreDraftMutation, { data, loading, error }] = useDeleteGenreDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGenreDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteGenreDraftMutation, Types.DeleteGenreDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteGenreDraftMutation, Types.DeleteGenreDraftMutationVariables>(
    DeleteGenreDraftDocument,
    options,
  )
}
export type DeleteGenreDraftMutationHookResult = ReturnType<typeof useDeleteGenreDraftMutation>
export type DeleteGenreDraftMutationResult = Apollo.MutationResult<Types.DeleteGenreDraftMutation>
export type DeleteGenreDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteGenreDraftMutation,
  Types.DeleteGenreDraftMutationVariables
>
export const GetGroupByIdDocument = gql`
    query getGroupById($id: ID!) {
  coreGroup(id: $id) {
    ...CoreGroupData
  }
}
    ${CoreGroupDataFragmentDoc}`

/**
 * __useGetGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables> &
    ({ variables: Types.GetGroupByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>(GetGroupByIdDocument, options)
}
export function useGetGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>(GetGroupByIdDocument, options)
}
export function useGetGroupByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>(
    GetGroupByIdDocument,
    options,
  )
}
export type GetGroupByIdQueryHookResult = ReturnType<typeof useGetGroupByIdQuery>
export type GetGroupByIdLazyQueryHookResult = ReturnType<typeof useGetGroupByIdLazyQuery>
export type GetGroupByIdSuspenseQueryHookResult = ReturnType<typeof useGetGroupByIdSuspenseQuery>
export type GetGroupByIdQueryResult = Apollo.QueryResult<Types.GetGroupByIdQuery, Types.GetGroupByIdQueryVariables>
export const SaveGroupDraftDocument = gql`
    mutation saveGroupDraft($input: CreateOrUpdateCoreGroupDraftInput!) {
  createOrUpdateCoreGroupDraft(input: $input) {
    result {
      ...CoreGroupData
    }
  }
}
    ${CoreGroupDataFragmentDoc}`
export type SaveGroupDraftMutationFn = Apollo.MutationFunction<
  Types.SaveGroupDraftMutation,
  Types.SaveGroupDraftMutationVariables
>

/**
 * __useSaveGroupDraftMutation__
 *
 * To run a mutation, you first call `useSaveGroupDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGroupDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGroupDraftMutation, { data, loading, error }] = useSaveGroupDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveGroupDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveGroupDraftMutation, Types.SaveGroupDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveGroupDraftMutation, Types.SaveGroupDraftMutationVariables>(
    SaveGroupDraftDocument,
    options,
  )
}
export type SaveGroupDraftMutationHookResult = ReturnType<typeof useSaveGroupDraftMutation>
export type SaveGroupDraftMutationResult = Apollo.MutationResult<Types.SaveGroupDraftMutation>
export type SaveGroupDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveGroupDraftMutation,
  Types.SaveGroupDraftMutationVariables
>
export const PublishGroupDocument = gql`
    mutation publishGroup($input: PublishCoreGroupInput!) {
  publishCoreGroup(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGroupData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGroupDataFragmentDoc}`
export type PublishGroupMutationFn = Apollo.MutationFunction<
  Types.PublishGroupMutation,
  Types.PublishGroupMutationVariables
>

/**
 * __usePublishGroupMutation__
 *
 * To run a mutation, you first call `usePublishGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishGroupMutation, { data, loading, error }] = usePublishGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishGroupMutation, Types.PublishGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishGroupMutation, Types.PublishGroupMutationVariables>(
    PublishGroupDocument,
    options,
  )
}
export type PublishGroupMutationHookResult = ReturnType<typeof usePublishGroupMutation>
export type PublishGroupMutationResult = Apollo.MutationResult<Types.PublishGroupMutation>
export type PublishGroupMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishGroupMutation,
  Types.PublishGroupMutationVariables
>
export const UnpublishGroupDocument = gql`
    mutation unpublishGroup($input: UnpublishCoreGroupInput!) {
  unpublishCoreGroup(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGroupData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGroupDataFragmentDoc}`
export type UnpublishGroupMutationFn = Apollo.MutationFunction<
  Types.UnpublishGroupMutation,
  Types.UnpublishGroupMutationVariables
>

/**
 * __useUnpublishGroupMutation__
 *
 * To run a mutation, you first call `useUnpublishGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishGroupMutation, { data, loading, error }] = useUnpublishGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishGroupMutation, Types.UnpublishGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishGroupMutation, Types.UnpublishGroupMutationVariables>(
    UnpublishGroupDocument,
    options,
  )
}
export type UnpublishGroupMutationHookResult = ReturnType<typeof useUnpublishGroupMutation>
export type UnpublishGroupMutationResult = Apollo.MutationResult<Types.UnpublishGroupMutation>
export type UnpublishGroupMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishGroupMutation,
  Types.UnpublishGroupMutationVariables
>
export const DeleteGroupDraftDocument = gql`
    mutation deleteGroupDraft($input: DeleteCoreGroupDraftInput!) {
  deleteCoreGroupDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreGroupData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreGroupDataFragmentDoc}`
export type DeleteGroupDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteGroupDraftMutation,
  Types.DeleteGroupDraftMutationVariables
>

/**
 * __useDeleteGroupDraftMutation__
 *
 * To run a mutation, you first call `useDeleteGroupDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupDraftMutation, { data, loading, error }] = useDeleteGroupDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteGroupDraftMutation, Types.DeleteGroupDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteGroupDraftMutation, Types.DeleteGroupDraftMutationVariables>(
    DeleteGroupDraftDocument,
    options,
  )
}
export type DeleteGroupDraftMutationHookResult = ReturnType<typeof useDeleteGroupDraftMutation>
export type DeleteGroupDraftMutationResult = Apollo.MutationResult<Types.DeleteGroupDraftMutation>
export type DeleteGroupDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteGroupDraftMutation,
  Types.DeleteGroupDraftMutationVariables
>
export const GetPartnerByIdDocument = gql`
    query getPartnerById($id: ID!) {
  corePartner(id: $id) {
    ...CorePartnerData
  }
}
    ${CorePartnerDataFragmentDoc}`

/**
 * __useGetPartnerByIdQuery__
 *
 * To run a query within a React component, call `useGetPartnerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables> &
    ({ variables: Types.GetPartnerByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables>(GetPartnerByIdDocument, options)
}
export function useGetPartnerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables>(
    GetPartnerByIdDocument,
    options,
  )
}
export function useGetPartnerByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetPartnerByIdQuery, Types.GetPartnerByIdQueryVariables>(
    GetPartnerByIdDocument,
    options,
  )
}
export type GetPartnerByIdQueryHookResult = ReturnType<typeof useGetPartnerByIdQuery>
export type GetPartnerByIdLazyQueryHookResult = ReturnType<typeof useGetPartnerByIdLazyQuery>
export type GetPartnerByIdSuspenseQueryHookResult = ReturnType<typeof useGetPartnerByIdSuspenseQuery>
export type GetPartnerByIdQueryResult = Apollo.QueryResult<
  Types.GetPartnerByIdQuery,
  Types.GetPartnerByIdQueryVariables
>
export const SavePartnerDraftDocument = gql`
    mutation savePartnerDraft($input: CreateOrUpdateCorePartnerDraftInput!) {
  createOrUpdateCorePartnerDraft(input: $input) {
    result {
      ...CorePartnerData
    }
  }
}
    ${CorePartnerDataFragmentDoc}`
export type SavePartnerDraftMutationFn = Apollo.MutationFunction<
  Types.SavePartnerDraftMutation,
  Types.SavePartnerDraftMutationVariables
>

/**
 * __useSavePartnerDraftMutation__
 *
 * To run a mutation, you first call `useSavePartnerDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePartnerDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePartnerDraftMutation, { data, loading, error }] = useSavePartnerDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePartnerDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SavePartnerDraftMutation, Types.SavePartnerDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SavePartnerDraftMutation, Types.SavePartnerDraftMutationVariables>(
    SavePartnerDraftDocument,
    options,
  )
}
export type SavePartnerDraftMutationHookResult = ReturnType<typeof useSavePartnerDraftMutation>
export type SavePartnerDraftMutationResult = Apollo.MutationResult<Types.SavePartnerDraftMutation>
export type SavePartnerDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SavePartnerDraftMutation,
  Types.SavePartnerDraftMutationVariables
>
export const PublishPartnerDocument = gql`
    mutation publishPartner($input: PublishCorePartnerInput!) {
  publishCorePartner(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CorePartnerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CorePartnerDataFragmentDoc}`
export type PublishPartnerMutationFn = Apollo.MutationFunction<
  Types.PublishPartnerMutation,
  Types.PublishPartnerMutationVariables
>

/**
 * __usePublishPartnerMutation__
 *
 * To run a mutation, you first call `usePublishPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPartnerMutation, { data, loading, error }] = usePublishPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishPartnerMutation, Types.PublishPartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishPartnerMutation, Types.PublishPartnerMutationVariables>(
    PublishPartnerDocument,
    options,
  )
}
export type PublishPartnerMutationHookResult = ReturnType<typeof usePublishPartnerMutation>
export type PublishPartnerMutationResult = Apollo.MutationResult<Types.PublishPartnerMutation>
export type PublishPartnerMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishPartnerMutation,
  Types.PublishPartnerMutationVariables
>
export const UnpublishPartnerDocument = gql`
    mutation unpublishPartner($input: UnpublishCorePartnerInput!) {
  unpublishCorePartner(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CorePartnerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CorePartnerDataFragmentDoc}`
export type UnpublishPartnerMutationFn = Apollo.MutationFunction<
  Types.UnpublishPartnerMutation,
  Types.UnpublishPartnerMutationVariables
>

/**
 * __useUnpublishPartnerMutation__
 *
 * To run a mutation, you first call `useUnpublishPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPartnerMutation, { data, loading, error }] = useUnpublishPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishPartnerMutation, Types.UnpublishPartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishPartnerMutation, Types.UnpublishPartnerMutationVariables>(
    UnpublishPartnerDocument,
    options,
  )
}
export type UnpublishPartnerMutationHookResult = ReturnType<typeof useUnpublishPartnerMutation>
export type UnpublishPartnerMutationResult = Apollo.MutationResult<Types.UnpublishPartnerMutation>
export type UnpublishPartnerMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishPartnerMutation,
  Types.UnpublishPartnerMutationVariables
>
export const DeletePartnerDraftDocument = gql`
    mutation deletePartnerDraft($input: DeleteCorePartnerDraftInput!) {
  deleteCorePartnerDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CorePartnerData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CorePartnerDataFragmentDoc}`
export type DeletePartnerDraftMutationFn = Apollo.MutationFunction<
  Types.DeletePartnerDraftMutation,
  Types.DeletePartnerDraftMutationVariables
>

/**
 * __useDeletePartnerDraftMutation__
 *
 * To run a mutation, you first call `useDeletePartnerDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerDraftMutation, { data, loading, error }] = useDeletePartnerDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeletePartnerDraftMutation, Types.DeletePartnerDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeletePartnerDraftMutation, Types.DeletePartnerDraftMutationVariables>(
    DeletePartnerDraftDocument,
    options,
  )
}
export type DeletePartnerDraftMutationHookResult = ReturnType<typeof useDeletePartnerDraftMutation>
export type DeletePartnerDraftMutationResult = Apollo.MutationResult<Types.DeletePartnerDraftMutation>
export type DeletePartnerDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePartnerDraftMutation,
  Types.DeletePartnerDraftMutationVariables
>
export const GetWorkByIdDocument = gql`
    query getWorkById($id: ID!) {
  coreWork(id: $id) {
    ...CoreWorkData
  }
}
    ${CoreWorkDataFragmentDoc}`

/**
 * __useGetWorkByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables> &
    ({ variables: Types.GetWorkByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>(GetWorkByIdDocument, options)
}
export function useGetWorkByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>(GetWorkByIdDocument, options)
}
export function useGetWorkByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>(GetWorkByIdDocument, options)
}
export type GetWorkByIdQueryHookResult = ReturnType<typeof useGetWorkByIdQuery>
export type GetWorkByIdLazyQueryHookResult = ReturnType<typeof useGetWorkByIdLazyQuery>
export type GetWorkByIdSuspenseQueryHookResult = ReturnType<typeof useGetWorkByIdSuspenseQuery>
export type GetWorkByIdQueryResult = Apollo.QueryResult<Types.GetWorkByIdQuery, Types.GetWorkByIdQueryVariables>
export const SaveWorkDraftDocument = gql`
    mutation saveWorkDraft($input: CreateOrUpdateCoreWorkDraftInput!) {
  createOrUpdateCoreWorkDraft(input: $input) {
    result {
      ...CoreWorkData
    }
  }
}
    ${CoreWorkDataFragmentDoc}`
export type SaveWorkDraftMutationFn = Apollo.MutationFunction<
  Types.SaveWorkDraftMutation,
  Types.SaveWorkDraftMutationVariables
>

/**
 * __useSaveWorkDraftMutation__
 *
 * To run a mutation, you first call `useSaveWorkDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkDraftMutation, { data, loading, error }] = useSaveWorkDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWorkDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SaveWorkDraftMutation, Types.SaveWorkDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SaveWorkDraftMutation, Types.SaveWorkDraftMutationVariables>(
    SaveWorkDraftDocument,
    options,
  )
}
export type SaveWorkDraftMutationHookResult = ReturnType<typeof useSaveWorkDraftMutation>
export type SaveWorkDraftMutationResult = Apollo.MutationResult<Types.SaveWorkDraftMutation>
export type SaveWorkDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveWorkDraftMutation,
  Types.SaveWorkDraftMutationVariables
>
export const PublishWorkDocument = gql`
    mutation publishWork($input: PublishCoreWorkInput!) {
  publishCoreWork(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreWorkData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreWorkDataFragmentDoc}`
export type PublishWorkMutationFn = Apollo.MutationFunction<
  Types.PublishWorkMutation,
  Types.PublishWorkMutationVariables
>

/**
 * __usePublishWorkMutation__
 *
 * To run a mutation, you first call `usePublishWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWorkMutation, { data, loading, error }] = usePublishWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PublishWorkMutation, Types.PublishWorkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PublishWorkMutation, Types.PublishWorkMutationVariables>(PublishWorkDocument, options)
}
export type PublishWorkMutationHookResult = ReturnType<typeof usePublishWorkMutation>
export type PublishWorkMutationResult = Apollo.MutationResult<Types.PublishWorkMutation>
export type PublishWorkMutationOptions = Apollo.BaseMutationOptions<
  Types.PublishWorkMutation,
  Types.PublishWorkMutationVariables
>
export const UnpublishWorkDocument = gql`
    mutation unpublishWork($input: UnpublishCoreWorkInput!) {
  unpublishCoreWork(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreWorkData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreWorkDataFragmentDoc}`
export type UnpublishWorkMutationFn = Apollo.MutationFunction<
  Types.UnpublishWorkMutation,
  Types.UnpublishWorkMutationVariables
>

/**
 * __useUnpublishWorkMutation__
 *
 * To run a mutation, you first call `useUnpublishWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishWorkMutation, { data, loading, error }] = useUnpublishWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UnpublishWorkMutation, Types.UnpublishWorkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UnpublishWorkMutation, Types.UnpublishWorkMutationVariables>(
    UnpublishWorkDocument,
    options,
  )
}
export type UnpublishWorkMutationHookResult = ReturnType<typeof useUnpublishWorkMutation>
export type UnpublishWorkMutationResult = Apollo.MutationResult<Types.UnpublishWorkMutation>
export type UnpublishWorkMutationOptions = Apollo.BaseMutationOptions<
  Types.UnpublishWorkMutation,
  Types.UnpublishWorkMutationVariables
>
export const DeleteWorkDraftDocument = gql`
    mutation deleteWorkDraft($input: DeleteCoreWorkDraftInput!) {
  deleteCoreWorkDraft(input: $input) {
    errors {
      ...AdminApiError
    }
    result {
      ...CoreWorkData
    }
  }
}
    ${AdminApiErrorFragmentDoc}
${CoreWorkDataFragmentDoc}`
export type DeleteWorkDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteWorkDraftMutation,
  Types.DeleteWorkDraftMutationVariables
>

/**
 * __useDeleteWorkDraftMutation__
 *
 * To run a mutation, you first call `useDeleteWorkDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkDraftMutation, { data, loading, error }] = useDeleteWorkDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteWorkDraftMutation, Types.DeleteWorkDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteWorkDraftMutation, Types.DeleteWorkDraftMutationVariables>(
    DeleteWorkDraftDocument,
    options,
  )
}
export type DeleteWorkDraftMutationHookResult = ReturnType<typeof useDeleteWorkDraftMutation>
export type DeleteWorkDraftMutationResult = Apollo.MutationResult<Types.DeleteWorkDraftMutation>
export type DeleteWorkDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteWorkDraftMutation,
  Types.DeleteWorkDraftMutationVariables
>
export const CopyFromR2WorkDocument = gql`
    mutation CopyFromR2Work($input: CopyR2WorkToCoreWorkInput) {
  copyR2WorkToCoreWork(input: $input) {
    errors {
      message
      path
    }
    result
  }
}
    `
export type CopyFromR2WorkMutationFn = Apollo.MutationFunction<
  Types.CopyFromR2WorkMutation,
  Types.CopyFromR2WorkMutationVariables
>

/**
 * __useCopyFromR2WorkMutation__
 *
 * To run a mutation, you first call `useCopyFromR2WorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyFromR2WorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyFromR2WorkMutation, { data, loading, error }] = useCopyFromR2WorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyFromR2WorkMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CopyFromR2WorkMutation, Types.CopyFromR2WorkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CopyFromR2WorkMutation, Types.CopyFromR2WorkMutationVariables>(
    CopyFromR2WorkDocument,
    options,
  )
}
export type CopyFromR2WorkMutationHookResult = ReturnType<typeof useCopyFromR2WorkMutation>
export type CopyFromR2WorkMutationResult = Apollo.MutationResult<Types.CopyFromR2WorkMutation>
export type CopyFromR2WorkMutationOptions = Apollo.BaseMutationOptions<
  Types.CopyFromR2WorkMutation,
  Types.CopyFromR2WorkMutationVariables
>
