export type Maybe<T> = T | undefined | null
export type InputMaybe<T> = T | undefined | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  Decimal: { input: number; output: number }
  NaiveDateTime: { input: string; output: string }
  NonEmptyString: { input: string; output: string }
  TimeWithFrames: { input: string; output: string }
}

export type AdminUser = {
  __typename: 'AdminUser'
  email: Scalars['String']['output']
  entraOid: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Album = Node & {
  __typename: 'Album'
  artistAndGroupDisplayInfo: Maybe<TranslatedString>
  artists: Maybe<CoreArtistWithRoleConnection>
  atmosUpc: Maybe<Scalars['String']['output']>
  copyright: Scalars['String']['output']
  editor: AdminUser
  groups: Array<CoreGroup>
  /** The ID of an object */
  id: Scalars['ID']['output']
  label: Label
  originalReleaseDate: Maybe<Scalars['Date']['output']>
  partners: Array<CorePartner>
  phonographicCopyright: Scalars['String']['output']
  pictures: Maybe<PictureWithEditorConnection>
  r2Id: Maybe<Scalars['Int']['output']>
  releaseDate: Maybe<Scalars['Date']['output']>
  status: AlbumPublicationStatus
  title: TranslatedString
  /** Total duration of all tracks combined in seconds */
  totalDuration: Scalars['Int']['output']
  trackSets: Array<TrackSet>
  upc: Scalars['String']['output']
}

export type AlbumArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type AlbumPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type AlbumConnection = {
  __typename: 'AlbumConnection'
  edges: Array<AlbumEdge>
  pageInfo: PageInfo
}

export type AlbumEdge = {
  __typename: 'AlbumEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: Album
  status: EdgePublicationStatus
}

export enum AlbumPublicationStatus {
  /** Album has just been deleted. Only returned as result of delete album mutation, not persisted */
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type AlbumStatus = {
  __typename: 'AlbumStatus'
  atmosUpc: Maybe<Scalars['String']['output']>
  isSuccess: Scalars['Boolean']['output']
  message: Scalars['String']['output']
  stereoUpc: Scalars['String']['output']
}

export type ApiError = {
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
}

export type ArtistWithRole = {
  artistId: Scalars['ID']['input']
  roleId: Scalars['ID']['input']
}

export type AuthenticationError = ApiError & {
  __typename: 'AuthenticationError'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
}

export type Banner = Node & {
  __typename: 'Banner'
  description: TranslatedString
  editor: AdminUser
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  link: TranslatedString
  pictures: Maybe<PictureConnection>
  title: TranslatedString
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type BannerPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type BannerData = Node & {
  __typename: 'BannerData'
  draft: Maybe<BannerDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<Banner>
  status: PublicationStatus
}

export type BannerDraft = {
  __typename: 'BannerDraft'
  description: Maybe<TranslatedString>
  editor: AdminUser
  insertedAt: Scalars['NaiveDateTime']['output']
  link: Maybe<TranslatedString>
  pictures: Maybe<PictureConnection>
  title: TranslatedString
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type BannerDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type BasicError = ApiError & {
  __typename: 'BasicError'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
}

export type BlockUserInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type BlockUserPayload = {
  __typename: 'BlockUserPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<User>
}

export type ConflictingNode = {
  __typename: 'ConflictingNode'
  id: Scalars['ID']['output']
  /** The Type of the node, used to construct URLs on the Frontend without having to touch the id. */
  type: Scalars['String']['output']
}

export type CopyLiveToVodConcertInput = {
  /** Id of the live concert to copy from */
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CopyLiveToVodConcertPayload = {
  __typename: 'CopyLiveToVodConcertPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertData>
}

export type CopyR2WorkToCoreWorkInput = {
  /** The r2 id of the r2 work that is supposed to be copied */
  r2WorkId: Scalars['Int']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CopyR2WorkToCoreWorkPayload = {
  __typename: 'CopyR2WorkToCoreWorkPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Scalars['ID']['output']>
}

export type CoreArtist = Node & {
  __typename: 'CoreArtist'
  biography: Maybe<TranslatedString>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  courtesyOf: Maybe<Scalars['String']['output']>
  dateOfBirth: Maybe<Scalars['String']['output']>
  dateOfDeath: Maybe<Scalars['String']['output']>
  editor: AdminUser
  epoch: Maybe<CoreEpoch>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  name: TranslatedString
  partyId: Maybe<Scalars['Int']['output']>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  roles: Maybe<CoreRoleConnection>
  sortLetter: Maybe<TranslatedString>
  sortName: TranslatedString
  title: Maybe<TranslatedString>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreArtistPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreArtistRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreArtistConnection = {
  __typename: 'CoreArtistConnection'
  edges: Array<CoreArtistEdge>
  pageInfo: PageInfo
}

export type CoreArtistData = Node & {
  __typename: 'CoreArtistData'
  draft: Maybe<CoreArtistDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CoreArtist>
  status: PublicationStatus
}

export type CoreArtistDraft = {
  __typename: 'CoreArtistDraft'
  biography: Maybe<TranslatedString>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  courtesyOf: Maybe<Scalars['String']['output']>
  dateOfBirth: Maybe<Scalars['String']['output']>
  dateOfDeath: Maybe<Scalars['String']['output']>
  editor: AdminUser
  epoch: Maybe<CoreEpoch>
  insertedAt: Scalars['NaiveDateTime']['output']
  name: Maybe<TranslatedString>
  partyId: Maybe<Scalars['Int']['output']>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  roles: Maybe<CoreRoleConnection>
  sortLetter: Maybe<TranslatedString>
  sortName: Maybe<TranslatedString>
  title: Maybe<TranslatedString>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreArtistDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreArtistDraftRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreArtistEdge = {
  __typename: 'CoreArtistEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: CoreArtist
  status: EdgePublicationStatus
}

export type CoreArtistWithRoleConnection = {
  __typename: 'CoreArtistWithRoleConnection'
  edges: Array<CoreArtistWithRoleEdge>
  pageInfo: PageInfo
}

export type CoreArtistWithRoleEdge = {
  __typename: 'CoreArtistWithRoleEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: CoreArtist
  role: CoreRole
  status: EdgePublicationStatus
}

export type CoreEpoch = Node & {
  __typename: 'CoreEpoch'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  endYear: Maybe<Scalars['Int']['output']>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  pictures: Maybe<PictureConnection>
  startYear: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<TranslatedString>
  title: TranslatedString
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreEpochPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreEpochData = Node & {
  __typename: 'CoreEpochData'
  draft: Maybe<CoreEpochDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CoreEpoch>
  status: PublicationStatus
}

export type CoreEpochDataConnection = {
  __typename: 'CoreEpochDataConnection'
  edges: Array<CoreEpochDataEdge>
  pageInfo: PageInfo
}

export type CoreEpochDataEdge = {
  __typename: 'CoreEpochDataEdge'
  cursor: Scalars['String']['output']
  node: CoreEpochData
}

export type CoreEpochDraft = {
  __typename: 'CoreEpochDraft'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  endYear: Maybe<Scalars['Int']['output']>
  insertedAt: Scalars['NaiveDateTime']['output']
  pictures: Maybe<PictureConnection>
  startYear: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<TranslatedString>
  title: Maybe<TranslatedString>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreEpochDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreGenre = Node & {
  __typename: 'CoreGenre'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  editor: AdminUser
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  pictures: Maybe<PictureConnection>
  title: TranslatedString
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreGenrePicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreGenreData = Node & {
  __typename: 'CoreGenreData'
  draft: Maybe<CoreGenreDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CoreGenre>
  status: PublicationStatus
}

export type CoreGenreDataConnection = {
  __typename: 'CoreGenreDataConnection'
  edges: Array<CoreGenreDataEdge>
  pageInfo: PageInfo
}

export type CoreGenreDataEdge = {
  __typename: 'CoreGenreDataEdge'
  cursor: Scalars['String']['output']
  node: CoreGenreData
}

export type CoreGenreDraft = {
  __typename: 'CoreGenreDraft'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  editor: AdminUser
  insertedAt: Scalars['NaiveDateTime']['output']
  pictures: Maybe<PictureConnection>
  title: Maybe<TranslatedString>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreGenreDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreGroup = Node & {
  __typename: 'CoreGroup'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  footnote: Maybe<TranslatedString>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  name: TranslatedString
  partyId: Maybe<Scalars['Int']['output']>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  sortLetter: Maybe<TranslatedString>
  type: GroupType
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreGroupPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreGroupConnection = {
  __typename: 'CoreGroupConnection'
  edges: Array<CoreGroupEdge>
  pageInfo: PageInfo
}

export type CoreGroupData = Node & {
  __typename: 'CoreGroupData'
  draft: Maybe<CoreGroupDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CoreGroup>
  status: PublicationStatus
}

export type CoreGroupDraft = {
  __typename: 'CoreGroupDraft'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  footnote: Maybe<TranslatedString>
  insertedAt: Scalars['NaiveDateTime']['output']
  name: Maybe<TranslatedString>
  partyId: Maybe<Scalars['Int']['output']>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  sortLetter: Maybe<TranslatedString>
  type: Maybe<GroupType>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CoreGroupDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreGroupEdge = {
  __typename: 'CoreGroupEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: CoreGroup
  status: EdgePublicationStatus
}

export type CoreMovement = Node & {
  __typename: 'CoreMovement'
  /** The ID of an object */
  id: Scalars['ID']['output']
  title: Maybe<TranslatedString>
}

export type CoreMovementDraftInput = {
  title: InputMaybe<TranslatableString>
}

export type CorePartner = Node & {
  __typename: 'CorePartner'
  albums: Maybe<AlbumConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  name: TranslatedString
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  sortLetter: Maybe<TranslatedString>
  subtype: Maybe<PartnerSubtype>
  type: PartnerType
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CorePartnerAlbumsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CorePartnerPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CorePartnerConnection = {
  __typename: 'CorePartnerConnection'
  edges: Array<CorePartnerEdge>
  pageInfo: PageInfo
}

export type CorePartnerData = Node & {
  __typename: 'CorePartnerData'
  draft: Maybe<CorePartnerDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CorePartner>
  status: PublicationStatus
}

export type CorePartnerDraft = {
  __typename: 'CorePartnerDraft'
  albums: Maybe<AlbumConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  description: Maybe<TranslatedString>
  editor: AdminUser
  insertedAt: Scalars['NaiveDateTime']['output']
  name: Maybe<TranslatedString>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  sortLetter: Maybe<TranslatedString>
  subtype: Maybe<PartnerSubtype>
  type: PartnerType
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CorePartnerDraftAlbumsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CorePartnerDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CorePartnerEdge = {
  __typename: 'CorePartnerEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: CorePartner
  status: EdgePublicationStatus
}

export type CoreRole = Node & {
  __typename: 'CoreRole'
  displayName: Scalars['String']['output']
  /** The ID of an object */
  id: Scalars['ID']['output']
  subtype: Maybe<Scalars['String']['output']>
  type: RoleType
}

export type CoreRoleConnection = {
  __typename: 'CoreRoleConnection'
  edges: Array<CoreRoleEdge>
  pageInfo: PageInfo
}

export type CoreRoleEdge = {
  __typename: 'CoreRoleEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: CoreRole
  status: EdgePublicationStatus
}

export type CoreRoleListConnection = {
  __typename: 'CoreRoleListConnection'
  edges: Array<CoreRoleListEdge>
  pageInfo: PageInfo
}

export type CoreRoleListEdge = {
  __typename: 'CoreRoleListEdge'
  cursor: Scalars['String']['output']
  node: CoreRole
}

export type CoreWork = Node & {
  __typename: 'CoreWork'
  arrangers: Maybe<CoreArtistConnection>
  composers: Maybe<CoreArtistConnection>
  copyright: Maybe<Scalars['String']['output']>
  editor: AdminUser
  epochs: Array<CoreEpoch>
  genre: Maybe<CoreGenre>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  librettists: Maybe<CoreArtistConnection>
  movements: Array<CoreMovement>
  publicDomain: Maybe<Scalars['Boolean']['output']>
  publisher: Maybe<Scalars['String']['output']>
  r2Work: Maybe<R2Work>
  r2idNote: Maybe<Scalars['String']['output']>
  title: TranslatedString
  updatedAt: Scalars['NaiveDateTime']['output']
  yearOfComposition: Maybe<Scalars['Int']['output']>
}

export type CoreWorkArrangersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreWorkComposersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreWorkLibrettistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreWorkData = Node & {
  __typename: 'CoreWorkData'
  draft: Maybe<CoreWorkDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CoreWork>
  status: PublicationStatus
}

export type CoreWorkDraft = {
  __typename: 'CoreWorkDraft'
  arrangers: Maybe<CoreArtistConnection>
  composers: Maybe<CoreArtistConnection>
  copyright: Maybe<Scalars['String']['output']>
  editor: AdminUser
  epochs: Array<CoreEpoch>
  genre: Maybe<CoreGenre>
  insertedAt: Scalars['NaiveDateTime']['output']
  librettists: Maybe<CoreArtistConnection>
  movements: Array<CoreMovement>
  publicDomain: Maybe<Scalars['Boolean']['output']>
  publisher: Maybe<Scalars['String']['output']>
  r2Work: Maybe<R2Work>
  r2idNote: Maybe<Scalars['String']['output']>
  title: Maybe<TranslatedString>
  updatedAt: Scalars['NaiveDateTime']['output']
  yearOfComposition: Maybe<Scalars['Int']['output']>
}

export type CoreWorkDraftArrangersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreWorkDraftComposersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type CoreWorkDraftLibrettistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type Country = {
  __typename: 'Country'
  displayName: Scalars['String']['output']
  iso: Scalars['String']['output']
}

export type CreateOrUpdateBannerDraftInput = {
  description: InputMaybe<TranslatableString>
  id: InputMaybe<Scalars['ID']['input']>
  link: InputMaybe<TranslatableString>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  title: InputMaybe<TranslatableString>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateBannerDraftPayload = {
  __typename: 'CreateOrUpdateBannerDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<BannerData>
}

export type CreateOrUpdateCoreArtistDraftInput = {
  biography: InputMaybe<TranslatableString>
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  courtesyOf: InputMaybe<Scalars['String']['input']>
  /** ISO 8601 formatted date. May be year or year/month only. */
  dateOfBirth: InputMaybe<Scalars['String']['input']>
  /** ISO 8601 formatted date. May be year or year/month only. */
  dateOfDeath: InputMaybe<Scalars['String']['input']>
  epochId: InputMaybe<Scalars['ID']['input']>
  id: InputMaybe<Scalars['ID']['input']>
  name: InputMaybe<TranslatableString>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  roleIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  sortLetter: InputMaybe<TranslatableString>
  sortName: InputMaybe<TranslatableString>
  title: InputMaybe<TranslatableString>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCoreArtistDraftPayload = {
  __typename: 'CreateOrUpdateCoreArtistDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreArtistData>
}

export type CreateOrUpdateCoreEpochDraftInput = {
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description: InputMaybe<TranslatableString>
  endYear: InputMaybe<Scalars['Int']['input']>
  id: InputMaybe<Scalars['ID']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  startYear: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<TranslatableString>
  title: InputMaybe<TranslatableString>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCoreEpochDraftPayload = {
  __typename: 'CreateOrUpdateCoreEpochDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreEpochData>
}

export type CreateOrUpdateCoreGenreDraftInput = {
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: InputMaybe<Scalars['ID']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  title: InputMaybe<TranslatableString>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCoreGenreDraftPayload = {
  __typename: 'CreateOrUpdateCoreGenreDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGenreData>
}

export type CreateOrUpdateCoreGroupDraftInput = {
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description: InputMaybe<TranslatableString>
  footnote: InputMaybe<TranslatableString>
  id: InputMaybe<Scalars['ID']['input']>
  name: InputMaybe<TranslatableString>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  sortLetter: InputMaybe<TranslatableString>
  type: InputMaybe<GroupType>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCoreGroupDraftPayload = {
  __typename: 'CreateOrUpdateCoreGroupDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGroupData>
}

export type CreateOrUpdateCorePartnerDraftInput = {
  albumIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description: InputMaybe<TranslatableString>
  id: InputMaybe<Scalars['ID']['input']>
  name: InputMaybe<TranslatableString>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  sortLetter: InputMaybe<TranslatableString>
  subtype: InputMaybe<PartnerSubtype>
  type: PartnerType
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCorePartnerDraftPayload = {
  __typename: 'CreateOrUpdateCorePartnerDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CorePartnerData>
}

export type CreateOrUpdateCoreWorkDraftInput = {
  arrangerIds: InputMaybe<Array<Scalars['ID']['input']>>
  composerIds: InputMaybe<Array<Scalars['ID']['input']>>
  copyright: InputMaybe<Scalars['String']['input']>
  genreId: InputMaybe<Scalars['ID']['input']>
  id: InputMaybe<Scalars['ID']['input']>
  librettistIds: InputMaybe<Array<Scalars['ID']['input']>>
  movements: InputMaybe<Array<CoreMovementDraftInput>>
  publicDomain: InputMaybe<Scalars['Boolean']['input']>
  publisher: InputMaybe<Scalars['String']['input']>
  r2WorkId: InputMaybe<Scalars['ID']['input']>
  r2idNote: InputMaybe<Scalars['String']['input']>
  title: InputMaybe<TranslatableString>
  yearOfComposition: InputMaybe<Scalars['Int']['input']>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCoreWorkDraftPayload = {
  __typename: 'CreateOrUpdateCoreWorkDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreWorkData>
}

export type CreateOrUpdateCuratedContentDraftInput = {
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCuratedContentDraftPayload = {
  __typename: 'CreateOrUpdateCuratedContentDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedContentData>
}

export type CreateOrUpdateCuratedSliderDraftInput = {
  /** Freeform text to help editors reference sliders in search for better discoverability */
  description: InputMaybe<Scalars['String']['input']>
  headline: InputMaybe<TranslatableString>
  id: InputMaybe<Scalars['ID']['input']>
  /** Only relevant for type=`editorial`. Takes the list of items that are supposed to be displayed in the editorial slider. */
  itemIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Only relevant for type-`featured_artist_audio`. Sets the party_id for the artist whose albums you want to display. */
  partyId: InputMaybe<Scalars['Int']['input']>
  type: InputMaybe<CuratedSliderType>
  /** Which user groups should see this slider? */
  visibleFor: InputMaybe<Array<CuratedSliderVisibleFor>>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateCuratedSliderDraftPayload = {
  __typename: 'CreateOrUpdateCuratedSliderDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedSliderData>
}

export type CreateOrUpdateLiveConcertDraftInput = {
  concertArtists: InputMaybe<Array<ArtistWithRole>>
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  copyright: InputMaybe<Scalars['String']['input']>
  courtesyOf: InputMaybe<Scalars['String']['input']>
  endTime: InputMaybe<Scalars['DateTime']['input']>
  geoAccessCountries: InputMaybe<Array<Scalars['String']['input']>>
  geoAccessMode: InputMaybe<GeoAccessMode>
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: InputMaybe<Scalars['ID']['input']>
  isAtmos: InputMaybe<Scalars['Boolean']['input']>
  isHdr: InputMaybe<Scalars['Boolean']['input']>
  isLossless: InputMaybe<Scalars['Boolean']['input']>
  isrc: InputMaybe<Scalars['String']['input']>
  licensor: InputMaybe<Scalars['String']['input']>
  longFormConcertIntroduction: InputMaybe<TranslatableString>
  maxResolution: InputMaybe<MaxResolution>
  partnerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  performanceWorkIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  plannedForVod: InputMaybe<Scalars['Boolean']['input']>
  production: InputMaybe<Scalars['String']['input']>
  publicationLevel: InputMaybe<PublicationLevel>
  /** Maximum of five (5) reruns allowed */
  reruns: InputMaybe<Array<LiveConcertRerunInput>>
  seriesTitle: InputMaybe<TranslatableString>
  shortDescription: InputMaybe<TranslatableString>
  startTime: InputMaybe<Scalars['DateTime']['input']>
  streamStartTime: InputMaybe<Scalars['DateTime']['input']>
  subtitle: InputMaybe<TranslatableString>
  title: InputMaybe<TranslatableString>
  totalDuration: InputMaybe<Scalars['Int']['input']>
  trailerIsrc: InputMaybe<Scalars['String']['input']>
  type: InputMaybe<LiveConcertType>
  videoDirector: InputMaybe<Scalars['String']['input']>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateLiveConcertDraftPayload = {
  __typename: 'CreateOrUpdateLiveConcertDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertData>
}

export type CreateOrUpdatePerformanceWorkDraftInput = {
  artists: InputMaybe<Array<ArtistWithRole>>
  concertId: Scalars['ID']['input']
  cuePoints: InputMaybe<Array<CuePointInput>>
  duration: Scalars['Int']['input']
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: InputMaybe<Scalars['ID']['input']>
  mvIsrc: InputMaybe<Scalars['String']['input']>
  performanceIsrc: Scalars['String']['input']
  workId: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdatePerformanceWorkDraftPayload = {
  __typename: 'CreateOrUpdatePerformanceWorkDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PerformanceWorkData>
}

export type CreateOrUpdateStageDraftInput = {
  id: InputMaybe<Scalars['ID']['input']>
  items: InputMaybe<Array<InputMaybe<CreateOrUpdateStageItemDraftInput>>>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateStageDraftPayload = {
  __typename: 'CreateOrUpdateStageDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<StageData>
}

export type CreateOrUpdateStageItemDraftInput = {
  itemId: Scalars['ID']['input']
  label: InputMaybe<TranslatableString>
  subtitle: InputMaybe<TranslatableString>
  title: InputMaybe<TranslatableString>
}

export type CreateOrUpdateVideoDraftInput = {
  albumIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  archiveReleaseDate: InputMaybe<Scalars['DateTime']['input']>
  artists: InputMaybe<Array<ArtistWithRole>>
  concertIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  copyright: InputMaybe<Scalars['String']['input']>
  courtesyOf: InputMaybe<Scalars['String']['input']>
  description: InputMaybe<TranslatableString>
  duration: InputMaybe<Scalars['Int']['input']>
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: InputMaybe<Array<Scalars['String']['input']>>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: InputMaybe<GeoAccessMode>
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: InputMaybe<Scalars['ID']['input']>
  isAtmos: InputMaybe<Scalars['Boolean']['input']>
  isHdr: InputMaybe<Scalars['Boolean']['input']>
  isLossless: InputMaybe<Scalars['Boolean']['input']>
  isrc: InputMaybe<Scalars['String']['input']>
  licensor: InputMaybe<Scalars['String']['input']>
  maxResolution: InputMaybe<MaxResolution>
  partnerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  production: InputMaybe<Scalars['String']['input']>
  productionDate: InputMaybe<Scalars['Date']['input']>
  /** The level that is needed for a user to access the stream of this vod-concert. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel
  subtitle: InputMaybe<TranslatableString>
  takedownDate: InputMaybe<Scalars['DateTime']['input']>
  title: InputMaybe<TranslatableString>
  trailerIsrc: InputMaybe<Scalars['String']['input']>
  type: VideoType
  videoDirector: InputMaybe<Scalars['String']['input']>
  workId: InputMaybe<Scalars['ID']['input']>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateVideoDraftPayload = {
  __typename: 'CreateOrUpdateVideoDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VideoData>
}

export type CreateOrUpdateVodConcertDraftInput = {
  /** The concert was first aired on this date */
  airDate: InputMaybe<Scalars['Date']['input']>
  /** The concert is available from this date */
  archiveReleaseDate: InputMaybe<Scalars['DateTime']['input']>
  concertArtists: InputMaybe<Array<ArtistWithRole>>
  contentContainerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  copyright: InputMaybe<Scalars['String']['input']>
  courtesyOf: InputMaybe<Scalars['String']['input']>
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: InputMaybe<Array<Scalars['String']['input']>>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: InputMaybe<GeoAccessMode>
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id: InputMaybe<Scalars['ID']['input']>
  isAtmos: InputMaybe<Scalars['Boolean']['input']>
  isHdr: InputMaybe<Scalars['Boolean']['input']>
  isImmersive: InputMaybe<Scalars['Boolean']['input']>
  isLossless: InputMaybe<Scalars['Boolean']['input']>
  isMvVideo: InputMaybe<Scalars['Boolean']['input']>
  isrc: InputMaybe<Scalars['String']['input']>
  licensor: InputMaybe<Scalars['String']['input']>
  longFormConcertIntroduction: InputMaybe<TranslatableString>
  maxResolution: InputMaybe<MaxResolution>
  partnerIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  performanceWorkIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  photographer: InputMaybe<Scalars['String']['input']>
  pictureIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  production: InputMaybe<Scalars['String']['input']>
  /** Production was finalized on this date */
  productionDate: InputMaybe<Scalars['Date']['input']>
  /** The level that is needed for a user to access the stream of this vod-concert. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: InputMaybe<PublicationLevel>
  seriesTitle: InputMaybe<TranslatableString>
  shortDescription: InputMaybe<TranslatableString>
  subtitle: InputMaybe<TranslatableString>
  /** The concert is available till this date */
  takedownDate: InputMaybe<Scalars['DateTime']['input']>
  title: InputMaybe<TranslatableString>
  totalDuration: InputMaybe<Scalars['Int']['input']>
  trailerIsrc: InputMaybe<Scalars['String']['input']>
  type: InputMaybe<VodConcertType>
  videoDirector: InputMaybe<Scalars['String']['input']>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreateOrUpdateVodConcertDraftPayload = {
  __typename: 'CreateOrUpdateVodConcertDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VodConcertData>
}

export type CreatePictureInput = {
  type: PictureType
  url: Scalars['String']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CreatePicturePayload = {
  __typename: 'CreatePicturePayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Picture>
}

export type CuePoint = {
  __typename: 'CuePoint'
  id: Scalars['ID']['output']
  /** Marker position in seconds */
  mark: Scalars['Int']['output']
  title: TranslatedString
}

export type CuePointInput = {
  /** Marker position in seconds */
  mark: Scalars['Int']['input']
  title: TranslatableString
}

export type CuratedContent = Node & {
  __typename: 'CuratedContent'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  editor: AdminUser
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  slug: CuratedContentSlug
  stage: Maybe<Stage>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type CuratedContentData = Node & {
  __typename: 'CuratedContentData'
  draft: Maybe<CuratedContentDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CuratedContent>
  status: PublicationStatus
}

export type CuratedContentDataConnection = {
  __typename: 'CuratedContentDataConnection'
  edges: Array<CuratedContentDataEdge>
  pageInfo: PageInfo
}

export type CuratedContentDataEdge = {
  __typename: 'CuratedContentDataEdge'
  cursor: Scalars['String']['output']
  node: CuratedContentData
}

export type CuratedContentDraft = {
  __typename: 'CuratedContentDraft'
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  editor: AdminUser
  insertedAt: Scalars['NaiveDateTime']['output']
  slug: CuratedContentSlug
  updatedAt: Scalars['NaiveDateTime']['output']
}

export enum CuratedContentSlug {
  Audio = 'AUDIO',
  Discover = 'DISCOVER',
  LandingPage = 'LANDING_PAGE',
  Live = 'LIVE',
  Search = 'SEARCH',
}

export type CuratedSlider = Node & {
  __typename: 'CuratedSlider'
  description: Maybe<Scalars['String']['output']>
  editor: AdminUser
  headline: TranslatedString
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Only relevant for type=`editorial`. List of items the editor chose for this slider. */
  items: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  /** Only relevant for type=`featured_artist_audio`. Party_id of the artist you want to display albums for */
  partyId: Maybe<Scalars['Int']['output']>
  type: CuratedSliderType
  updatedAt: Scalars['NaiveDateTime']['output']
  /** User-Groups that will see this slider. */
  visibleFor: Array<CuratedSliderVisibleFor>
}

export type CuratedSliderData = Node & {
  __typename: 'CuratedSliderData'
  draft: Maybe<CuratedSliderDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<CuratedSlider>
  status: PublicationStatus
}

export type CuratedSliderDraft = {
  __typename: 'CuratedSliderDraft'
  /** Freeform text to help editors reference sliders in search for better discoverability */
  description: Maybe<Scalars['String']['output']>
  editor: AdminUser
  headline: TranslatedString
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Only relevant for type=`editorial`. List of items the editor chose for this slider. */
  items: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  /** Only relevant for type=`featured_artist_audio`. Party_id of the artist you want to display albums for */
  partyId: Maybe<Scalars['Int']['output']>
  type: CuratedSliderType
  updatedAt: Scalars['NaiveDateTime']['output']
  /** User-Groups that will see this slider. */
  visibleFor: Array<CuratedSliderVisibleFor>
}

export enum CuratedSliderType {
  AlbumsAtmos = 'ALBUMS_ATMOS',
  AvailableSoon = 'AVAILABLE_SOON',
  Editorial = 'EDITORIAL',
  Epochs = 'EPOCHS',
  FeaturedArtistAudio = 'FEATURED_ARTIST_AUDIO',
  Free = 'FREE',
  Genres = 'GENRES',
  LatestAlbums = 'LATEST_ALBUMS',
  NewInArchive = 'NEW_IN_ARCHIVE',
  NextLive = 'NEXT_LIVE',
  Series = 'SERIES',
  Videos_4K = 'VIDEOS_4K',
  VideosAtmos = 'VIDEOS_ATMOS',
  VideoDocumentary = 'VIDEO_DOCUMENTARY',
  VideoInterview = 'VIDEO_INTERVIEW',
  VideoShort = 'VIDEO_SHORT',
}

export enum CuratedSliderVisibleFor {
  AccountRequired = 'ACCOUNT_REQUIRED',
  NoAuthentication = 'NO_AUTHENTICATION',
  TicketRequired = 'TICKET_REQUIRED',
}

export type DeleteAlbumInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteAlbumPayload = {
  __typename: 'DeleteAlbumPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Album>
}

export type DeleteBannerDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteBannerDraftPayload = {
  __typename: 'DeleteBannerDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<BannerData>
}

export type DeleteCoreArtistDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCoreArtistDraftPayload = {
  __typename: 'DeleteCoreArtistDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreArtistData>
}

export type DeleteCoreEpochDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCoreEpochDraftPayload = {
  __typename: 'DeleteCoreEpochDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreEpochData>
}

export type DeleteCoreGenreDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCoreGenreDraftPayload = {
  __typename: 'DeleteCoreGenreDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGenreData>
}

export type DeleteCoreGroupDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCoreGroupDraftPayload = {
  __typename: 'DeleteCoreGroupDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGroupData>
}

export type DeleteCorePartnerDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCorePartnerDraftPayload = {
  __typename: 'DeleteCorePartnerDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CorePartnerData>
}

export type DeleteCoreWorkDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCoreWorkDraftPayload = {
  __typename: 'DeleteCoreWorkDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreWorkData>
}

export type DeleteCuratedSliderDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteCuratedSliderDraftPayload = {
  __typename: 'DeleteCuratedSliderDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedSliderData>
}

export type DeleteFromSearchIndexInput = {
  id: Scalars['ID']['input']
  index: SearchIndex
}

export type DeleteFromSearchIndexPayload = {
  __typename: 'DeleteFromSearchIndexPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** 'true' when the delete operation was successfully submitted to Algolia */
  result: Maybe<Scalars['Boolean']['output']>
}

export type DeleteLiveConcertDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteLiveConcertDraftPayload = {
  __typename: 'DeleteLiveConcertDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertData>
}

export type DeletePerformanceWorkDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeletePerformanceWorkDraftPayload = {
  __typename: 'DeletePerformanceWorkDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PerformanceWorkData>
}

export type DeleteUserAddressInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteUserAddressPayload = {
  __typename: 'DeleteUserAddressPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<User>
}

export type DeleteUserInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteUserPayload = {
  __typename: 'DeleteUserPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<User>
}

export type DeleteUserTicketInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteUserTicketPayload = {
  __typename: 'DeleteUserTicketPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<User>
}

export type DeleteVideoDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteVideoDraftPayload = {
  __typename: 'DeleteVideoDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VideoData>
}

export type DeleteVodConcertDraftInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteVodConcertDraftPayload = {
  __typename: 'DeleteVodConcertDraftPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VodConcertData>
}

export type Device = {
  __typename: 'Device'
  clientDeviceId: Maybe<Scalars['String']['output']>
  deviceCode: Maybe<Scalars['String']['output']>
  deviceInfo: Maybe<Scalars['String']['output']>
  externalId: Maybe<Scalars['String']['output']>
}

export enum EdgePublicationStatus {
  /** The connection is marked for deletion on next publication */
  DeleteOnPublish = 'DELETE_ON_PUBLISH',
  /** The connection is published */
  Published = 'PUBLISHED',
  /** The connection has never been published */
  Unpublished = 'UNPUBLISHED',
}

export enum GeoAccessMode {
  Allow = 'ALLOW',
  Deny = 'DENY',
}

export type GeoBlocking = {
  __typename: 'GeoBlocking'
  geoAccessCountries: Array<Scalars['String']['output']>
  geoAccessMode: GeoAccessMode
}

export enum GroupType {
  Band = 'BAND',
  ChamberEnsemble = 'CHAMBER_ENSEMBLE',
  ChamberOrchestra = 'CHAMBER_ORCHESTRA',
  Choir = 'CHOIR',
  Combo = 'COMBO',
  Duo = 'DUO',
  Ensemble = 'ENSEMBLE',
  Octett = 'OCTETT',
  Orchestra = 'ORCHESTRA',
  Quartet = 'QUARTET',
  Trio = 'TRIO',
  Various = 'VARIOUS',
}

export type ImporterAlbumResult = {
  __typename: 'ImporterAlbumResult'
  atmosUpc: Maybe<Scalars['String']['output']>
  importMessage: Maybe<Scalars['String']['output']>
  importRejectedIds: Array<Scalars['String']['output']>
  importResult: Scalars['String']['output']
  importStatus: Scalars['String']['output']
  title: Scalars['String']['output']
  upc: Scalars['String']['output']
}

export type ImporterImportSummary = {
  __typename: 'ImporterImportSummary'
  albumsError: Scalars['Int']['output']
  albumsInserted: Scalars['Int']['output']
  albumsRejected: Scalars['Int']['output']
  albumsSuccess: Scalars['Int']['output']
  albumsUnknownStatus: Scalars['Int']['output']
  albumsUpdated: Scalars['Int']['output']
  albumsWarning: Scalars['Int']['output']
  releasesImport: Scalars['Int']['output']
  releasesInFile: Scalars['Int']['output']
  releasesSkipSubRelease: Scalars['Int']['output']
}

export type ImporterLastRunStatus = {
  __typename: 'ImporterLastRunStatus'
  importFinishedAt: Scalars['NaiveDateTime']['output']
  importStartedAt: Scalars['NaiveDateTime']['output']
  message: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
}

export type ImporterProgress = {
  __typename: 'ImporterProgress'
  processed: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type ImporterStatus = {
  __typename: 'ImporterStatus'
  albumResults: Array<ImporterAlbumResult>
  importStartedAt: Maybe<Scalars['NaiveDateTime']['output']>
  importSummary: Maybe<ImporterImportSummary>
  lastRunStatus: Maybe<ImporterLastRunStatus>
  progress: Maybe<ImporterProgress>
  state: Scalars['String']['output']
}

export type JobStatus = {
  __typename: 'JobStatus'
  isrc: Scalars['String']['output']
  message: Scalars['String']['output']
  status: Scalars['String']['output']
  url: Maybe<Scalars['String']['output']>
}

export type Label = Node & {
  __typename: 'Label'
  /** The ID of an object */
  id: Scalars['ID']['output']
  title: TranslatedString
}

export type LastRunResult = {
  __typename: 'LastRunResult'
  albumStatuses: Array<AlbumStatus>
  albumsPackagedErrors: Scalars['Int']['output']
  albumsPackagedSuccesses: Scalars['Int']['output']
  albumsPackagedTotal: Scalars['Int']['output']
}

export type LiveConcert = Node & {
  __typename: 'LiveConcert'
  artists: Maybe<CoreArtistWithRoleConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  editor: AdminUser
  endTime: Scalars['DateTime']['output']
  geoAccessCountries: Array<Scalars['String']['output']>
  geoAccessMode: GeoAccessMode
  groups: Maybe<CoreGroupConnection>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  longFormConcertIntroduction: Maybe<TranslatedString>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  performanceWorks: Maybe<PerformanceWorkConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  plannedForVod: Scalars['Boolean']['output']
  production: Maybe<Scalars['String']['output']>
  publicationLevel: PublicationLevel
  reruns: Array<LiveConcertRerun>
  seriesTitle: Maybe<TranslatedString>
  shortDescription: Maybe<TranslatedString>
  startTime: Scalars['DateTime']['output']
  streamStartTime: Maybe<Scalars['DateTime']['output']>
  subtitle: Maybe<TranslatedString>
  title: TranslatedString
  totalDuration: Scalars['Int']['output']
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: LiveConcertType
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
  /** A connected vod-concert that was created by copying live-concert data */
  vodConcert: Maybe<VodConcert>
}

export type LiveConcertArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertPerformanceWorksArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertData = Node & {
  __typename: 'LiveConcertData'
  draft: Maybe<LiveConcertDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<LiveConcert>
  status: PublicationStatus
}

export type LiveConcertDraft = {
  __typename: 'LiveConcertDraft'
  artists: Maybe<CoreArtistWithRoleConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  editor: AdminUser
  endTime: Maybe<Scalars['DateTime']['output']>
  geoAccessCountries: Maybe<Array<Scalars['String']['output']>>
  geoAccessMode: Maybe<GeoAccessMode>
  groups: Maybe<CoreGroupConnection>
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  longFormConcertIntroduction: Maybe<TranslatedString>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  performanceWorks: Maybe<PerformanceWorkConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  plannedForVod: Scalars['Boolean']['output']
  production: Maybe<Scalars['String']['output']>
  publicationLevel: Maybe<PublicationLevel>
  reruns: Array<LiveConcertRerun>
  seriesTitle: Maybe<TranslatedString>
  shortDescription: Maybe<TranslatedString>
  startTime: Maybe<Scalars['DateTime']['output']>
  streamStartTime: Maybe<Scalars['DateTime']['output']>
  subtitle: Maybe<TranslatedString>
  title: Maybe<TranslatedString>
  totalDuration: Maybe<Scalars['Int']['output']>
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: Maybe<LiveConcertType>
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
  /** A connected vod-concert that was created by copying live-concert data */
  vodConcert: Maybe<VodConcert>
}

export type LiveConcertDraftArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertDraftGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertDraftPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertDraftPerformanceWorksArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type LiveConcertRerun = {
  __typename: 'LiveConcertRerun'
  endTime: Maybe<Scalars['DateTime']['output']>
  id: Maybe<Scalars['String']['output']>
  startTime: Maybe<Scalars['DateTime']['output']>
  streamStartTime: Maybe<Scalars['DateTime']['output']>
  title: Maybe<Scalars['String']['output']>
}

export type LiveConcertRerunInput = {
  endTime: InputMaybe<Scalars['DateTime']['input']>
  startTime: InputMaybe<Scalars['DateTime']['input']>
  streamStartTime: InputMaybe<Scalars['DateTime']['input']>
  /** Internal use only, not shown to the clients */
  title: InputMaybe<Scalars['String']['input']>
}

export type LiveConcertSettings = {
  __typename: 'LiveConcertSettings'
  cdnUrl: Maybe<Scalars['String']['output']>
}

export enum LiveConcertType {
  Live = 'LIVE',
  Premiere = 'PREMIERE',
}

export type LiveOrVodConcert = LiveConcert | VodConcert

export type LiveOrVodConcertConnection = {
  __typename: 'LiveOrVodConcertConnection'
  edges: Array<LiveOrVodConcertEdge>
  pageInfo: PageInfo
}

export type LiveOrVodConcertEdge = {
  __typename: 'LiveOrVodConcertEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: LiveOrVodConcert
  status: EdgePublicationStatus
}

export enum MaxResolution {
  Resolution_4K = 'RESOLUTION_4K',
  ResolutionHd = 'RESOLUTION_HD',
  ResolutionSd = 'RESOLUTION_SD',
}

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output']
}

export type PackageAlbumsInput = {
  forceRepackage: Scalars['Boolean']['input']
  maxConcurrency: Scalars['Int']['input']
  /** A list of regular UPCs. Atmos UPCs will be loaded from the DB */
  upcs: InputMaybe<Array<Scalars['String']['input']>>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PackageAlbumsPayload = {
  __typename: 'PackageAlbumsPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Scalars['Boolean']['output']>
}

export type PackageAlbumsStatusData = {
  __typename: 'PackageAlbumsStatusData'
  lastRunResult: Maybe<LastRunResult>
  status: Scalars['String']['output']
}

export type PackageAlbumsWithoutStreamInput = {
  count: Scalars['Int']['input']
  exclude: InputMaybe<Array<Scalars['String']['input']>>
  maxConcurrency: Scalars['Int']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PackageAlbumsWithoutStreamPayload = {
  __typename: 'PackageAlbumsWithoutStreamPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Scalars['Boolean']['output']>
}

export type PageInfo = {
  __typename: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>
}

export type ParentConcert = LiveConcert | VodConcert

export enum PartnerSubtype {
  ChamberMusicHall = 'CHAMBER_MUSIC_HALL',
  Club = 'CLUB',
  ConcertHall = 'CONCERT_HALL',
  OpenAir = 'OPEN_AIR',
  OperaHouse = 'OPERA_HOUSE',
  Theatre = 'THEATRE',
}

export enum PartnerType {
  Curator = 'CURATOR',
  Festival = 'FESTIVAL',
  ProductionCompany = 'PRODUCTION_COMPANY',
  RecordingCompany = 'RECORDING_COMPANY',
  Series = 'SERIES',
  Sponsor = 'SPONSOR',
  Venue = 'VENUE',
}

export type PerformanceWork = Node & {
  __typename: 'PerformanceWork'
  artists: Maybe<CoreArtistWithRoleConnection>
  cuePoints: Maybe<Array<CuePoint>>
  /** Duration of the work in seconds */
  duration: Scalars['Int']['output']
  editor: AdminUser
  groups: Maybe<CoreGroupConnection>
  hasUnpublishedChanges: Scalars['Boolean']['output']
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  mvIsrc: Maybe<Scalars['String']['output']>
  parentConcert: ParentConcert
  performanceIsrc: Scalars['String']['output']
  status: Status
  stream: Maybe<Stream>
  updatedAt: Scalars['NaiveDateTime']['output']
  work: CoreWork
}

export type PerformanceWorkArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
  role: InputMaybe<RoleType>
}

export type PerformanceWorkGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type PerformanceWorkConnection = {
  __typename: 'PerformanceWorkConnection'
  edges: Array<PerformanceWorkEdge>
  pageInfo: PageInfo
}

export type PerformanceWorkData = Node & {
  __typename: 'PerformanceWorkData'
  draft: Maybe<PerformanceWorkDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<PerformanceWork>
  status: PublicationStatus
}

export type PerformanceWorkDraft = {
  __typename: 'PerformanceWorkDraft'
  artists: Maybe<CoreArtistWithRoleConnection>
  cuePoints: Maybe<Array<CuePoint>>
  /** Duration of the work in seconds */
  duration: Maybe<Scalars['Int']['output']>
  editor: AdminUser
  groups: Maybe<CoreGroupConnection>
  insertedAt: Scalars['NaiveDateTime']['output']
  mvIsrc: Maybe<Scalars['String']['output']>
  parentConcert: ParentConcert
  performanceIsrc: Maybe<Scalars['String']['output']>
  stream: Maybe<Stream>
  updatedAt: Scalars['NaiveDateTime']['output']
  work: Maybe<CoreWork>
}

export type PerformanceWorkDraftArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
  role: InputMaybe<RoleType>
}

export type PerformanceWorkDraftGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type PerformanceWorkEdge = {
  __typename: 'PerformanceWorkEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: PerformanceWork
  status: EdgePublicationStatus
}

export type Picture = Node & {
  __typename: 'Picture'
  /** The ID of an object */
  id: Scalars['ID']['output']
  type: PictureType
  url: Scalars['String']['output']
}

export type PictureConnection = {
  __typename: 'PictureConnection'
  edges: Array<PictureEdge>
  pageInfo: PageInfo
}

export type PictureEdge = {
  __typename: 'PictureEdge'
  cursor: Maybe<Scalars['String']['output']>
  /** The editor that last edited the relation, the node can have a different editor */
  editor: AdminUser
  node: Picture
  status: EdgePublicationStatus
}

export enum PictureType {
  Banner = 'BANNER',
  Cover = 'COVER',
  ImmersiveEnvironmentImage = 'IMMERSIVE_ENVIRONMENT_IMAGE',
  Logo = 'LOGO',
  Teaser = 'TEASER',
  TeaserSquare = 'TEASER_SQUARE',
}

export type PictureWithEditorConnection = {
  __typename: 'PictureWithEditorConnection'
  edges: Array<PictureWithEditorEdge>
  pageInfo: PageInfo
}

export type PictureWithEditorEdge = {
  __typename: 'PictureWithEditorEdge'
  cursor: Scalars['String']['output']
  editor: AdminUser
  node: Picture
}

export type Playout = {
  __typename: 'Playout'
  /** Id of the MediaLive-Channel this playout controls */
  channelId: Scalars['NonEmptyString']['output']
  /** The timecode playback should start as offset of the beginning of the input file. Format: 'HH:MM:SS:FF' */
  clipTime: Scalars['TimeWithFrames']['output']
  /** Human readable info for this playout, only for extra info */
  description: Scalars['String']['output']
  /** The time the playout should end in UTC */
  endTime: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  /** Should the channel schedule be set? Channel starts with welcome slate, then PGM playout scheduled, then ThankYou-Slate is displayed. Requires certain naming structure in the input attachments. Set this to fales if you want to set the channel schedule manually. */
  setChannelSchedule: Scalars['Boolean']['output']
  /** The time the playout should start in UTC */
  startTime: Scalars['DateTime']['output']
  /** The state of the playout */
  state: PlayoutState
}

export type PlayoutChannel = {
  __typename: 'PlayoutChannel'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type PlayoutChannelData = {
  __typename: 'PlayoutChannelData'
  channels: Array<PlayoutChannel>
}

export type PlayoutData = {
  __typename: 'PlayoutData'
  playouts: Array<Playout>
}

export enum PlayoutState {
  /** Playout has been cancelled. Both jobs are cancelled */
  Cancelled = 'CANCELLED',
  /** Playout is complete. Start- and Stop-Job have run successfully */
  Completed = 'COMPLETED',
  /** There was some error in executing the playout. */
  Error = 'ERROR',
  /** Playout is not yet scheduled. */
  Initial = 'INITIAL',
  /** Playout is currently running. Start-Job has completed, stop-job is scheduled. */
  Running = 'RUNNING',
  /** Playout is scheduled for the future, neither start- nor stop-job have run. */
  Scheduled = 'SCHEDULED',
}

export type PostalAddress = {
  __typename: 'PostalAddress'
  city: Maybe<Scalars['String']['output']>
  country: Maybe<Scalars['String']['output']>
  district: Maybe<Scalars['String']['output']>
  firstLine: Maybe<Scalars['String']['output']>
  province: Maybe<Scalars['String']['output']>
  secondLine: Maybe<Scalars['String']['output']>
  state: Maybe<Scalars['String']['output']>
  zipCode: Maybe<Scalars['String']['output']>
}

export type PublicationError = ApiError & {
  __typename: 'PublicationError'
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
  reason: Scalars['String']['output']
}

export enum PublicationLevel {
  AccountRequired = 'ACCOUNT_REQUIRED',
  NoAuthentication = 'NO_AUTHENTICATION',
  TicketRequired = 'TICKET_REQUIRED',
}

export enum PublicationStatus {
  /** Entity has just been deleted. Only returned as result of delete entity mutation, not persisted. */
  Deleted = 'DELETED',
  /** Entity is published and has no draft. */
  Published = 'PUBLISHED',
  /** Entity has been published before and has a draft. */
  PublishedWithDraft = 'PUBLISHED_WITH_DRAFT',
  /** Entity has never been published */
  Unpublished = 'UNPUBLISHED',
}

export type PublishAlbumInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishAlbumPayload = {
  __typename: 'PublishAlbumPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Album>
}

export type PublishBannerInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishBannerPayload = {
  __typename: 'PublishBannerPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<BannerData>
}

export type PublishCoreArtistInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCoreArtistPayload = {
  __typename: 'PublishCoreArtistPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreArtistData>
}

export type PublishCoreEpochInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCoreEpochPayload = {
  __typename: 'PublishCoreEpochPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreEpochData>
}

export type PublishCoreGenreInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCoreGenrePayload = {
  __typename: 'PublishCoreGenrePayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGenreData>
}

export type PublishCoreGroupInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCoreGroupPayload = {
  __typename: 'PublishCoreGroupPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGroupData>
}

export type PublishCorePartnerInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCorePartnerPayload = {
  __typename: 'PublishCorePartnerPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CorePartnerData>
}

export type PublishCoreWorkInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCoreWorkPayload = {
  __typename: 'PublishCoreWorkPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreWorkData>
}

export type PublishCuratedContentInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCuratedContentPayload = {
  __typename: 'PublishCuratedContentPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedContentData>
}

export type PublishCuratedSliderInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishCuratedSliderPayload = {
  __typename: 'PublishCuratedSliderPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedSliderData>
}

export type PublishLiveConcertInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishLiveConcertPayload = {
  __typename: 'PublishLiveConcertPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertData>
}

export type PublishPerformanceWorkInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishPerformanceWorkPayload = {
  __typename: 'PublishPerformanceWorkPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PerformanceWorkData>
}

export type PublishStageInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishStagePayload = {
  __typename: 'PublishStagePayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<StageData>
}

export type PublishVideoInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishVideoPayload = {
  __typename: 'PublishVideoPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VideoData>
}

export type PublishVodConcertInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type PublishVodConcertPayload = {
  __typename: 'PublishVodConcertPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VodConcertData>
}

export type R2Work = Node & {
  __typename: 'R2Work'
  artists: Maybe<CoreArtistConnection>
  children: Array<R2Work>
  epoch: Maybe<CoreEpoch>
  genre: Maybe<CoreGenre>
  /** The ID of an object */
  id: Scalars['ID']['output']
  isOriginalVersion: Maybe<Scalars['Boolean']['output']>
  isVersion: Maybe<Scalars['Boolean']['output']>
  r2Id: Maybe<Scalars['Int']['output']>
  r2ParentId: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
  treeLevel: Maybe<Scalars['Int']['output']>
  versionType: Maybe<Scalars['String']['output']>
}

export type R2WorkArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export enum RecordingSetting {
  Live = 'LIVE',
  Studio = 'STUDIO',
  Unknown = 'UNKNOWN',
}

export type ReindexSearchInput = {
  contentTypes: SearchIndexContentTypes
  index: SearchIndex
}

export type ReindexSearchPayload = {
  __typename: 'ReindexSearchPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** 'true' when the reindexing operation was successfully started */
  result: Maybe<Scalars['Boolean']['output']>
}

export enum RoleType {
  Arrangement = 'ARRANGEMENT',
  Author = 'AUTHOR',
  ChorusMaster = 'CHORUS_MASTER',
  Composer = 'COMPOSER',
  Conductor = 'CONDUCTOR',
  Director = 'DIRECTOR',
  Engineer = 'ENGINEER',
  InstrumentalSoloist = 'INSTRUMENTAL_SOLOIST',
  Mixing = 'MIXING',
  Orchestrator = 'ORCHESTRATOR',
  Other = 'OTHER',
  Production = 'PRODUCTION',
  VocalSoloist = 'VOCAL_SOLOIST',
}

export type RootMutationType = {
  __typename: 'RootMutationType'
  blockUser: Maybe<BlockUserPayload>
  copyLiveToVodConcert: Maybe<CopyLiveToVodConcertPayload>
  copyR2WorkToCoreWork: Maybe<CopyR2WorkToCoreWorkPayload>
  createOrUpdateBannerDraft: Maybe<CreateOrUpdateBannerDraftPayload>
  createOrUpdateCoreArtistDraft: Maybe<CreateOrUpdateCoreArtistDraftPayload>
  createOrUpdateCoreEpochDraft: Maybe<CreateOrUpdateCoreEpochDraftPayload>
  createOrUpdateCoreGenreDraft: Maybe<CreateOrUpdateCoreGenreDraftPayload>
  createOrUpdateCoreGroupDraft: Maybe<CreateOrUpdateCoreGroupDraftPayload>
  createOrUpdateCorePartnerDraft: Maybe<CreateOrUpdateCorePartnerDraftPayload>
  createOrUpdateCoreWorkDraft: Maybe<CreateOrUpdateCoreWorkDraftPayload>
  createOrUpdateCuratedContentDraft: Maybe<CreateOrUpdateCuratedContentDraftPayload>
  createOrUpdateCuratedSliderDraft: Maybe<CreateOrUpdateCuratedSliderDraftPayload>
  createOrUpdateLiveConcertDraft: Maybe<CreateOrUpdateLiveConcertDraftPayload>
  createOrUpdatePerformanceWorkDraft: Maybe<CreateOrUpdatePerformanceWorkDraftPayload>
  createOrUpdateStageDraft: Maybe<CreateOrUpdateStageDraftPayload>
  createOrUpdateVideoDraft: Maybe<CreateOrUpdateVideoDraftPayload>
  createOrUpdateVodConcertDraft: Maybe<CreateOrUpdateVodConcertDraftPayload>
  createPicture: Maybe<CreatePicturePayload>
  deleteAlbum: Maybe<DeleteAlbumPayload>
  deleteBannerDraft: Maybe<DeleteBannerDraftPayload>
  deleteCoreArtistDraft: Maybe<DeleteCoreArtistDraftPayload>
  deleteCoreEpochDraft: Maybe<DeleteCoreEpochDraftPayload>
  deleteCoreGenreDraft: Maybe<DeleteCoreGenreDraftPayload>
  deleteCoreGroupDraft: Maybe<DeleteCoreGroupDraftPayload>
  deleteCorePartnerDraft: Maybe<DeleteCorePartnerDraftPayload>
  deleteCoreWorkDraft: Maybe<DeleteCoreWorkDraftPayload>
  deleteCuratedSliderDraft: Maybe<DeleteCuratedSliderDraftPayload>
  deleteFromSearchIndex: Maybe<DeleteFromSearchIndexPayload>
  deleteLiveConcertDraft: Maybe<DeleteLiveConcertDraftPayload>
  deletePerformanceWorkDraft: Maybe<DeletePerformanceWorkDraftPayload>
  deleteUser: Maybe<DeleteUserPayload>
  deleteUserAddress: Maybe<DeleteUserAddressPayload>
  deleteUserTicket: Maybe<DeleteUserTicketPayload>
  deleteVideoDraft: Maybe<DeleteVideoDraftPayload>
  deleteVodConcertDraft: Maybe<DeleteVodConcertDraftPayload>
  packageAlbums: Maybe<PackageAlbumsPayload>
  packageAlbumsWithoutStream: Maybe<PackageAlbumsWithoutStreamPayload>
  publishAlbum: Maybe<PublishAlbumPayload>
  publishBanner: Maybe<PublishBannerPayload>
  publishCoreArtist: Maybe<PublishCoreArtistPayload>
  publishCoreEpoch: Maybe<PublishCoreEpochPayload>
  publishCoreGenre: Maybe<PublishCoreGenrePayload>
  publishCoreGroup: Maybe<PublishCoreGroupPayload>
  publishCorePartner: Maybe<PublishCorePartnerPayload>
  publishCoreWork: Maybe<PublishCoreWorkPayload>
  publishCuratedContent: Maybe<PublishCuratedContentPayload>
  publishCuratedSlider: Maybe<PublishCuratedSliderPayload>
  publishLiveConcert: Maybe<PublishLiveConcertPayload>
  publishPerformanceWork: Maybe<PublishPerformanceWorkPayload>
  publishStage: Maybe<PublishStagePayload>
  publishVideo: Maybe<PublishVideoPayload>
  publishVodConcert: Maybe<PublishVodConcertPayload>
  reindexSearch: Maybe<ReindexSearchPayload>
  setStripeCouponPaymentMethodUpfrontRequired: Maybe<SetStripeCouponPaymentMethodUpfrontRequiredPayload>
  /** @deprecated Use the Admin UI instead using of the API directly. */
  streamingAddPlayout: Maybe<StreamingAddPlayoutPayload>
  /** @deprecated Use the Admin UI instead using of the API directly. */
  streamingDeletePlayout: Maybe<StreamingDeletePlayoutPayload>
  streamingSetPlayouts: Maybe<StreamingSetPlayoutsPayload>
  transcodingSetGeoblocking: Maybe<TranscodingSetGeoblockingPayload>
  transcodingSetPublicationLevel: Maybe<TranscodingSetPublicationLevelPayload>
  transcodingStartJob: Maybe<TranscodingStartJobPayload>
  transcodingStartJobBatch: Maybe<TranscodingStartJobBatchPayload>
  unblockUser: Maybe<UnblockUserPayload>
  unpublishAlbum: Maybe<UnpublishAlbumPayload>
  unpublishBanner: Maybe<UnpublishBannerPayload>
  unpublishCoreArtist: Maybe<UnpublishCoreArtistPayload>
  unpublishCoreEpoch: Maybe<UnpublishCoreEpochPayload>
  unpublishCoreGenre: Maybe<UnpublishCoreGenrePayload>
  unpublishCoreGroup: Maybe<UnpublishCoreGroupPayload>
  unpublishCorePartner: Maybe<UnpublishCorePartnerPayload>
  unpublishCoreWork: Maybe<UnpublishCoreWorkPayload>
  unpublishCuratedSlider: Maybe<UnpublishCuratedSliderPayload>
  unpublishLiveConcert: Maybe<UnpublishLiveConcertPayload>
  unpublishPerformanceWork: Maybe<UnpublishPerformanceWorkPayload>
  /** Unpublishes all albums that are do not have a stream */
  unpublishStreamlessAlbums: Maybe<UnpublishStreamlessAlbumsPayload>
  unpublishVideo: Maybe<UnpublishVideoPayload>
  unpublishVodConcert: Maybe<UnpublishVodConcertPayload>
  updateLiveConcertSettings: Maybe<UpdateLiveConcertSettingsPayload>
}

export type RootMutationTypeBlockUserArgs = {
  input: InputMaybe<BlockUserInput>
}

export type RootMutationTypeCopyLiveToVodConcertArgs = {
  input: InputMaybe<CopyLiveToVodConcertInput>
}

export type RootMutationTypeCopyR2WorkToCoreWorkArgs = {
  input: InputMaybe<CopyR2WorkToCoreWorkInput>
}

export type RootMutationTypeCreateOrUpdateBannerDraftArgs = {
  input: InputMaybe<CreateOrUpdateBannerDraftInput>
}

export type RootMutationTypeCreateOrUpdateCoreArtistDraftArgs = {
  input: InputMaybe<CreateOrUpdateCoreArtistDraftInput>
}

export type RootMutationTypeCreateOrUpdateCoreEpochDraftArgs = {
  input: InputMaybe<CreateOrUpdateCoreEpochDraftInput>
}

export type RootMutationTypeCreateOrUpdateCoreGenreDraftArgs = {
  input: InputMaybe<CreateOrUpdateCoreGenreDraftInput>
}

export type RootMutationTypeCreateOrUpdateCoreGroupDraftArgs = {
  input: InputMaybe<CreateOrUpdateCoreGroupDraftInput>
}

export type RootMutationTypeCreateOrUpdateCorePartnerDraftArgs = {
  input: InputMaybe<CreateOrUpdateCorePartnerDraftInput>
}

export type RootMutationTypeCreateOrUpdateCoreWorkDraftArgs = {
  input: InputMaybe<CreateOrUpdateCoreWorkDraftInput>
}

export type RootMutationTypeCreateOrUpdateCuratedContentDraftArgs = {
  input: InputMaybe<CreateOrUpdateCuratedContentDraftInput>
}

export type RootMutationTypeCreateOrUpdateCuratedSliderDraftArgs = {
  input: InputMaybe<CreateOrUpdateCuratedSliderDraftInput>
}

export type RootMutationTypeCreateOrUpdateLiveConcertDraftArgs = {
  input: InputMaybe<CreateOrUpdateLiveConcertDraftInput>
}

export type RootMutationTypeCreateOrUpdatePerformanceWorkDraftArgs = {
  input: InputMaybe<CreateOrUpdatePerformanceWorkDraftInput>
}

export type RootMutationTypeCreateOrUpdateStageDraftArgs = {
  input: InputMaybe<CreateOrUpdateStageDraftInput>
}

export type RootMutationTypeCreateOrUpdateVideoDraftArgs = {
  input: InputMaybe<CreateOrUpdateVideoDraftInput>
}

export type RootMutationTypeCreateOrUpdateVodConcertDraftArgs = {
  input: InputMaybe<CreateOrUpdateVodConcertDraftInput>
}

export type RootMutationTypeCreatePictureArgs = {
  input: InputMaybe<CreatePictureInput>
}

export type RootMutationTypeDeleteAlbumArgs = {
  input: InputMaybe<DeleteAlbumInput>
}

export type RootMutationTypeDeleteBannerDraftArgs = {
  input: InputMaybe<DeleteBannerDraftInput>
}

export type RootMutationTypeDeleteCoreArtistDraftArgs = {
  input: InputMaybe<DeleteCoreArtistDraftInput>
}

export type RootMutationTypeDeleteCoreEpochDraftArgs = {
  input: InputMaybe<DeleteCoreEpochDraftInput>
}

export type RootMutationTypeDeleteCoreGenreDraftArgs = {
  input: InputMaybe<DeleteCoreGenreDraftInput>
}

export type RootMutationTypeDeleteCoreGroupDraftArgs = {
  input: InputMaybe<DeleteCoreGroupDraftInput>
}

export type RootMutationTypeDeleteCorePartnerDraftArgs = {
  input: InputMaybe<DeleteCorePartnerDraftInput>
}

export type RootMutationTypeDeleteCoreWorkDraftArgs = {
  input: InputMaybe<DeleteCoreWorkDraftInput>
}

export type RootMutationTypeDeleteCuratedSliderDraftArgs = {
  input: InputMaybe<DeleteCuratedSliderDraftInput>
}

export type RootMutationTypeDeleteFromSearchIndexArgs = {
  input: InputMaybe<DeleteFromSearchIndexInput>
}

export type RootMutationTypeDeleteLiveConcertDraftArgs = {
  input: InputMaybe<DeleteLiveConcertDraftInput>
}

export type RootMutationTypeDeletePerformanceWorkDraftArgs = {
  input: InputMaybe<DeletePerformanceWorkDraftInput>
}

export type RootMutationTypeDeleteUserArgs = {
  input: InputMaybe<DeleteUserInput>
}

export type RootMutationTypeDeleteUserAddressArgs = {
  input: InputMaybe<DeleteUserAddressInput>
}

export type RootMutationTypeDeleteUserTicketArgs = {
  input: InputMaybe<DeleteUserTicketInput>
}

export type RootMutationTypeDeleteVideoDraftArgs = {
  input: InputMaybe<DeleteVideoDraftInput>
}

export type RootMutationTypeDeleteVodConcertDraftArgs = {
  input: InputMaybe<DeleteVodConcertDraftInput>
}

export type RootMutationTypePackageAlbumsArgs = {
  input: InputMaybe<PackageAlbumsInput>
}

export type RootMutationTypePackageAlbumsWithoutStreamArgs = {
  input: InputMaybe<PackageAlbumsWithoutStreamInput>
}

export type RootMutationTypePublishAlbumArgs = {
  input: InputMaybe<PublishAlbumInput>
}

export type RootMutationTypePublishBannerArgs = {
  input: InputMaybe<PublishBannerInput>
}

export type RootMutationTypePublishCoreArtistArgs = {
  input: InputMaybe<PublishCoreArtistInput>
}

export type RootMutationTypePublishCoreEpochArgs = {
  input: InputMaybe<PublishCoreEpochInput>
}

export type RootMutationTypePublishCoreGenreArgs = {
  input: InputMaybe<PublishCoreGenreInput>
}

export type RootMutationTypePublishCoreGroupArgs = {
  input: InputMaybe<PublishCoreGroupInput>
}

export type RootMutationTypePublishCorePartnerArgs = {
  input: InputMaybe<PublishCorePartnerInput>
}

export type RootMutationTypePublishCoreWorkArgs = {
  input: InputMaybe<PublishCoreWorkInput>
}

export type RootMutationTypePublishCuratedContentArgs = {
  input: InputMaybe<PublishCuratedContentInput>
}

export type RootMutationTypePublishCuratedSliderArgs = {
  input: InputMaybe<PublishCuratedSliderInput>
}

export type RootMutationTypePublishLiveConcertArgs = {
  input: InputMaybe<PublishLiveConcertInput>
}

export type RootMutationTypePublishPerformanceWorkArgs = {
  input: InputMaybe<PublishPerformanceWorkInput>
}

export type RootMutationTypePublishStageArgs = {
  input: InputMaybe<PublishStageInput>
}

export type RootMutationTypePublishVideoArgs = {
  input: InputMaybe<PublishVideoInput>
}

export type RootMutationTypePublishVodConcertArgs = {
  input: InputMaybe<PublishVodConcertInput>
}

export type RootMutationTypeReindexSearchArgs = {
  input: InputMaybe<ReindexSearchInput>
}

export type RootMutationTypeSetStripeCouponPaymentMethodUpfrontRequiredArgs = {
  input: InputMaybe<SetStripeCouponPaymentMethodUpfrontRequiredInput>
}

export type RootMutationTypeStreamingAddPlayoutArgs = {
  input: InputMaybe<StreamingAddPlayoutInput>
}

export type RootMutationTypeStreamingDeletePlayoutArgs = {
  input: InputMaybe<StreamingDeletePlayoutInput>
}

export type RootMutationTypeStreamingSetPlayoutsArgs = {
  input: InputMaybe<StreamingSetPlayoutsInput>
}

export type RootMutationTypeTranscodingSetGeoblockingArgs = {
  input: InputMaybe<TranscodingSetGeoblockingInput>
}

export type RootMutationTypeTranscodingSetPublicationLevelArgs = {
  input: InputMaybe<TranscodingSetPublicationLevelInput>
}

export type RootMutationTypeTranscodingStartJobArgs = {
  input: InputMaybe<TranscodingStartJobInput>
}

export type RootMutationTypeTranscodingStartJobBatchArgs = {
  input: InputMaybe<TranscodingStartJobBatchInput>
}

export type RootMutationTypeUnblockUserArgs = {
  input: InputMaybe<UnblockUserInput>
}

export type RootMutationTypeUnpublishAlbumArgs = {
  input: InputMaybe<UnpublishAlbumInput>
}

export type RootMutationTypeUnpublishBannerArgs = {
  input: InputMaybe<UnpublishBannerInput>
}

export type RootMutationTypeUnpublishCoreArtistArgs = {
  input: InputMaybe<UnpublishCoreArtistInput>
}

export type RootMutationTypeUnpublishCoreEpochArgs = {
  input: InputMaybe<UnpublishCoreEpochInput>
}

export type RootMutationTypeUnpublishCoreGenreArgs = {
  input: InputMaybe<UnpublishCoreGenreInput>
}

export type RootMutationTypeUnpublishCoreGroupArgs = {
  input: InputMaybe<UnpublishCoreGroupInput>
}

export type RootMutationTypeUnpublishCorePartnerArgs = {
  input: InputMaybe<UnpublishCorePartnerInput>
}

export type RootMutationTypeUnpublishCoreWorkArgs = {
  input: InputMaybe<UnpublishCoreWorkInput>
}

export type RootMutationTypeUnpublishCuratedSliderArgs = {
  input: InputMaybe<UnpublishCuratedSliderInput>
}

export type RootMutationTypeUnpublishLiveConcertArgs = {
  input: InputMaybe<UnpublishLiveConcertInput>
}

export type RootMutationTypeUnpublishPerformanceWorkArgs = {
  input: InputMaybe<UnpublishPerformanceWorkInput>
}

export type RootMutationTypeUnpublishVideoArgs = {
  input: InputMaybe<UnpublishVideoInput>
}

export type RootMutationTypeUnpublishVodConcertArgs = {
  input: InputMaybe<UnpublishVodConcertInput>
}

export type RootMutationTypeUpdateLiveConcertSettingsArgs = {
  input: InputMaybe<UpdateLiveConcertSettingsInput>
}

export type RootQueryType = {
  __typename: 'RootQueryType'
  album: Maybe<Album>
  banner: Maybe<BannerData>
  coreArtist: Maybe<CoreArtistData>
  coreEpoch: Maybe<CoreEpochData>
  coreEpochs: Maybe<CoreEpochDataConnection>
  coreGenre: Maybe<CoreGenreData>
  coreGenres: Maybe<CoreGenreDataConnection>
  coreGroup: Maybe<CoreGroupData>
  corePartner: Maybe<CorePartnerData>
  coreRole: Maybe<CoreRole>
  coreRoles: Maybe<CoreRoleListConnection>
  coreWork: Maybe<CoreWorkData>
  /** Returns a list of countries that might be used in geoinformation or user-info */
  countries: Array<Country>
  curatedContent: CuratedContentData
  curatedContents: Maybe<CuratedContentDataConnection>
  curatedSlider: Maybe<CuratedSliderData>
  importerStatus: Maybe<ImporterStatus>
  liveConcert: Maybe<LiveConcertData>
  liveConcertSettings: Maybe<LiveConcertSettings>
  packageAlbumsStatus: Maybe<PackageAlbumsStatusData>
  performanceWork: Maybe<PerformanceWorkData>
  stage: Maybe<StageData>
  stages: Maybe<StageDataConnection>
  streamingChannels: Maybe<PlayoutChannelData>
  streamingScheduledPlayouts: Maybe<PlayoutData>
  transcodingGetGeoBlocking: Maybe<GeoBlocking>
  transcodingGetJobStatus: Maybe<JobStatus>
  transcodingGetJobStatusBatch: Array<JobStatus>
  transcodingGetPublicationLevel: Maybe<PublicationLevel>
  transcodingGetSourceFiles: Maybe<SourceFiles>
  transcodingGetSourceFilesBatch: Maybe<Array<TranscodingGetSourceFilesBatchPayload>>
  user: Maybe<User>
  video: Maybe<VideoData>
  vodConcert: Maybe<VodConcertData>
}

export type RootQueryTypeAlbumArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeBannerArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreArtistArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreEpochArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreEpochsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeCoreGenreArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreGenresArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeCoreGroupArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCorePartnerArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreRoleArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCoreRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeCoreWorkArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCuratedContentArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeCuratedContentsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeCuratedSliderArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeLiveConcertArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypePerformanceWorkArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeStageArgs = {
  slug: CuratedContentSlug
}

export type RootQueryTypeStagesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeTranscodingGetGeoBlockingArgs = {
  isrc: Scalars['String']['input']
}

export type RootQueryTypeTranscodingGetJobStatusArgs = {
  isrc: Scalars['String']['input']
}

export type RootQueryTypeTranscodingGetJobStatusBatchArgs = {
  isrcs: Array<Scalars['String']['input']>
}

export type RootQueryTypeTranscodingGetPublicationLevelArgs = {
  isrc: Scalars['String']['input']
}

export type RootQueryTypeTranscodingGetSourceFilesArgs = {
  isrc: Scalars['String']['input']
}

export type RootQueryTypeTranscodingGetSourceFilesBatchArgs = {
  isrcs: Array<Scalars['String']['input']>
}

export type RootQueryTypeUserArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeVideoArgs = {
  id: Scalars['ID']['input']
}

export type RootQueryTypeVodConcertArgs = {
  id: Scalars['ID']['input']
}

/** The Search Index that is supposed to be updated */
export enum SearchIndex {
  /** The Search Index used for searching in the Admin-UI */
  Admin = 'ADMIN',
  /** The Search Index used for searching on the clients */
  Audience = 'AUDIENCE',
}

/** The Content Types that are supposed to be reindexed. Either all or a specific one. With or without clearing the index. */
export enum SearchIndexContentTypes {
  /** Reindex Albums */
  Albums = 'ALBUMS',
  /** Reindex all content types. This does not clear the index and does not apply index settings. */
  All = 'ALL',
  /** Reindex Artists */
  Artists = 'ARTISTS',
  /** Reindex Banners */
  Banners = 'BANNERS',
  /** Reindex Curated Sliders */
  CuratedSliders = 'CURATED_SLIDERS',
  /** Reindex Epochs */
  Epochs = 'EPOCHS',
  /** Reindex Genres */
  Genres = 'GENRES',
  /** Reindex Groups */
  Groups = 'GROUPS',
  /** Reindex Live Concerts */
  LiveConcerts = 'LIVE_CONCERTS',
  /** Reindex Partners */
  Partners = 'PARTNERS',
  /** Reindex R2 Works */
  R2Works = 'R2_WORKS',
  /** This clears the index, applies the configured settings and reindexes all content types. Use this to correct errors or to apply new settings. */
  Recreate = 'RECREATE',
  /** Reindex Roles */
  Roles = 'ROLES',
  /** Reindex Users */
  Users = 'USERS',
  /** Reindex Videos */
  Videos = 'VIDEOS',
  /** Reindex VOD Concerts */
  VodConcerts = 'VOD_CONCERTS',
  /** Reindex Core Works */
  Works = 'WORKS',
}

export type SetGeoblockingResult = {
  __typename: 'SetGeoblockingResult'
  message: Scalars['String']['output']
}

export type SetPublicationLevelResult = {
  __typename: 'SetPublicationLevelResult'
  message: Scalars['String']['output']
}

export type SetStripeCouponPaymentMethodUpfrontRequiredInput = {
  isRequired: Scalars['Boolean']['input']
  stripeCouponId: Scalars['String']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SetStripeCouponPaymentMethodUpfrontRequiredPayload = {
  __typename: 'SetStripeCouponPaymentMethodUpfrontRequiredPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Scalars['Boolean']['output']>
}

export type SourceFile = {
  __typename: 'SourceFile'
  fileName: Scalars['String']['output']
  size: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SourceFiles = {
  __typename: 'SourceFiles'
  isrc: Scalars['String']['output']
  sourceFiles: Array<SourceFile>
}

export type Stage = Node & {
  __typename: 'Stage'
  editor: AdminUser
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  items: Array<StageItem>
  slug: Maybe<CuratedContentSlug>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type StageData = Node & {
  __typename: 'StageData'
  draft: Maybe<StageDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<Stage>
  status: PublicationStatus
}

export type StageDataConnection = {
  __typename: 'StageDataConnection'
  edges: Array<StageDataEdge>
  pageInfo: PageInfo
}

export type StageDataEdge = {
  __typename: 'StageDataEdge'
  cursor: Scalars['String']['output']
  node: StageData
}

export type StageDraft = {
  __typename: 'StageDraft'
  editor: AdminUser
  insertedAt: Scalars['NaiveDateTime']['output']
  items: Array<StageItem>
  slug: Maybe<CuratedContentSlug>
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type StageItem = {
  __typename: 'StageItem'
  item:
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  label: Maybe<TranslatedString>
  subtitle: Maybe<TranslatedString>
  title: Maybe<TranslatedString>
}

export enum Status {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type Stream = Node & {
  __typename: 'Stream'
  /** The ID of an object */
  id: Scalars['ID']['output']
  /** Url of 3D-Stream for videos if exists. Always null for audio. */
  mvVideoUrl: Maybe<Scalars['String']['output']>
  publicationLevel: PublicationLevel
  streamType: StreamType
  url: Scalars['String']['output']
}

export enum StreamType {
  Audio = 'AUDIO',
  Video = 'VIDEO',
}

export type StreamingAddPlayoutInput = {
  /** Id of the MediaLive-Channel this playout controls */
  channelId: Scalars['NonEmptyString']['input']
  /** The timecode playback should start as offset of the beginning of the input file. Format: 'HH:MM:SS:FF' */
  clipTime: Scalars['TimeWithFrames']['input']
  /** Human readable info for this playout, only for extra info */
  description: Scalars['String']['input']
  /** The time the playout should end in UTC */
  endTime: Scalars['DateTime']['input']
  /** Should the channel schedule be set? Channel starts with welcome slate, then PGM playout scheduled, then ThankYou-Slate is displayed. Requires certain naming structure in the input attachments. Set this to fales if you want to set the channel schedule manually. */
  setChannelSchedule: Scalars['Boolean']['input']
  /** The time the playout should start in UTC. */
  startTime: Scalars['DateTime']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type StreamingAddPlayoutPayload = {
  __typename: 'StreamingAddPlayoutPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PlayoutData>
}

export type StreamingDeletePlayoutInput = {
  id: Scalars['NonEmptyString']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type StreamingDeletePlayoutPayload = {
  __typename: 'StreamingDeletePlayoutPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PlayoutData>
}

export type StreamingSetPlayoutInput = {
  /** Id of the MediaLive-Channel this playout controls */
  channelId: Scalars['NonEmptyString']['input']
  /** The timecode playback should start as offset of the beginning of the input file. Format: 'HH:MM:SS:FF' */
  clipTime: Scalars['TimeWithFrames']['input']
  /** Human readable info for this playout, only for extra info */
  description: Scalars['String']['input']
  /** The time the playout should end in UTC */
  endTime: Scalars['DateTime']['input']
  id: InputMaybe<Scalars['String']['input']>
  /** Should the channel schedule be set? Channel starts with welcome slate, then PGM playout scheduled, then ThankYou-Slate is displayed. Requires certain naming structure in the input attachments. Set this to fales if you want to set the channel schedule manually. */
  setChannelSchedule: Scalars['Boolean']['input']
  /** The time the playout should start in UTC. */
  startTime: Scalars['DateTime']['input']
}

export type StreamingSetPlayoutsInput = {
  playouts: Array<StreamingSetPlayoutInput>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type StreamingSetPlayoutsPayload = {
  __typename: 'StreamingSetPlayoutsPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PlayoutData>
}

export type Ticket = {
  __typename: 'Ticket'
  amazonOriginalReceiptId: Maybe<Scalars['String']['output']>
  amazonProductId: Maybe<Scalars['String']['output']>
  appleOriginalTransactionId: Maybe<Scalars['String']['output']>
  appleProductId: Maybe<Scalars['String']['output']>
  canceledAt: Maybe<Scalars['DateTime']['output']>
  deletedAt: Maybe<Scalars['DateTime']['output']>
  insertedAt: Maybe<Scalars['DateTime']['output']>
  interval: Maybe<Scalars['String']['output']>
  paymentMethodType: Maybe<Scalars['String']['output']>
  paymentProvider: Maybe<Scalars['String']['output']>
  renewedAt: Maybe<Scalars['DateTime']['output']>
  status: Maybe<Scalars['String']['output']>
  stripePriceId: Maybe<Scalars['String']['output']>
  stripeProductId: Maybe<Scalars['String']['output']>
  stripeSubscriptionId: Maybe<Scalars['String']['output']>
  ticketType: Maybe<Scalars['String']['output']>
  trialEndsAt: Maybe<Scalars['DateTime']['output']>
  validUntil: Maybe<Scalars['DateTime']['output']>
  validUntilCalculated: Maybe<Scalars['DateTime']['output']>
  voucherCode: Maybe<Scalars['String']['output']>
  voucherDuration: Maybe<Scalars['String']['output']>
  voucherDurationInMonths: Maybe<Scalars['Int']['output']>
  voucherId: Maybe<Scalars['String']['output']>
  voucherPercentageOff: Maybe<Scalars['Decimal']['output']>
}

export type Track = Node & {
  __typename: 'Track'
  album: Album
  artistAndGroupDisplayInfo: Maybe<TranslatedString>
  artistDisplayInfo: Maybe<TranslatedString>
  bitDepth: Maybe<Scalars['Int']['output']>
  composerDisplayInfo: Maybe<TranslatedString>
  conductorDisplayInfo: Maybe<TranslatedString>
  /** Duration of the track in seconds */
  duration: Scalars['Int']['output']
  engineerDisplayInfo: Maybe<TranslatedString>
  firstReleaseYear: Maybe<Scalars['Int']['output']>
  groupDisplayInfo: Maybe<TranslatedString>
  /** The ID of an object */
  id: Scalars['ID']['output']
  isrc: Maybe<Scalars['String']['output']>
  isrcSurround: Maybe<Scalars['String']['output']>
  producerDisplayInfo: Maybe<TranslatedString>
  recordingCountry: Maybe<TranslatedString>
  recordingLocation: Maybe<TranslatedString>
  /** ISO 8601 formatted date. May be year or year/month only. */
  recordingSessionEnd: Maybe<Scalars['String']['output']>
  /** ISO 8601 formatted date. May be year or year/month only. */
  recordingSessionStart: Maybe<Scalars['String']['output']>
  recordingSetting: Maybe<RecordingSetting>
  sampleRate: Maybe<Scalars['Float']['output']>
  stream: Maybe<Stream>
  subtitle: Maybe<TranslatedString>
  technicalFormat: Maybe<Scalars['String']['output']>
  title: TranslatedString
  trackNumber: Scalars['Int']['output']
}

export type TrackSet = Node & {
  __typename: 'TrackSet'
  composerDisplayInfo: Maybe<TranslatedString>
  groupDisplayInfo: Maybe<TranslatedString>
  /** The ID of an object */
  id: Scalars['ID']['output']
  subtitle: Maybe<TranslatedString>
  title: Maybe<TranslatedString>
  trackSetNumber: Scalars['Int']['output']
  tracks: Array<Track>
}

export type TranscodingGetSourceFilesBatchPayload = {
  __typename: 'TranscodingGetSourceFilesBatchPayload'
  isrc: Scalars['String']['output']
  sourceFiles: Array<SourceFile>
}

export type TranscodingSetGeoblockingInput = {
  countries: Array<Scalars['String']['input']>
  isrc: Scalars['String']['input']
  mode: Scalars['String']['input']
}

export type TranscodingSetGeoblockingPayload = {
  __typename: 'TranscodingSetGeoblockingPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The result of the set geo-blocking operation */
  result: Maybe<SetGeoblockingResult>
}

export type TranscodingSetPublicationLevelInput = {
  isrc: Scalars['String']['input']
  publicationLevel: PublicationLevel
}

export type TranscodingSetPublicationLevelPayload = {
  __typename: 'TranscodingSetPublicationLevelPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The result of the set publication-level operation */
  result: Maybe<SetPublicationLevelResult>
}

export type TranscodingStartJobBatchInput = {
  isrcs: Array<Scalars['String']['input']>
}

export type TranscodingStartJobBatchPayload = {
  __typename: 'TranscodingStartJobBatchPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The result of starting the transcoding job as a new job_status */
  result: Maybe<Array<JobStatus>>
}

export type TranscodingStartJobInput = {
  isrc: Scalars['String']['input']
}

export type TranscodingStartJobPayload = {
  __typename: 'TranscodingStartJobPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The result of starting the transcoding job */
  result: Maybe<JobStatus>
}

export type TranslatableString = {
  de: InputMaybe<Scalars['String']['input']>
  en: InputMaybe<Scalars['String']['input']>
  ja: InputMaybe<Scalars['String']['input']>
}

export type TranslatedString = {
  __typename: 'TranslatedString'
  de: Maybe<Scalars['String']['output']>
  en: Maybe<Scalars['String']['output']>
  ja: Maybe<Scalars['String']['output']>
}

export type UnblockUserInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnblockUserPayload = {
  __typename: 'UnblockUserPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<User>
}

/** Returned when the used isrc is already used in a conflicting entity. */
export type UniqueIsrcPublicationError = ApiError & {
  __typename: 'UniqueIsrcPublicationError'
  conflictingNodes: Array<ConflictingNode>
  isrc: Scalars['String']['output']
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
  reason: Scalars['String']['output']
}

export type UnpublishAlbumInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishAlbumPayload = {
  __typename: 'UnpublishAlbumPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Album>
}

export type UnpublishBannerInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishBannerPayload = {
  __typename: 'UnpublishBannerPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<BannerData>
}

export type UnpublishCoreArtistInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCoreArtistPayload = {
  __typename: 'UnpublishCoreArtistPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreArtistData>
}

export type UnpublishCoreEpochInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCoreEpochPayload = {
  __typename: 'UnpublishCoreEpochPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreEpochData>
}

export type UnpublishCoreGenreInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCoreGenrePayload = {
  __typename: 'UnpublishCoreGenrePayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGenreData>
}

export type UnpublishCoreGroupInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCoreGroupPayload = {
  __typename: 'UnpublishCoreGroupPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreGroupData>
}

export type UnpublishCorePartnerInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCorePartnerPayload = {
  __typename: 'UnpublishCorePartnerPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CorePartnerData>
}

export type UnpublishCoreWorkInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCoreWorkPayload = {
  __typename: 'UnpublishCoreWorkPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CoreWorkData>
}

export type UnpublishCuratedSliderInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishCuratedSliderPayload = {
  __typename: 'UnpublishCuratedSliderPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<CuratedSliderData>
}

export type UnpublishLiveConcertInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishLiveConcertPayload = {
  __typename: 'UnpublishLiveConcertPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertData>
}

export type UnpublishPerformanceWorkInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishPerformanceWorkPayload = {
  __typename: 'UnpublishPerformanceWorkPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<PerformanceWorkData>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishStreamlessAlbumsPayload = {
  __typename: 'UnpublishStreamlessAlbumsPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<Scalars['Int']['output']>
}

export type UnpublishVideoInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishVideoPayload = {
  __typename: 'UnpublishVideoPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VideoData>
}

export type UnpublishVodConcertInput = {
  id: Scalars['ID']['input']
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UnpublishVodConcertPayload = {
  __typename: 'UnpublishVodConcertPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<VodConcertData>
}

export type UpdateLiveConcertSettingsInput = {
  cdnUrl: InputMaybe<Scalars['String']['input']>
}

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateLiveConcertSettingsPayload = {
  __typename: 'UpdateLiveConcertSettingsPayload'
  /** A list of errors. May be null if mutation succeeded. */
  errors: Maybe<
    Array<AuthenticationError | BasicError | PublicationError | UniqueIsrcPublicationError | ValidationError>
  >
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result: Maybe<LiveConcertSettings>
}

export type User = Node & {
  __typename: 'User'
  accountConfirmed: Scalars['Boolean']['output']
  affiliateGroup: Maybe<Scalars['String']['output']>
  amazonCustomerId: Maybe<Scalars['String']['output']>
  appleAppAccountToken: Maybe<Scalars['String']['output']>
  country: Maybe<Scalars['String']['output']>
  customerGroup: Maybe<Scalars['String']['output']>
  dateRegistered: Maybe<Scalars['DateTime']['output']>
  devices: Maybe<Array<Maybe<Device>>>
  email: Scalars['String']['output']
  externalId: Scalars['String']['output']
  firstName: Scalars['String']['output']
  hasTrialedBefore: Maybe<Scalars['Boolean']['output']>
  /** The ID of an object */
  id: Scalars['ID']['output']
  inactiveTickets: Maybe<Array<Maybe<Ticket>>>
  lastAuthenticatedAt: Maybe<Scalars['DateTime']['output']>
  lastName: Scalars['String']['output']
  locale: Maybe<Scalars['String']['output']>
  /** Meta param that identifies a click conversion event https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc */
  metaFbc: Maybe<Scalars['String']['output']>
  /** Has user consented to Meta (Facebook) Tracking */
  metaTrackingAllowed: Maybe<Scalars['Boolean']['output']>
  /** Has user consented to Mixpanel Tracking */
  mixpanelTrackingAllowed: Maybe<Scalars['Boolean']['output']>
  postalAddress: Maybe<PostalAddress>
  registrationCountry: Maybe<Scalars['String']['output']>
  sendNewsletter: Maybe<Scalars['Boolean']['output']>
  status: UserStatus
  stripeCurrency: Maybe<Scalars['String']['output']>
  stripeCustomerId: Maybe<Scalars['String']['output']>
  ticket: Maybe<Ticket>
  tickets: Maybe<Array<Maybe<Ticket>>>
  title: Maybe<Scalars['String']['output']>
}

export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  /** Returned only as result of the delete user mutation. Deleted users are not persisted in DB. */
  Deleted = 'DELETED',
}

export type ValidationError = ApiError & {
  __typename: 'ValidationError'
  field: Scalars['String']['output']
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
  validation: Scalars['String']['output']
}

export type Video = Node & {
  __typename: 'Video'
  albums: Maybe<AlbumConnection>
  archiveReleaseDate: Maybe<Scalars['DateTime']['output']>
  artists: Maybe<CoreArtistWithRoleConnection>
  concerts: Maybe<LiveOrVodConcertConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  description: Maybe<TranslatedString>
  duration: Scalars['Int']['output']
  editor: AdminUser
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode
  groups: Maybe<CoreGroupConnection>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  production: Maybe<Scalars['String']['output']>
  productionDate: Maybe<Scalars['Date']['output']>
  /** The level that is needed for a user to access the video-stream. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel
  stream: Maybe<Stream>
  subtitle: Maybe<TranslatedString>
  takedownDate: Maybe<Scalars['DateTime']['output']>
  title: TranslatedString
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: VideoType
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
  work: Maybe<CoreWork>
}

export type VideoAlbumsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoConcertsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoData = Node & {
  __typename: 'VideoData'
  draft: Maybe<VideoDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<Video>
  status: PublicationStatus
}

export type VideoDraft = {
  __typename: 'VideoDraft'
  albums: Maybe<AlbumConnection>
  archiveReleaseDate: Maybe<Scalars['DateTime']['output']>
  artists: Maybe<CoreArtistWithRoleConnection>
  concerts: Maybe<LiveOrVodConcertConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  description: Maybe<TranslatedString>
  duration: Maybe<Scalars['Int']['output']>
  editor: AdminUser
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode
  groups: Maybe<CoreGroupConnection>
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  production: Maybe<Scalars['String']['output']>
  productionDate: Maybe<Scalars['Date']['output']>
  /** The level that is needed for a user to access the video-stream. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel
  stream: Maybe<Stream>
  subtitle: Maybe<TranslatedString>
  takedownDate: Maybe<Scalars['DateTime']['output']>
  title: Maybe<TranslatedString>
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: VideoType
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
  work: Maybe<CoreWork>
}

export type VideoDraftAlbumsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoDraftArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoDraftConcertsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoDraftGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoDraftPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VideoDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export enum VideoType {
  Documentary = 'DOCUMENTARY',
  Interview = 'INTERVIEW',
  Short = 'SHORT',
}

export type VodConcert = Node & {
  __typename: 'VodConcert'
  /** The concert was first aired on this date */
  airDate: Scalars['Date']['output']
  /** The concert is available from this date */
  archiveReleaseDate: Maybe<Scalars['DateTime']['output']>
  artists: Maybe<CoreArtistWithRoleConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  editor: AdminUser
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode
  groups: Maybe<CoreGroupConnection>
  /** The ID of an object */
  id: Scalars['ID']['output']
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isImmersive: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isMvVideo: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  /** A connected live-concert out of which this vod-concert was originally created */
  liveConcert: Maybe<LiveConcert>
  longFormConcertIntroduction: Maybe<TranslatedString>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  performanceWorks: Maybe<PerformanceWorkConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  production: Maybe<Scalars['String']['output']>
  /** Production was finalized on this date */
  productionDate: Maybe<Scalars['Date']['output']>
  /** The level that is needed for a user to access the stream-content. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel
  seriesTitle: Maybe<TranslatedString>
  shortDescription: Maybe<TranslatedString>
  subtitle: Maybe<TranslatedString>
  /** The concert is available until this date */
  takedownDate: Maybe<Scalars['DateTime']['output']>
  title: TranslatedString
  totalDuration: Scalars['Int']['output']
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: VodConcertType
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
}

export type VodConcertArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertPerformanceWorksArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertData = Node & {
  __typename: 'VodConcertData'
  draft: Maybe<VodConcertDraft>
  /** The ID of an object */
  id: Scalars['ID']['output']
  published: Maybe<VodConcert>
  status: PublicationStatus
}

export type VodConcertDraft = {
  __typename: 'VodConcertDraft'
  /** The concert was first aired on this date */
  airDate: Maybe<Scalars['Date']['output']>
  /** The concert is available from this date */
  archiveReleaseDate: Maybe<Scalars['DateTime']['output']>
  artists: Maybe<CoreArtistWithRoleConnection>
  contentContainers: Array<
    | Album
    | Banner
    | BannerData
    | CoreArtist
    | CoreArtistData
    | CoreEpoch
    | CoreEpochData
    | CoreGenre
    | CoreGenreData
    | CoreGroup
    | CoreGroupData
    | CoreMovement
    | CorePartner
    | CorePartnerData
    | CoreRole
    | CoreWork
    | CoreWorkData
    | CuratedContent
    | CuratedContentData
    | CuratedSlider
    | CuratedSliderData
    | Label
    | LiveConcert
    | LiveConcertData
    | PerformanceWork
    | PerformanceWorkData
    | Picture
    | R2Work
    | Stage
    | StageData
    | Stream
    | Track
    | TrackSet
    | User
    | Video
    | VideoData
    | VodConcert
    | VodConcertData
  >
  copyright: Maybe<Scalars['String']['output']>
  courtesyOf: Maybe<Scalars['String']['output']>
  editor: AdminUser
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode
  groups: Maybe<CoreGroupConnection>
  insertedAt: Scalars['NaiveDateTime']['output']
  isAtmos: Scalars['Boolean']['output']
  isHdr: Scalars['Boolean']['output']
  isImmersive: Scalars['Boolean']['output']
  isLossless: Scalars['Boolean']['output']
  isMvVideo: Scalars['Boolean']['output']
  isrc: Maybe<Scalars['String']['output']>
  licensor: Maybe<Scalars['String']['output']>
  /** A connected live-concert out of which this vod-concert was originally created */
  liveConcert: Maybe<LiveConcert>
  longFormConcertIntroduction: Maybe<TranslatedString>
  maxResolution: MaxResolution
  partners: Maybe<CorePartnerConnection>
  performanceWorks: Maybe<PerformanceWorkConnection>
  photographer: Maybe<Scalars['String']['output']>
  pictures: Maybe<PictureConnection>
  production: Maybe<Scalars['String']['output']>
  /** Production was finalized on this date */
  productionDate: Maybe<Scalars['Date']['output']>
  /** The level that is needed for a user to access this stream-content. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel
  seriesTitle: Maybe<TranslatedString>
  shortDescription: Maybe<TranslatedString>
  subtitle: Maybe<TranslatedString>
  /** The concert is available until this date */
  takedownDate: Maybe<Scalars['DateTime']['output']>
  title: Maybe<TranslatedString>
  totalDuration: Maybe<Scalars['Int']['output']>
  trailerIsrc: Maybe<Scalars['String']['output']>
  trailerStream: Maybe<Stream>
  type: Maybe<VodConcertType>
  updatedAt: Scalars['NaiveDateTime']['output']
  videoDirector: Maybe<Scalars['String']['output']>
}

export type VodConcertDraftArtistsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertDraftGroupsArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertDraftPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertDraftPerformanceWorksArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type VodConcertDraftPicturesArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export enum VodConcertType {
  Concert = 'CONCERT',
  Dance = 'DANCE',
  Opera = 'OPERA',
}

export type GetCountryListQueryVariables = Exact<{ [key: string]: never }>

export type GetCountryListQuery = { __typename: 'RootQueryType' } & {
  countries: Array<{ __typename: 'Country' } & Pick<Country, 'iso' | 'displayName'>>
}

export type GetAllRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllRolesQuery = { __typename: 'RootQueryType' } & {
  coreRoles: Maybe<
    { __typename: 'CoreRoleListConnection' } & {
      edges: Array<
        { __typename: 'CoreRoleListEdge' } & {
          node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
        }
      >
    }
  >
}

export type AlbumInfoFragment = { __typename: 'Album' } & Pick<
  Album,
  'id' | 'status' | 'upc' | 'atmosUpc' | 'totalDuration' | 'copyright' | 'releaseDate' | 'originalReleaseDate'
> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    artistAndGroupDisplayInfo: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>>
    pictures: Maybe<
      { __typename: 'PictureWithEditorConnection' } & {
        edges: Array<
          { __typename: 'PictureWithEditorEdge' } & {
            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
          }
        >
      }
    >
    trackSets: Array<
      { __typename: 'TrackSet' } & Pick<TrackSet, 'id'> & { tracks: Array<{ __typename: 'Track' } & Pick<Track, 'id'>> }
    >
  }

export type PublishAlbumMutationVariables = Exact<{
  input: PublishAlbumInput
}>

export type PublishAlbumMutation = { __typename: 'RootMutationType' } & {
  publishAlbum: Maybe<
    { __typename: 'PublishAlbumPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'Album' } & Pick<
          Album,
          'id' | 'status' | 'upc' | 'atmosUpc' | 'totalDuration' | 'copyright' | 'releaseDate' | 'originalReleaseDate'
        > & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
            artistAndGroupDisplayInfo: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>>
            pictures: Maybe<
              { __typename: 'PictureWithEditorConnection' } & {
                edges: Array<
                  { __typename: 'PictureWithEditorEdge' } & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
                >
              }
            >
            trackSets: Array<
              { __typename: 'TrackSet' } & Pick<TrackSet, 'id'> & {
                  tracks: Array<{ __typename: 'Track' } & Pick<Track, 'id'>>
                }
            >
          }
      >
    }
  >
}

export type UnpublishAlbumMutationVariables = Exact<{
  input: UnpublishAlbumInput
}>

export type UnpublishAlbumMutation = { __typename: 'RootMutationType' } & {
  unpublishAlbum: Maybe<
    { __typename: 'UnpublishAlbumPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'Album' } & Pick<
          Album,
          'id' | 'status' | 'upc' | 'atmosUpc' | 'totalDuration' | 'copyright' | 'releaseDate' | 'originalReleaseDate'
        > & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
            artistAndGroupDisplayInfo: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>>
            pictures: Maybe<
              { __typename: 'PictureWithEditorConnection' } & {
                edges: Array<
                  { __typename: 'PictureWithEditorEdge' } & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
                >
              }
            >
            trackSets: Array<
              { __typename: 'TrackSet' } & Pick<TrackSet, 'id'> & {
                  tracks: Array<{ __typename: 'Track' } & Pick<Track, 'id'>>
                }
            >
          }
      >
    }
  >
}

export type DeleteAlbumMutationVariables = Exact<{
  input: InputMaybe<DeleteAlbumInput>
}>

export type DeleteAlbumMutation = { __typename: 'RootMutationType' } & {
  deleteAlbum: Maybe<
    { __typename: 'DeleteAlbumPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'Album' } & Pick<
          Album,
          'id' | 'status' | 'upc' | 'atmosUpc' | 'totalDuration' | 'copyright' | 'releaseDate' | 'originalReleaseDate'
        > & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
            artistAndGroupDisplayInfo: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>>
            pictures: Maybe<
              { __typename: 'PictureWithEditorConnection' } & {
                edges: Array<
                  { __typename: 'PictureWithEditorEdge' } & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
                >
              }
            >
            trackSets: Array<
              { __typename: 'TrackSet' } & Pick<TrackSet, 'id'> & {
                  tracks: Array<{ __typename: 'Track' } & Pick<Track, 'id'>>
                }
            >
          }
      >
    }
  >
}

export type GetAlbumByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetAlbumByIdQuery = { __typename: 'RootQueryType' } & {
  album: Maybe<
    { __typename: 'Album' } & Pick<
      Album,
      'id' | 'status' | 'upc' | 'atmosUpc' | 'totalDuration' | 'copyright' | 'releaseDate' | 'originalReleaseDate'
    > & {
        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
        artistAndGroupDisplayInfo: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>>
        pictures: Maybe<
          { __typename: 'PictureWithEditorConnection' } & {
            edges: Array<
              { __typename: 'PictureWithEditorEdge' } & {
                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
              }
            >
          }
        >
        trackSets: Array<
          { __typename: 'TrackSet' } & Pick<TrackSet, 'id'> & {
              tracks: Array<{ __typename: 'Track' } & Pick<Track, 'id'>>
            }
        >
      }
  >
}

export type ReferencedPerformanceWorksFragment = { __typename: 'PerformanceWorkConnection' } & {
  edges: Array<
    { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
        node: { __typename: 'PerformanceWork' } & Pick<
          PerformanceWork,
          'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
        > & {
            stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
            cuePoints: Maybe<
              Array<
                { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
            >
            artists: Maybe<
              { __typename: 'CoreArtistWithRoleConnection' } & {
                edges: Array<
                  { __typename: 'CoreArtistWithRoleEdge' } & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
                >
              }
            >
            work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                composers: Maybe<
                  { __typename: 'CoreArtistConnection' } & {
                    edges: Array<
                      { __typename: 'CoreArtistEdge' } & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                    >
                  }
                >
              }
          }
      }
  >
}

export type ReferencedPerfomanceWorkFragment = { __typename: 'PerformanceWork' } & Pick<
  PerformanceWork,
  'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
> & {
    stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
    cuePoints: Maybe<
      Array<
        { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          }
      >
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & {
            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
          }
        >
      }
    >
    work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        composers: Maybe<
          { __typename: 'CoreArtistConnection' } & {
            edges: Array<
              { __typename: 'CoreArtistEdge' } & {
                node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              }
            >
          }
        >
      }
  }

export type GetLiveConcertByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetLiveConcertByIdQuery = { __typename: 'RootQueryType' } & {
  liveConcert: Maybe<
    { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'LiveConcertDraft' } & Pick<
            LiveConcertDraft,
            | 'copyright'
            | 'courtesyOf'
            | 'isrc'
            | 'trailerIsrc'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'publicationLevel'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'startTime'
            | 'endTime'
            | 'streamStartTime'
            | 'type'
            | 'totalDuration'
            | 'licensor'
            | 'photographer'
            | 'plannedForVod'
            | 'production'
            | 'updatedAt'
            | 'videoDirector'
          > & {
              vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              longFormConcertIntroduction: Maybe<
                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              performanceWorks: Maybe<
                { __typename: 'PerformanceWorkConnection' } & {
                  edges: Array<
                    { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                        node: { __typename: 'PerformanceWork' } & Pick<
                          PerformanceWork,
                          'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                        > & {
                            stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                            cuePoints: Maybe<
                              Array<
                                { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                              >
                            >
                            artists: Maybe<
                              { __typename: 'CoreArtistWithRoleConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistWithRoleEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                  }
                                >
                              }
                            >
                            work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                composers: Maybe<
                                  { __typename: 'CoreArtistConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                      }
                                    >
                                  }
                                >
                              }
                          }
                      }
                  >
                }
              >
              reruns: Array<
                { __typename: 'LiveConcertRerun' } & Pick<
                  LiveConcertRerun,
                  'title' | 'startTime' | 'streamStartTime' | 'endTime'
                >
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'LiveConcert' } & Pick<
            LiveConcert,
            | 'id'
            | 'copyright'
            | 'courtesyOf'
            | 'startTime'
            | 'endTime'
            | 'streamStartTime'
            | 'type'
            | 'totalDuration'
            | 'isrc'
            | 'trailerIsrc'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'publicationLevel'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'licensor'
            | 'photographer'
            | 'plannedForVod'
            | 'production'
            | 'videoDirector'
            | 'updatedAt'
          > & {
              vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              longFormConcertIntroduction: Maybe<
                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              performanceWorks: Maybe<
                { __typename: 'PerformanceWorkConnection' } & {
                  edges: Array<
                    { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                        node: { __typename: 'PerformanceWork' } & Pick<
                          PerformanceWork,
                          'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                        > & {
                            stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                            cuePoints: Maybe<
                              Array<
                                { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                              >
                            >
                            artists: Maybe<
                              { __typename: 'CoreArtistWithRoleConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistWithRoleEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                  }
                                >
                              }
                            >
                            work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                composers: Maybe<
                                  { __typename: 'CoreArtistConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                      }
                                    >
                                  }
                                >
                              }
                          }
                      }
                  >
                }
              >
              reruns: Array<
                { __typename: 'LiveConcertRerun' } & Pick<
                  LiveConcertRerun,
                  'title' | 'startTime' | 'streamStartTime' | 'endTime'
                >
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type LiveConcertInfoFragment = { __typename: 'LiveConcert' } & Pick<
  LiveConcert,
  | 'id'
  | 'copyright'
  | 'courtesyOf'
  | 'startTime'
  | 'endTime'
  | 'streamStartTime'
  | 'type'
  | 'totalDuration'
  | 'isrc'
  | 'trailerIsrc'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'publicationLevel'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'licensor'
  | 'photographer'
  | 'plannedForVod'
  | 'production'
  | 'videoDirector'
  | 'updatedAt'
> & {
    vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    longFormConcertIntroduction: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    performanceWorks: Maybe<
      { __typename: 'PerformanceWorkConnection' } & {
        edges: Array<
          { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
              node: { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        }
                    >
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & {
                          node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            }
                          role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                        }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      composers: Maybe<
                        { __typename: 'CoreArtistConnection' } & {
                          edges: Array<
                            { __typename: 'CoreArtistEdge' } & {
                              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                }
                            }
                          >
                        }
                      >
                    }
                }
            }
        >
      }
    >
    reruns: Array<
      { __typename: 'LiveConcertRerun' } & Pick<LiveConcertRerun, 'title' | 'startTime' | 'streamStartTime' | 'endTime'>
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type LiveConcertDraftInfoFragment = { __typename: 'LiveConcertDraft' } & Pick<
  LiveConcertDraft,
  | 'copyright'
  | 'courtesyOf'
  | 'isrc'
  | 'trailerIsrc'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'publicationLevel'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'startTime'
  | 'endTime'
  | 'streamStartTime'
  | 'type'
  | 'totalDuration'
  | 'licensor'
  | 'photographer'
  | 'plannedForVod'
  | 'production'
  | 'updatedAt'
  | 'videoDirector'
> & {
    vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    longFormConcertIntroduction: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    performanceWorks: Maybe<
      { __typename: 'PerformanceWorkConnection' } & {
        edges: Array<
          { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
              node: { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        }
                    >
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & {
                          node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            }
                          role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                        }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      composers: Maybe<
                        { __typename: 'CoreArtistConnection' } & {
                          edges: Array<
                            { __typename: 'CoreArtistEdge' } & {
                              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                }
                            }
                          >
                        }
                      >
                    }
                }
            }
        >
      }
    >
    reruns: Array<
      { __typename: 'LiveConcertRerun' } & Pick<LiveConcertRerun, 'title' | 'startTime' | 'streamStartTime' | 'endTime'>
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

type ContentContainerItems_Album_Fragment = { __typename: 'Album' } & Pick<Album, 'id'>

type ContentContainerItems_Banner_Fragment = { __typename: 'Banner' } & Pick<Banner, 'id'>

type ContentContainerItems_BannerData_Fragment = { __typename: 'BannerData' } & Pick<BannerData, 'id'>

type ContentContainerItems_CoreArtist_Fragment = { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>

type ContentContainerItems_CoreArtistData_Fragment = { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>

type ContentContainerItems_CoreEpoch_Fragment = { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>

type ContentContainerItems_CoreEpochData_Fragment = { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>

type ContentContainerItems_CoreGenre_Fragment = { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>

type ContentContainerItems_CoreGenreData_Fragment = { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>

type ContentContainerItems_CoreGroup_Fragment = { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>

type ContentContainerItems_CoreGroupData_Fragment = { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>

type ContentContainerItems_CoreMovement_Fragment = { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>

type ContentContainerItems_CorePartner_Fragment = { __typename: 'CorePartner' } & Pick<CorePartner, 'id'>

type ContentContainerItems_CorePartnerData_Fragment = { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>

type ContentContainerItems_CoreRole_Fragment = { __typename: 'CoreRole' } & Pick<CoreRole, 'id'>

type ContentContainerItems_CoreWork_Fragment = { __typename: 'CoreWork' } & Pick<CoreWork, 'id'>

type ContentContainerItems_CoreWorkData_Fragment = { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>

type ContentContainerItems_CuratedContent_Fragment = { __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>

type ContentContainerItems_CuratedContentData_Fragment = { __typename: 'CuratedContentData' } & Pick<
  CuratedContentData,
  'id'
>

type ContentContainerItems_CuratedSlider_Fragment = { __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>

type ContentContainerItems_CuratedSliderData_Fragment = { __typename: 'CuratedSliderData' } & Pick<
  CuratedSliderData,
  'id'
>

type ContentContainerItems_Label_Fragment = { __typename: 'Label' } & Pick<Label, 'id'>

type ContentContainerItems_LiveConcert_Fragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>

type ContentContainerItems_LiveConcertData_Fragment = { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>

type ContentContainerItems_PerformanceWork_Fragment = { __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>

type ContentContainerItems_PerformanceWorkData_Fragment = { __typename: 'PerformanceWorkData' } & Pick<
  PerformanceWorkData,
  'id'
>

type ContentContainerItems_Picture_Fragment = { __typename: 'Picture' } & Pick<Picture, 'id'>

type ContentContainerItems_R2Work_Fragment = { __typename: 'R2Work' } & Pick<R2Work, 'id'>

type ContentContainerItems_Stage_Fragment = { __typename: 'Stage' } & Pick<Stage, 'id'>

type ContentContainerItems_StageData_Fragment = { __typename: 'StageData' } & Pick<StageData, 'id'>

type ContentContainerItems_Stream_Fragment = { __typename: 'Stream' } & Pick<Stream, 'id'>

type ContentContainerItems_Track_Fragment = { __typename: 'Track' } & Pick<Track, 'id'>

type ContentContainerItems_TrackSet_Fragment = { __typename: 'TrackSet' } & Pick<TrackSet, 'id'>

type ContentContainerItems_User_Fragment = { __typename: 'User' } & Pick<User, 'id'>

type ContentContainerItems_Video_Fragment = { __typename: 'Video' } & Pick<Video, 'id'>

type ContentContainerItems_VideoData_Fragment = { __typename: 'VideoData' } & Pick<VideoData, 'id'>

type ContentContainerItems_VodConcert_Fragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'id'>

type ContentContainerItems_VodConcertData_Fragment = { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>

export type ContentContainerItemsFragment =
  | ContentContainerItems_Album_Fragment
  | ContentContainerItems_Banner_Fragment
  | ContentContainerItems_BannerData_Fragment
  | ContentContainerItems_CoreArtist_Fragment
  | ContentContainerItems_CoreArtistData_Fragment
  | ContentContainerItems_CoreEpoch_Fragment
  | ContentContainerItems_CoreEpochData_Fragment
  | ContentContainerItems_CoreGenre_Fragment
  | ContentContainerItems_CoreGenreData_Fragment
  | ContentContainerItems_CoreGroup_Fragment
  | ContentContainerItems_CoreGroupData_Fragment
  | ContentContainerItems_CoreMovement_Fragment
  | ContentContainerItems_CorePartner_Fragment
  | ContentContainerItems_CorePartnerData_Fragment
  | ContentContainerItems_CoreRole_Fragment
  | ContentContainerItems_CoreWork_Fragment
  | ContentContainerItems_CoreWorkData_Fragment
  | ContentContainerItems_CuratedContent_Fragment
  | ContentContainerItems_CuratedContentData_Fragment
  | ContentContainerItems_CuratedSlider_Fragment
  | ContentContainerItems_CuratedSliderData_Fragment
  | ContentContainerItems_Label_Fragment
  | ContentContainerItems_LiveConcert_Fragment
  | ContentContainerItems_LiveConcertData_Fragment
  | ContentContainerItems_PerformanceWork_Fragment
  | ContentContainerItems_PerformanceWorkData_Fragment
  | ContentContainerItems_Picture_Fragment
  | ContentContainerItems_R2Work_Fragment
  | ContentContainerItems_Stage_Fragment
  | ContentContainerItems_StageData_Fragment
  | ContentContainerItems_Stream_Fragment
  | ContentContainerItems_Track_Fragment
  | ContentContainerItems_TrackSet_Fragment
  | ContentContainerItems_User_Fragment
  | ContentContainerItems_Video_Fragment
  | ContentContainerItems_VideoData_Fragment
  | ContentContainerItems_VodConcert_Fragment
  | ContentContainerItems_VodConcertData_Fragment

export type LiveConcertDataFragment = { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'LiveConcertDraft' } & Pick<
        LiveConcertDraft,
        | 'copyright'
        | 'courtesyOf'
        | 'isrc'
        | 'trailerIsrc'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'publicationLevel'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'startTime'
        | 'endTime'
        | 'streamStartTime'
        | 'type'
        | 'totalDuration'
        | 'licensor'
        | 'photographer'
        | 'plannedForVod'
        | 'production'
        | 'updatedAt'
        | 'videoDirector'
      > & {
          vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          longFormConcertIntroduction: Maybe<
            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          performanceWorks: Maybe<
            { __typename: 'PerformanceWorkConnection' } & {
              edges: Array<
                { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                    node: { __typename: 'PerformanceWork' } & Pick<
                      PerformanceWork,
                      'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                    > & {
                        stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                        cuePoints: Maybe<
                          Array<
                            { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          >
                        >
                        artists: Maybe<
                          { __typename: 'CoreArtistWithRoleConnection' } & {
                            edges: Array<
                              { __typename: 'CoreArtistWithRoleEdge' } & {
                                node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                                role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                              }
                            >
                          }
                        >
                        work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            composers: Maybe<
                              { __typename: 'CoreArtistConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  }
                                >
                              }
                            >
                          }
                      }
                  }
              >
            }
          >
          reruns: Array<
            { __typename: 'LiveConcertRerun' } & Pick<
              LiveConcertRerun,
              'title' | 'startTime' | 'streamStartTime' | 'endTime'
            >
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'LiveConcert' } & Pick<
        LiveConcert,
        | 'id'
        | 'copyright'
        | 'courtesyOf'
        | 'startTime'
        | 'endTime'
        | 'streamStartTime'
        | 'type'
        | 'totalDuration'
        | 'isrc'
        | 'trailerIsrc'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'publicationLevel'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'licensor'
        | 'photographer'
        | 'plannedForVod'
        | 'production'
        | 'videoDirector'
        | 'updatedAt'
      > & {
          vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          longFormConcertIntroduction: Maybe<
            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          performanceWorks: Maybe<
            { __typename: 'PerformanceWorkConnection' } & {
              edges: Array<
                { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                    node: { __typename: 'PerformanceWork' } & Pick<
                      PerformanceWork,
                      'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                    > & {
                        stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                        cuePoints: Maybe<
                          Array<
                            { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          >
                        >
                        artists: Maybe<
                          { __typename: 'CoreArtistWithRoleConnection' } & {
                            edges: Array<
                              { __typename: 'CoreArtistWithRoleEdge' } & {
                                node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                                role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                              }
                            >
                          }
                        >
                        work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            composers: Maybe<
                              { __typename: 'CoreArtistConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  }
                                >
                              }
                            >
                          }
                      }
                  }
              >
            }
          >
          reruns: Array<
            { __typename: 'LiveConcertRerun' } & Pick<
              LiveConcertRerun,
              'title' | 'startTime' | 'streamStartTime' | 'endTime'
            >
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

export type RerunFragment = { __typename: 'LiveConcertRerun' } & Pick<
  LiveConcertRerun,
  'title' | 'startTime' | 'streamStartTime' | 'endTime'
>

export type ConnectedVodConcertInfoFragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'id'>

export type SaveLiveConcertDraftMutationVariables = Exact<{
  input: CreateOrUpdateLiveConcertDraftInput
}>

export type SaveLiveConcertDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateLiveConcertDraft: Maybe<
    { __typename: 'CreateOrUpdateLiveConcertDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'LiveConcertDraft' } & Pick<
                LiveConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'LiveConcert' } & Pick<
                LiveConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type PublishLiveConcertMutationVariables = Exact<{
  input: PublishLiveConcertInput
}>

export type PublishLiveConcertMutation = { __typename: 'RootMutationType' } & {
  publishLiveConcert: Maybe<
    { __typename: 'PublishLiveConcertPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'LiveConcertDraft' } & Pick<
                LiveConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'LiveConcert' } & Pick<
                LiveConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishLiveConcertMutationVariables = Exact<{
  input: UnpublishLiveConcertInput
}>

export type UnpublishLiveConcertMutation = { __typename: 'RootMutationType' } & {
  unpublishLiveConcert: Maybe<
    { __typename: 'UnpublishLiveConcertPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'LiveConcertDraft' } & Pick<
                LiveConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'LiveConcert' } & Pick<
                LiveConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteLiveConcertDraftMutationVariables = Exact<{
  input: DeleteLiveConcertDraftInput
}>

export type DeleteLiveConcertDraftMutation = { __typename: 'RootMutationType' } & {
  deleteLiveConcertDraft: Maybe<
    { __typename: 'DeleteLiveConcertDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'LiveConcertDraft' } & Pick<
                LiveConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'LiveConcert' } & Pick<
                LiveConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type CopyLiveToVodConcertMutationVariables = Exact<{
  input: CopyLiveToVodConcertInput
}>

export type CopyLiveToVodConcertMutation = { __typename: 'RootMutationType' } & {
  copyLiveToVodConcert: Maybe<
    { __typename: 'CopyLiveToVodConcertPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'LiveConcertDraft' } & Pick<
                LiveConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'LiveConcert' } & Pick<
                LiveConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'startTime'
                | 'endTime'
                | 'streamStartTime'
                | 'type'
                | 'totalDuration'
                | 'isrc'
                | 'trailerIsrc'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'licensor'
                | 'photographer'
                | 'plannedForVod'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  vodConcert: Maybe<{ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  reruns: Array<
                    { __typename: 'LiveConcertRerun' } & Pick<
                      LiveConcertRerun,
                      'title' | 'startTime' | 'streamStartTime' | 'endTime'
                    >
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetPerformanceWorkByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPerformanceWorkByIdQuery = { __typename: 'RootQueryType' } & {
  performanceWork: Maybe<
    { __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'PerformanceWorkDraft' } & Pick<
            PerformanceWorkDraft,
            'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
          > & {
              stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              cuePoints: Maybe<
                Array<
                  { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                    }
                >
              >
              parentConcert:
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              work: Maybe<
                { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    movements: Array<
                      { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                        }
                    >
                  }
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
        published: Maybe<
          { __typename: 'PerformanceWork' } & Pick<
            PerformanceWork,
            'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
          > & {
              stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              cuePoints: Maybe<
                Array<
                  { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                    }
                >
              >
              parentConcert:
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                }
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type GetMovementsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetMovementsQuery = { __typename: 'RootQueryType' } & {
  coreWork: Maybe<
    { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'status'> & {
        published: Maybe<
          { __typename: 'CoreWork' } & {
            movements: Array<
              { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                }
            >
          }
        >
      }
  >
}

export type PerformanceWorkDraftInfoFragment = { __typename: 'PerformanceWorkDraft' } & Pick<
  PerformanceWorkDraft,
  'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
> & {
    stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    cuePoints: Maybe<
      Array<
        { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          }
      >
    >
    parentConcert:
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          })
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          })
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    work: Maybe<
      { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          movements: Array<
            { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              }
          >
        }
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type PerformanceWorkInfoFragment = { __typename: 'PerformanceWork' } & Pick<
  PerformanceWork,
  'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
> & {
    stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    cuePoints: Maybe<
      Array<
        { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          }
      >
    >
    parentConcert:
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          })
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          })
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        movements: Array<
          { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
            }
        >
      }
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type CuePointInfoFragment = { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
  }

export type PerformanceWorkDataFragment = { __typename: 'PerformanceWorkData' } & Pick<
  PerformanceWorkData,
  'id' | 'status'
> & {
    draft: Maybe<
      { __typename: 'PerformanceWorkDraft' } & Pick<
        PerformanceWorkDraft,
        'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
      > & {
          stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          cuePoints: Maybe<
            Array<
              { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            >
          >
          parentConcert:
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                })
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                })
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          work: Maybe<
            { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                movements: Array<
                  { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                      title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                    }
                >
              }
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
    published: Maybe<
      { __typename: 'PerformanceWork' } & Pick<
        PerformanceWork,
        'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
      > & {
          stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          cuePoints: Maybe<
            Array<
              { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            >
          >
          parentConcert:
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                })
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                })
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              movements: Array<
                { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  }
              >
            }
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

type ParentConcertInfo_LiveConcert_Fragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type ParentConcertInfo_VodConcert_Fragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

export type ParentConcertInfoFragment = ParentConcertInfo_LiveConcert_Fragment | ParentConcertInfo_VodConcert_Fragment

export type CoreWorkInfoFragment = { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    movements: Array<
      { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        }
    >
  }

export type SavePerformanceWorkDraftMutationVariables = Exact<{
  input: CreateOrUpdatePerformanceWorkDraftInput
}>

export type SavePerformanceWorkDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdatePerformanceWorkDraft: Maybe<
    { __typename: 'CreateOrUpdatePerformanceWorkDraftPayload' } & {
      result: Maybe<
        { __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'PerformanceWorkDraft' } & Pick<
                PerformanceWorkDraft,
                'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        movements: Array<
                          { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                              title: Maybe<
                                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              >
                            }
                        >
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
            published: Maybe<
              { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      movements: Array<
                        { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                            title: Maybe<
                              { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                            >
                          }
                      >
                    }
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type PublishPerformanceWorkMutationVariables = Exact<{
  input: PublishPerformanceWorkInput
}>

export type PublishPerformanceWorkMutation = { __typename: 'RootMutationType' } & {
  publishPerformanceWork: Maybe<
    { __typename: 'PublishPerformanceWorkPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'PerformanceWorkDraft' } & Pick<
                PerformanceWorkDraft,
                'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        movements: Array<
                          { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                              title: Maybe<
                                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              >
                            }
                        >
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
            published: Maybe<
              { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      movements: Array<
                        { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                            title: Maybe<
                              { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                            >
                          }
                      >
                    }
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishPerformanceWorkMutationVariables = Exact<{
  input: UnpublishPerformanceWorkInput
}>

export type UnpublishPerformanceWorkMutation = { __typename: 'RootMutationType' } & {
  unpublishPerformanceWork: Maybe<
    { __typename: 'UnpublishPerformanceWorkPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'PerformanceWorkDraft' } & Pick<
                PerformanceWorkDraft,
                'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        movements: Array<
                          { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                              title: Maybe<
                                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              >
                            }
                        >
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
            published: Maybe<
              { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      movements: Array<
                        { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                            title: Maybe<
                              { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                            >
                          }
                      >
                    }
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeletePerformanceWorkDraftMutationVariables = Exact<{
  input: DeletePerformanceWorkDraftInput
}>

export type DeletePerformanceWorkDraftMutation = { __typename: 'RootMutationType' } & {
  deletePerformanceWorkDraft: Maybe<
    { __typename: 'DeletePerformanceWorkDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'PerformanceWorkDraft' } & Pick<
                PerformanceWorkDraft,
                'duration' | 'performanceIsrc' | 'mvIsrc' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        movements: Array<
                          { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                              title: Maybe<
                                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              >
                            }
                        >
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
            published: Maybe<
              { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'duration' | 'performanceIsrc' | 'mvIsrc' | 'status' | 'hasUnpublishedChanges' | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id' | 'mark'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                        }
                    >
                  >
                  parentConcert:
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      movements: Array<
                        { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                            title: Maybe<
                              { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                            >
                          }
                      >
                    }
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetVideoByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetVideoByIdQuery = { __typename: 'RootQueryType' } & {
  video: Maybe<
    { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'VideoDraft' } & Pick<
            VideoDraft,
            | 'copyright'
            | 'courtesyOf'
            | 'type'
            | 'isrc'
            | 'trailerIsrc'
            | 'publicationLevel'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'archiveReleaseDate'
            | 'takedownDate'
            | 'productionDate'
            | 'duration'
            | 'licensor'
            | 'photographer'
            | 'production'
            | 'videoDirector'
            | 'updatedAt'
          > & {
              stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              concerts: Maybe<
                { __typename: 'LiveOrVodConcertConnection' } & {
                  edges: Array<
                    { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                        node:
                          | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              })
                          | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              })
                      }
                  >
                }
              >
              albums: Maybe<
                { __typename: 'AlbumConnection' } & {
                  edges: Array<
                    { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                        node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          }
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              work: Maybe<
                { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
            }
        >
        published: Maybe<
          { __typename: 'Video' } & Pick<
            Video,
            | 'id'
            | 'copyright'
            | 'courtesyOf'
            | 'type'
            | 'isrc'
            | 'trailerIsrc'
            | 'publicationLevel'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'archiveReleaseDate'
            | 'takedownDate'
            | 'productionDate'
            | 'duration'
            | 'licensor'
            | 'photographer'
            | 'production'
            | 'videoDirector'
            | 'updatedAt'
          > & {
              stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              concerts: Maybe<
                { __typename: 'LiveOrVodConcertConnection' } & {
                  edges: Array<
                    { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                        node:
                          | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              })
                          | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              })
                      }
                  >
                }
              >
              albums: Maybe<
                { __typename: 'AlbumConnection' } & {
                  edges: Array<
                    { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                        node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          }
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              work: Maybe<
                { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type VideoInfoFragment = { __typename: 'Video' } & Pick<
  Video,
  | 'id'
  | 'copyright'
  | 'courtesyOf'
  | 'type'
  | 'isrc'
  | 'trailerIsrc'
  | 'publicationLevel'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'archiveReleaseDate'
  | 'takedownDate'
  | 'productionDate'
  | 'duration'
  | 'licensor'
  | 'photographer'
  | 'production'
  | 'videoDirector'
  | 'updatedAt'
> & {
    stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    concerts: Maybe<
      { __typename: 'LiveOrVodConcertConnection' } & {
        edges: Array<
          { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
              node:
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
            }
        >
      }
    >
    albums: Maybe<
      { __typename: 'AlbumConnection' } & {
        edges: Array<
          { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
              node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    work: Maybe<
      { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type VideoDraftInfoFragment = { __typename: 'VideoDraft' } & Pick<
  VideoDraft,
  | 'copyright'
  | 'courtesyOf'
  | 'type'
  | 'isrc'
  | 'trailerIsrc'
  | 'publicationLevel'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'archiveReleaseDate'
  | 'takedownDate'
  | 'productionDate'
  | 'duration'
  | 'licensor'
  | 'photographer'
  | 'production'
  | 'videoDirector'
  | 'updatedAt'
> & {
    stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    concerts: Maybe<
      { __typename: 'LiveOrVodConcertConnection' } & {
        edges: Array<
          { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
              node:
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
            }
        >
      }
    >
    albums: Maybe<
      { __typename: 'AlbumConnection' } & {
        edges: Array<
          { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
              node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    work: Maybe<
      { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
  }

export type VideoDataFragment = { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'VideoDraft' } & Pick<
        VideoDraft,
        | 'copyright'
        | 'courtesyOf'
        | 'type'
        | 'isrc'
        | 'trailerIsrc'
        | 'publicationLevel'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'archiveReleaseDate'
        | 'takedownDate'
        | 'productionDate'
        | 'duration'
        | 'licensor'
        | 'photographer'
        | 'production'
        | 'videoDirector'
        | 'updatedAt'
      > & {
          stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          concerts: Maybe<
            { __typename: 'LiveOrVodConcertConnection' } & {
              edges: Array<
                { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                    node:
                      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          })
                      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          })
                  }
              >
            }
          >
          albums: Maybe<
            { __typename: 'AlbumConnection' } & {
              edges: Array<
                { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                    node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          work: Maybe<
            { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
        }
    >
    published: Maybe<
      { __typename: 'Video' } & Pick<
        Video,
        | 'id'
        | 'copyright'
        | 'courtesyOf'
        | 'type'
        | 'isrc'
        | 'trailerIsrc'
        | 'publicationLevel'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'archiveReleaseDate'
        | 'takedownDate'
        | 'productionDate'
        | 'duration'
        | 'licensor'
        | 'photographer'
        | 'production'
        | 'videoDirector'
        | 'updatedAt'
      > & {
          stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          concerts: Maybe<
            { __typename: 'LiveOrVodConcertConnection' } & {
              edges: Array<
                { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                    node:
                      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          })
                      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          })
                  }
              >
            }
          >
          albums: Maybe<
            { __typename: 'AlbumConnection' } & {
              edges: Array<
                { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                    node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          work: Maybe<
            { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

type VideoConcertInfo_LiveConcert_Fragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type VideoConcertInfo_VodConcert_Fragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

export type VideoConcertInfoFragment = VideoConcertInfo_LiveConcert_Fragment | VideoConcertInfo_VodConcert_Fragment

export type SaveVideoDraftMutationVariables = Exact<{
  input: CreateOrUpdateVideoDraftInput
}>

export type SaveVideoDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateVideoDraft: Maybe<
    { __typename: 'CreateOrUpdateVideoDraftPayload' } & {
      result: Maybe<
        { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VideoDraft' } & Pick<
                VideoDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Video' } & Pick<
                Video,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type PublishVideoMutationVariables = Exact<{
  input: PublishVideoInput
}>

export type PublishVideoMutation = { __typename: 'RootMutationType' } & {
  publishVideo: Maybe<
    { __typename: 'PublishVideoPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VideoDraft' } & Pick<
                VideoDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Video' } & Pick<
                Video,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishVideoMutationVariables = Exact<{
  input: UnpublishVideoInput
}>

export type UnpublishVideoMutation = { __typename: 'RootMutationType' } & {
  unpublishVideo: Maybe<
    { __typename: 'UnpublishVideoPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VideoDraft' } & Pick<
                VideoDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Video' } & Pick<
                Video,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteVideoDraftMutationVariables = Exact<{
  input: DeleteVideoDraftInput
}>

export type DeleteVideoDraftMutation = { __typename: 'RootMutationType' } & {
  deleteVideoDraft: Maybe<
    { __typename: 'DeleteVideoDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VideoData' } & Pick<VideoData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VideoDraft' } & Pick<
                VideoDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Video' } & Pick<
                Video,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'isrc'
                | 'trailerIsrc'
                | 'publicationLevel'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'productionDate'
                | 'duration'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'updatedAt'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  concerts: Maybe<
                    { __typename: 'LiveOrVodConcertConnection' } & {
                      edges: Array<
                        { __typename: 'LiveOrVodConcertEdge' } & Pick<LiveOrVodConcertEdge, 'status'> & {
                            node:
                              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  })
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  work: Maybe<
                    { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetVodConcertByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetVodConcertByIdQuery = { __typename: 'RootQueryType' } & {
  vodConcert: Maybe<
    { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'VodConcertDraft' } & Pick<
            VodConcertDraft,
            | 'copyright'
            | 'courtesyOf'
            | 'type'
            | 'totalDuration'
            | 'airDate'
            | 'productionDate'
            | 'archiveReleaseDate'
            | 'takedownDate'
            | 'isrc'
            | 'trailerIsrc'
            | 'licensor'
            | 'photographer'
            | 'production'
            | 'updatedAt'
            | 'videoDirector'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'isMvVideo'
            | 'isImmersive'
            | 'publicationLevel'
          > & {
              liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              longFormConcertIntroduction: Maybe<
                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              performanceWorks: Maybe<
                { __typename: 'PerformanceWorkConnection' } & {
                  edges: Array<
                    { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                        node: { __typename: 'PerformanceWork' } & Pick<
                          PerformanceWork,
                          'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                        > & {
                            stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                            cuePoints: Maybe<
                              Array<
                                { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                              >
                            >
                            artists: Maybe<
                              { __typename: 'CoreArtistWithRoleConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistWithRoleEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                  }
                                >
                              }
                            >
                            work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                composers: Maybe<
                                  { __typename: 'CoreArtistConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                      }
                                    >
                                  }
                                >
                              }
                          }
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'VodConcert' } & Pick<
            VodConcert,
            | 'id'
            | 'copyright'
            | 'courtesyOf'
            | 'airDate'
            | 'type'
            | 'isMvVideo'
            | 'isImmersive'
            | 'totalDuration'
            | 'productionDate'
            | 'archiveReleaseDate'
            | 'takedownDate'
            | 'isrc'
            | 'trailerIsrc'
            | 'licensor'
            | 'photographer'
            | 'production'
            | 'videoDirector'
            | 'geoAccessMode'
            | 'geoAccessCountries'
            | 'maxResolution'
            | 'isHdr'
            | 'isAtmos'
            | 'isLossless'
            | 'publicationLevel'
            | 'updatedAt'
          > & {
              liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
              trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              longFormConcertIntroduction: Maybe<
                { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              >
              groups: Maybe<
                { __typename: 'CoreGroupConnection' } & {
                  edges: Array<
                    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              partners: Maybe<
                { __typename: 'CorePartnerConnection' } & {
                  edges: Array<
                    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              artists: Maybe<
                { __typename: 'CoreArtistWithRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                      }
                  >
                }
              >
              performanceWorks: Maybe<
                { __typename: 'PerformanceWorkConnection' } & {
                  edges: Array<
                    { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                        node: { __typename: 'PerformanceWork' } & Pick<
                          PerformanceWork,
                          'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                        > & {
                            stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                            cuePoints: Maybe<
                              Array<
                                { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                              >
                            >
                            artists: Maybe<
                              { __typename: 'CoreArtistWithRoleConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistWithRoleEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                  }
                                >
                              }
                            >
                            work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                composers: Maybe<
                                  { __typename: 'CoreArtistConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                      }
                                    >
                                  }
                                >
                              }
                          }
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type VodConcertInfoFragment = { __typename: 'VodConcert' } & Pick<
  VodConcert,
  | 'id'
  | 'copyright'
  | 'courtesyOf'
  | 'airDate'
  | 'type'
  | 'isMvVideo'
  | 'isImmersive'
  | 'totalDuration'
  | 'productionDate'
  | 'archiveReleaseDate'
  | 'takedownDate'
  | 'isrc'
  | 'trailerIsrc'
  | 'licensor'
  | 'photographer'
  | 'production'
  | 'videoDirector'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'publicationLevel'
  | 'updatedAt'
> & {
    liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    longFormConcertIntroduction: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    performanceWorks: Maybe<
      { __typename: 'PerformanceWorkConnection' } & {
        edges: Array<
          { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
              node: { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        }
                    >
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & {
                          node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            }
                          role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                        }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      composers: Maybe<
                        { __typename: 'CoreArtistConnection' } & {
                          edges: Array<
                            { __typename: 'CoreArtistEdge' } & {
                              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                }
                            }
                          >
                        }
                      >
                    }
                }
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type VodConcertDraftInfoFragment = { __typename: 'VodConcertDraft' } & Pick<
  VodConcertDraft,
  | 'copyright'
  | 'courtesyOf'
  | 'type'
  | 'totalDuration'
  | 'airDate'
  | 'productionDate'
  | 'archiveReleaseDate'
  | 'takedownDate'
  | 'isrc'
  | 'trailerIsrc'
  | 'licensor'
  | 'photographer'
  | 'production'
  | 'updatedAt'
  | 'videoDirector'
  | 'geoAccessMode'
  | 'geoAccessCountries'
  | 'maxResolution'
  | 'isHdr'
  | 'isAtmos'
  | 'isLossless'
  | 'isMvVideo'
  | 'isImmersive'
  | 'publicationLevel'
> & {
    liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
    trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    longFormConcertIntroduction: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    groups: Maybe<
      { __typename: 'CoreGroupConnection' } & {
        edges: Array<
          { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
              node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    partners: Maybe<
      { __typename: 'CorePartnerConnection' } & {
        edges: Array<
          { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
              node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    artists: Maybe<
      { __typename: 'CoreArtistWithRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
              role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
            }
        >
      }
    >
    performanceWorks: Maybe<
      { __typename: 'PerformanceWorkConnection' } & {
        edges: Array<
          { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
              node: { __typename: 'PerformanceWork' } & Pick<
                PerformanceWork,
                'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
              > & {
                  stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                  cuePoints: Maybe<
                    Array<
                      { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        }
                    >
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & {
                          node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            }
                          role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                        }
                      >
                    }
                  >
                  work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      composers: Maybe<
                        { __typename: 'CoreArtistConnection' } & {
                          edges: Array<
                            { __typename: 'CoreArtistEdge' } & {
                              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                }
                            }
                          >
                        }
                      >
                    }
                }
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type ConnectedLiveConcertInfoFragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>

export type VodConcertDataFragment = { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'VodConcertDraft' } & Pick<
        VodConcertDraft,
        | 'copyright'
        | 'courtesyOf'
        | 'type'
        | 'totalDuration'
        | 'airDate'
        | 'productionDate'
        | 'archiveReleaseDate'
        | 'takedownDate'
        | 'isrc'
        | 'trailerIsrc'
        | 'licensor'
        | 'photographer'
        | 'production'
        | 'updatedAt'
        | 'videoDirector'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'isMvVideo'
        | 'isImmersive'
        | 'publicationLevel'
      > & {
          liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          longFormConcertIntroduction: Maybe<
            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          performanceWorks: Maybe<
            { __typename: 'PerformanceWorkConnection' } & {
              edges: Array<
                { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                    node: { __typename: 'PerformanceWork' } & Pick<
                      PerformanceWork,
                      'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                    > & {
                        stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                        cuePoints: Maybe<
                          Array<
                            { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          >
                        >
                        artists: Maybe<
                          { __typename: 'CoreArtistWithRoleConnection' } & {
                            edges: Array<
                              { __typename: 'CoreArtistWithRoleEdge' } & {
                                node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                                role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                              }
                            >
                          }
                        >
                        work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            composers: Maybe<
                              { __typename: 'CoreArtistConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  }
                                >
                              }
                            >
                          }
                      }
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'VodConcert' } & Pick<
        VodConcert,
        | 'id'
        | 'copyright'
        | 'courtesyOf'
        | 'airDate'
        | 'type'
        | 'isMvVideo'
        | 'isImmersive'
        | 'totalDuration'
        | 'productionDate'
        | 'archiveReleaseDate'
        | 'takedownDate'
        | 'isrc'
        | 'trailerIsrc'
        | 'licensor'
        | 'photographer'
        | 'production'
        | 'videoDirector'
        | 'geoAccessMode'
        | 'geoAccessCountries'
        | 'maxResolution'
        | 'isHdr'
        | 'isAtmos'
        | 'isLossless'
        | 'publicationLevel'
        | 'updatedAt'
      > & {
          liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
          trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          shortDescription: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          longFormConcertIntroduction: Maybe<
            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          >
          groups: Maybe<
            { __typename: 'CoreGroupConnection' } & {
              edges: Array<
                { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                    node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          partners: Maybe<
            { __typename: 'CorePartnerConnection' } & {
              edges: Array<
                { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                    node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          artists: Maybe<
            { __typename: 'CoreArtistWithRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                    role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                  }
              >
            }
          >
          performanceWorks: Maybe<
            { __typename: 'PerformanceWorkConnection' } & {
              edges: Array<
                { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                    node: { __typename: 'PerformanceWork' } & Pick<
                      PerformanceWork,
                      'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                    > & {
                        stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                        cuePoints: Maybe<
                          Array<
                            { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          >
                        >
                        artists: Maybe<
                          { __typename: 'CoreArtistWithRoleConnection' } & {
                            edges: Array<
                              { __typename: 'CoreArtistWithRoleEdge' } & {
                                node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                  }
                                role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                              }
                            >
                          }
                        >
                        work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            composers: Maybe<
                              { __typename: 'CoreArtistConnection' } & {
                                edges: Array<
                                  { __typename: 'CoreArtistEdge' } & {
                                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  }
                                >
                              }
                            >
                          }
                      }
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

export type SaveVodConcertDraftMutationVariables = Exact<{
  input: CreateOrUpdateVodConcertDraftInput
}>

export type SaveVodConcertDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateVodConcertDraft: Maybe<
    { __typename: 'CreateOrUpdateVodConcertDraftPayload' } & {
      result: Maybe<
        { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VodConcertDraft' } & Pick<
                VodConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'totalDuration'
                | 'airDate'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'isMvVideo'
                | 'isImmersive'
                | 'publicationLevel'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'VodConcert' } & Pick<
                VodConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'airDate'
                | 'type'
                | 'isMvVideo'
                | 'isImmersive'
                | 'totalDuration'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'updatedAt'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type PublishVodConcertMutationVariables = Exact<{
  input: PublishVodConcertInput
}>

export type PublishVodConcertMutation = { __typename: 'RootMutationType' } & {
  publishVodConcert: Maybe<
    { __typename: 'PublishVodConcertPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VodConcertDraft' } & Pick<
                VodConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'totalDuration'
                | 'airDate'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'isMvVideo'
                | 'isImmersive'
                | 'publicationLevel'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'VodConcert' } & Pick<
                VodConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'airDate'
                | 'type'
                | 'isMvVideo'
                | 'isImmersive'
                | 'totalDuration'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'updatedAt'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishVodConcertMutationVariables = Exact<{
  input: UnpublishVodConcertInput
}>

export type UnpublishVodConcertMutation = { __typename: 'RootMutationType' } & {
  unpublishVodConcert: Maybe<
    { __typename: 'UnpublishVodConcertPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VodConcertDraft' } & Pick<
                VodConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'totalDuration'
                | 'airDate'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'isMvVideo'
                | 'isImmersive'
                | 'publicationLevel'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'VodConcert' } & Pick<
                VodConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'airDate'
                | 'type'
                | 'isMvVideo'
                | 'isImmersive'
                | 'totalDuration'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'updatedAt'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteVodConcertDraftMutationVariables = Exact<{
  input: DeleteVodConcertDraftInput
}>

export type DeleteVodConcertDraftMutation = { __typename: 'RootMutationType' } & {
  deleteVodConcertDraft: Maybe<
    { __typename: 'DeleteVodConcertDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'VodConcertDraft' } & Pick<
                VodConcertDraft,
                | 'copyright'
                | 'courtesyOf'
                | 'type'
                | 'totalDuration'
                | 'airDate'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'updatedAt'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'isMvVideo'
                | 'isImmersive'
                | 'publicationLevel'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'VodConcert' } & Pick<
                VodConcert,
                | 'id'
                | 'copyright'
                | 'courtesyOf'
                | 'airDate'
                | 'type'
                | 'isMvVideo'
                | 'isImmersive'
                | 'totalDuration'
                | 'productionDate'
                | 'archiveReleaseDate'
                | 'takedownDate'
                | 'isrc'
                | 'trailerIsrc'
                | 'licensor'
                | 'photographer'
                | 'production'
                | 'videoDirector'
                | 'geoAccessMode'
                | 'geoAccessCountries'
                | 'maxResolution'
                | 'isHdr'
                | 'isAtmos'
                | 'isLossless'
                | 'publicationLevel'
                | 'updatedAt'
              > & {
                  liveConcert: Maybe<{ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>>
                  trailerStream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'url'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  seriesTitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  shortDescription: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  longFormConcertIntroduction: Maybe<
                    { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  >
                  groups: Maybe<
                    { __typename: 'CoreGroupConnection' } & {
                      edges: Array<
                        { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
                            node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  partners: Maybe<
                    { __typename: 'CorePartnerConnection' } & {
                      edges: Array<
                        { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
                            node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  artists: Maybe<
                    { __typename: 'CoreArtistWithRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                            role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                          }
                      >
                    }
                  >
                  performanceWorks: Maybe<
                    { __typename: 'PerformanceWorkConnection' } & {
                      edges: Array<
                        { __typename: 'PerformanceWorkEdge' } & Pick<PerformanceWorkEdge, 'status'> & {
                            node: { __typename: 'PerformanceWork' } & Pick<
                              PerformanceWork,
                              'id' | 'performanceIsrc' | 'hasUnpublishedChanges' | 'status'
                            > & {
                                stream: Maybe<{ __typename: 'Stream' } & Pick<Stream, 'id' | 'url'>>
                                cuePoints: Maybe<
                                  Array<
                                    { __typename: 'CuePoint' } & Pick<CuePoint, 'id'> & {
                                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                      }
                                  >
                                >
                                artists: Maybe<
                                  { __typename: 'CoreArtistWithRoleConnection' } & {
                                    edges: Array<
                                      { __typename: 'CoreArtistWithRoleEdge' } & {
                                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                          }
                                        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
                                      }
                                    >
                                  }
                                >
                                work: { __typename: 'CoreWork' } & Pick<CoreWork, 'id'> & {
                                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                    composers: Maybe<
                                      { __typename: 'CoreArtistConnection' } & {
                                        edges: Array<
                                          { __typename: 'CoreArtistEdge' } & {
                                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                              }
                                          }
                                        >
                                      }
                                    >
                                  }
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetArtistByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetArtistByIdQuery = { __typename: 'RootQueryType' } & {
  coreArtist: Maybe<
    { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CoreArtistDraft' } & Pick<
            CoreArtistDraft,
            'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
          > & {
              biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              epoch: Maybe<
                { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  }
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              roles: Maybe<
                { __typename: 'CoreRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                        node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CoreArtist' } & Pick<
            CoreArtist,
            'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
          > & {
              biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              epoch: Maybe<
                { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  }
              >
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              roles: Maybe<
                { __typename: 'CoreRoleConnection' } & {
                  edges: Array<
                    { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                        node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                      }
                  >
                }
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
      }
  >
}

export type ArtistInfoFragment = { __typename: 'CoreArtist' } & Pick<
  CoreArtist,
  'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
> & {
    biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    epoch: Maybe<
      { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
        }
    >
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    roles: Maybe<
      { __typename: 'CoreRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
              node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
            }
        >
      }
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type ArtistDraftInfoFragment = { __typename: 'CoreArtistDraft' } & Pick<
  CoreArtistDraft,
  'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
> & {
    biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    epoch: Maybe<
      { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
        }
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    roles: Maybe<
      { __typename: 'CoreRoleConnection' } & {
        edges: Array<
          { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
              node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CoreArtistDataFragment = { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CoreArtistDraft' } & Pick<
        CoreArtistDraft,
        'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
      > & {
          biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          epoch: Maybe<
            { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              }
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          roles: Maybe<
            { __typename: 'CoreRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                    node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CoreArtist' } & Pick<
        CoreArtist,
        'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
      > & {
          biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          epoch: Maybe<
            { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              }
          >
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          roles: Maybe<
            { __typename: 'CoreRoleConnection' } & {
              edges: Array<
                { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                    node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                  }
              >
            }
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
  }

export type SaveArtistDraftMutationVariables = Exact<{
  input: CreateOrUpdateCoreArtistDraftInput
}>

export type SaveArtistDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCoreArtistDraft: Maybe<
    { __typename: 'CreateOrUpdateCoreArtistDraftPayload' } & {
      result: Maybe<
        { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreArtistDraft' } & Pick<
                CoreArtistDraft,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreArtist' } & Pick<
                CoreArtist,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishArtistMutationVariables = Exact<{
  input: PublishCoreArtistInput
}>

export type PublishArtistMutation = { __typename: 'RootMutationType' } & {
  publishCoreArtist: Maybe<
    { __typename: 'PublishCoreArtistPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreArtistDraft' } & Pick<
                CoreArtistDraft,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreArtist' } & Pick<
                CoreArtist,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type UnpublishArtistMutationVariables = Exact<{
  input: UnpublishCoreArtistInput
}>

export type UnpublishArtistMutation = { __typename: 'RootMutationType' } & {
  unpublishCoreArtist: Maybe<
    { __typename: 'UnpublishCoreArtistPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreArtistDraft' } & Pick<
                CoreArtistDraft,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreArtist' } & Pick<
                CoreArtist,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type DeleteArtistDraftMutationVariables = Exact<{
  input: DeleteCoreArtistDraftInput
}>

export type DeleteArtistDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCoreArtistDraft: Maybe<
    { __typename: 'DeleteCoreArtistDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreArtistDraft' } & Pick<
                CoreArtistDraft,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortName: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreArtist' } & Pick<
                CoreArtist,
                'courtesyOf' | 'dateOfBirth' | 'dateOfDeath' | 'partyId' | 'updatedAt' | 'photographer'
              > & {
                  biography: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortName: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  epoch: Maybe<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  >
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  roles: Maybe<
                    { __typename: 'CoreRoleConnection' } & {
                      edges: Array<
                        { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
                            node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type GetEpochByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetEpochByIdQuery = { __typename: 'RootQueryType' } & {
  coreEpoch: Maybe<
    { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type GetAllEpochsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}>

export type GetAllEpochsQuery = { __typename: 'RootQueryType' } & {
  coreEpochs: Maybe<
    { __typename: 'CoreEpochDataConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >
      edges: Array<
        { __typename: 'CoreEpochDataEdge' } & Pick<CoreEpochDataEdge, 'cursor'> & {
            node: { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
                published: Maybe<
                  { __typename: 'CoreEpoch' } & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  }
                >
                draft: Maybe<
                  { __typename: 'CoreEpochDraft' } & {
                    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  }
                >
              }
          }
      >
    }
  >
}

export type EpochListInfoFragment = { __typename: 'CoreEpoch' } & {
  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
}

export type EpochDraftListInfoFragment = { __typename: 'CoreEpochDraft' } & {
  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
}

export type EpochFragment = { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type EpochDraftFragment = { __typename: 'CoreEpochDraft' } & Pick<
  CoreEpochDraft,
  'updatedAt' | 'startYear' | 'endYear'
> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CoreEpochDataFragment = { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

export type SaveEpochDraftMutationVariables = Exact<{
  input: CreateOrUpdateCoreEpochDraftInput
}>

export type SaveEpochDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCoreEpochDraft: Maybe<
    { __typename: 'CreateOrUpdateCoreEpochDraftPayload' } & {
      result: Maybe<
        { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'> & {
            draft: Maybe<
              { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishEpochMutationVariables = Exact<{
  input: PublishCoreEpochInput
}>

export type PublishEpochMutation = { __typename: 'RootMutationType' } & {
  publishCoreEpoch: Maybe<
    { __typename: 'PublishCoreEpochPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishEpochMutationVariables = Exact<{
  input: UnpublishCoreEpochInput
}>

export type UnpublishEpochMutation = { __typename: 'RootMutationType' } & {
  unpublishCoreEpoch: Maybe<
    { __typename: 'UnpublishCoreEpochPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteEpochDraftMutationVariables = Exact<{
  input: DeleteCoreEpochDraftInput
}>

export type DeleteEpochDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCoreEpochDraft: Maybe<
    { __typename: 'DeleteCoreEpochDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreEpochDraft' } & Pick<CoreEpochDraft, 'updatedAt' | 'startYear' | 'endYear'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'startYear' | 'endYear' | 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetGenreByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetGenreByIdQuery = { __typename: 'RootQueryType' } & {
  coreGenre: Maybe<
    { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type GetAllGenresQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}>

export type GetAllGenresQuery = { __typename: 'RootQueryType' } & {
  coreGenres: Maybe<
    { __typename: 'CoreGenreDataConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >
      edges: Array<
        { __typename: 'CoreGenreDataEdge' } & Pick<CoreGenreDataEdge, 'cursor'> & {
            node: { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
                published: Maybe<
                  { __typename: 'CoreGenre' } & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  }
                >
                draft: Maybe<
                  { __typename: 'CoreGenreDraft' } & {
                    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  }
                >
              }
          }
      >
    }
  >
}

export type CoreGenreListInfoFragment = { __typename: 'CoreGenre' } & {
  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
}

export type CoreGenreDraftListInfoFragment = { __typename: 'CoreGenreDraft' } & {
  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
}

export type CoreGenreFragment = { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type CoreGenreDraftFragment = { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CoreGenreDataFragment = { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

export type SaveGenreDraftMutationVariables = Exact<{
  input: CreateOrUpdateCoreGenreDraftInput
}>

export type SaveGenreDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCoreGenreDraft: Maybe<
    { __typename: 'CreateOrUpdateCoreGenreDraftPayload' } & {
      result: Maybe<
        { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'> & {
            draft: Maybe<
              { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishGenreMutationVariables = Exact<{
  input: PublishCoreGenreInput
}>

export type PublishGenreMutation = { __typename: 'RootMutationType' } & {
  publishCoreGenre: Maybe<
    { __typename: 'PublishCoreGenrePayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishGenreMutationVariables = Exact<{
  input: UnpublishCoreGenreInput
}>

export type UnpublishGenreMutation = { __typename: 'RootMutationType' } & {
  unpublishCoreGenre: Maybe<
    { __typename: 'UnpublishCoreGenrePayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteGenreDraftMutationVariables = Exact<{
  input: DeleteCoreGenreDraftInput
}>

export type DeleteGenreDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCoreGenreDraft: Maybe<
    { __typename: 'DeleteCoreGenreDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGenreDraft' } & Pick<CoreGenreDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGenre' } & Pick<CoreGenre, 'updatedAt'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type GetGroupByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetGroupByIdQuery = { __typename: 'RootQueryType' } & {
  coreGroup: Maybe<
    { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CoreGroupDraft' } & Pick<CoreGroupDraft, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
              name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
      }
  >
}

export type GroupInfoFragment = { __typename: 'CoreGroup' } & Pick<
  CoreGroup,
  'partyId' | 'type' | 'updatedAt' | 'photographer'
> & {
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type GroupDraftInfoFragment = { __typename: 'CoreGroupDraft' } & Pick<
  CoreGroupDraft,
  'partyId' | 'type' | 'updatedAt' | 'photographer'
> & {
    name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CoreGroupDataFragment = { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CoreGroupDraft' } & Pick<CoreGroupDraft, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
          name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
  }

export type SaveGroupDraftMutationVariables = Exact<{
  input: CreateOrUpdateCoreGroupDraftInput
}>

export type SaveGroupDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCoreGroupDraft: Maybe<
    { __typename: 'CreateOrUpdateCoreGroupDraftPayload' } & {
      result: Maybe<
        { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGroupDraft' } & Pick<
                CoreGroupDraft,
                'partyId' | 'type' | 'updatedAt' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishGroupMutationVariables = Exact<{
  input: PublishCoreGroupInput
}>

export type PublishGroupMutation = { __typename: 'RootMutationType' } & {
  publishCoreGroup: Maybe<
    { __typename: 'PublishCoreGroupPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGroupDraft' } & Pick<
                CoreGroupDraft,
                'partyId' | 'type' | 'updatedAt' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type UnpublishGroupMutationVariables = Exact<{
  input: UnpublishCoreGroupInput
}>

export type UnpublishGroupMutation = { __typename: 'RootMutationType' } & {
  unpublishCoreGroup: Maybe<
    { __typename: 'UnpublishCoreGroupPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGroupDraft' } & Pick<
                CoreGroupDraft,
                'partyId' | 'type' | 'updatedAt' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type DeleteGroupDraftMutationVariables = Exact<{
  input: DeleteCoreGroupDraftInput
}>

export type DeleteGroupDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCoreGroupDraft: Maybe<
    { __typename: 'DeleteCoreGroupDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreGroupDraft' } & Pick<
                CoreGroupDraft,
                'partyId' | 'type' | 'updatedAt' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreGroup' } & Pick<CoreGroup, 'partyId' | 'type' | 'updatedAt' | 'photographer'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  footnote: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type GetPartnerByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPartnerByIdQuery = { __typename: 'RootQueryType' } & {
  corePartner: Maybe<
    { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CorePartnerDraft' } & Pick<
            CorePartnerDraft,
            'updatedAt' | 'type' | 'subtype' | 'photographer'
          > & {
              name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              albums: Maybe<
                { __typename: 'AlbumConnection' } & {
                  edges: Array<
                    { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                        node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          }
                      }
                  >
                }
              >
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
              name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
              albums: Maybe<
                { __typename: 'AlbumConnection' } & {
                  edges: Array<
                    { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                        node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          }
                      }
                  >
                }
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              contentContainers: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
      }
  >
}

export type PartnerInfoFragment = { __typename: 'CorePartner' } & Pick<
  CorePartner,
  'type' | 'subtype' | 'photographer' | 'updatedAt'
> & {
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    albums: Maybe<
      { __typename: 'AlbumConnection' } & {
        edges: Array<
          { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
              node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            }
        >
      }
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type PartnerDraftInfoFragment = { __typename: 'CorePartnerDraft' } & Pick<
  CorePartnerDraft,
  'updatedAt' | 'type' | 'subtype' | 'photographer'
> & {
    name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
    albums: Maybe<
      { __typename: 'AlbumConnection' } & {
        edges: Array<
          { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
              node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                }
            }
        >
      }
    >
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CorePartnerDataFragment = { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CorePartnerDraft' } & Pick<CorePartnerDraft, 'updatedAt' | 'type' | 'subtype' | 'photographer'> & {
          name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          albums: Maybe<
            { __typename: 'AlbumConnection' } & {
              edges: Array<
                { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                    node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  }
              >
            }
          >
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
          albums: Maybe<
            { __typename: 'AlbumConnection' } & {
              edges: Array<
                { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                    node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                      }
                  }
              >
            }
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
  }

export type PartnerAlbumInfoFragment = { __typename: 'Album' } & Pick<Album, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
  }

export type SavePartnerDraftMutationVariables = Exact<{
  input: CreateOrUpdateCorePartnerDraftInput
}>

export type SavePartnerDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCorePartnerDraft: Maybe<
    { __typename: 'CreateOrUpdateCorePartnerDraftPayload' } & {
      result: Maybe<
        { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CorePartnerDraft' } & Pick<
                CorePartnerDraft,
                'updatedAt' | 'type' | 'subtype' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishPartnerMutationVariables = Exact<{
  input: PublishCorePartnerInput
}>

export type PublishPartnerMutation = { __typename: 'RootMutationType' } & {
  publishCorePartner: Maybe<
    { __typename: 'PublishCorePartnerPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CorePartnerDraft' } & Pick<
                CorePartnerDraft,
                'updatedAt' | 'type' | 'subtype' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type UnpublishPartnerMutationVariables = Exact<{
  input: UnpublishCorePartnerInput
}>

export type UnpublishPartnerMutation = { __typename: 'RootMutationType' } & {
  unpublishCorePartner: Maybe<
    { __typename: 'UnpublishCorePartnerPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CorePartnerDraft' } & Pick<
                CorePartnerDraft,
                'updatedAt' | 'type' | 'subtype' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type DeletePartnerDraftMutationVariables = Exact<{
  input: DeleteCorePartnerDraftInput
}>

export type DeletePartnerDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCorePartnerDraft: Maybe<
    { __typename: 'DeleteCorePartnerDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CorePartnerDraft' } & Pick<
                CorePartnerDraft,
                'updatedAt' | 'type' | 'subtype' | 'photographer'
              > & {
                  name: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'subtype' | 'photographer' | 'updatedAt'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  sortLetter: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                  albums: Maybe<
                    { __typename: 'AlbumConnection' } & {
                      edges: Array<
                        { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
                            node: { __typename: 'Album' } & Pick<Album, 'id'> & {
                                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                              }
                          }
                      >
                    }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type GetWorkByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetWorkByIdQuery = { __typename: 'RootQueryType' } & {
  coreWork: Maybe<
    { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CoreWorkDraft' } & Pick<
            CoreWorkDraft,
            'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
          > & {
              composers: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              arrangers: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              librettists: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
              epochs: Array<
                { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
              genre: Maybe<
                { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              movements: Array<
                { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  }
              >
            }
        >
        published: Maybe<
          { __typename: 'CoreWork' } & Pick<
            CoreWork,
            'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
          > & {
              composers: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              arrangers: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              librettists: Maybe<
                { __typename: 'CoreArtistConnection' } & {
                  edges: Array<
                    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          }
                      }
                  >
                }
              >
              r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
              epochs: Array<
                { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
              genre: Maybe<
                { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  }
              >
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              movements: Array<
                { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  }
              >
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            }
        >
      }
  >
}

export type WorkInfoFragment = { __typename: 'CoreWork' } & Pick<
  CoreWork,
  'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
> & {
    composers: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    arrangers: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    librettists: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
    epochs: Array<
      { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
    genre: Maybe<
      { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    movements: Array<
      { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        }
    >
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
  }

export type WorkDraftInfoFragment = { __typename: 'CoreWorkDraft' } & Pick<
  CoreWorkDraft,
  'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
> & {
    composers: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    arrangers: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    librettists: Maybe<
      { __typename: 'CoreArtistConnection' } & {
        edges: Array<
          { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
              node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                }
            }
        >
      }
    >
    r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
    epochs: Array<
      { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
    genre: Maybe<
      { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        }
    >
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    movements: Array<
      { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        }
    >
  }

export type MovementFragment = { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
    title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
  }

export type CoreWorkDataFragment = { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'CoreWorkDraft' } & Pick<
        CoreWorkDraft,
        'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
      > & {
          composers: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          arrangers: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          librettists: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
          epochs: Array<
            { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
          genre: Maybe<
            { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          movements: Array<
            { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              }
          >
        }
    >
    published: Maybe<
      { __typename: 'CoreWork' } & Pick<
        CoreWork,
        'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
      > & {
          composers: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          arrangers: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          librettists: Maybe<
            { __typename: 'CoreArtistConnection' } & {
              edges: Array<
                { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                    node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                        name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  }
              >
            }
          >
          r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
          epochs: Array<
            { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
          genre: Maybe<
            { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              }
          >
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          movements: Array<
            { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              }
          >
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
        }
    >
  }

export type SaveWorkDraftMutationVariables = Exact<{
  input: CreateOrUpdateCoreWorkDraftInput
}>

export type SaveWorkDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCoreWorkDraft: Maybe<
    { __typename: 'CreateOrUpdateCoreWorkDraftPayload' } & {
      result: Maybe<
        { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreWorkDraft' } & Pick<
                CoreWorkDraft,
                'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreWork' } & Pick<
                CoreWork,
                'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type PublishWorkMutationVariables = Exact<{
  input: PublishCoreWorkInput
}>

export type PublishWorkMutation = { __typename: 'RootMutationType' } & {
  publishCoreWork: Maybe<
    { __typename: 'PublishCoreWorkPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreWorkDraft' } & Pick<
                CoreWorkDraft,
                'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreWork' } & Pick<
                CoreWork,
                'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type UnpublishWorkMutationVariables = Exact<{
  input: UnpublishCoreWorkInput
}>

export type UnpublishWorkMutation = { __typename: 'RootMutationType' } & {
  unpublishCoreWork: Maybe<
    { __typename: 'UnpublishCoreWorkPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreWorkDraft' } & Pick<
                CoreWorkDraft,
                'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreWork' } & Pick<
                CoreWork,
                'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type DeleteWorkDraftMutationVariables = Exact<{
  input: DeleteCoreWorkDraftInput
}>

export type DeleteWorkDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCoreWorkDraft: Maybe<
    { __typename: 'DeleteCoreWorkDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CoreWorkDraft' } & Pick<
                CoreWorkDraft,
                'copyright' | 'publisher' | 'yearOfComposition' | 'updatedAt' | 'publicDomain' | 'r2idNote'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                }
            >
            published: Maybe<
              { __typename: 'CoreWork' } & Pick<
                CoreWork,
                'copyright' | 'publisher' | 'yearOfComposition' | 'publicDomain' | 'r2idNote' | 'updatedAt'
              > & {
                  composers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  arrangers: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  librettists: Maybe<
                    { __typename: 'CoreArtistConnection' } & {
                      edges: Array<
                        { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
                            node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                                name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                              }
                          }
                      >
                    }
                  >
                  r2Work: Maybe<{ __typename: 'R2Work' } & Pick<R2Work, 'id' | 'r2Id' | 'title'>>
                  epochs: Array<
                    { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  genre: Maybe<
                    { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'> & {
                        title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      }
                  >
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  movements: Array<
                    { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'> & {
                        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      }
                  >
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                }
            >
          }
      >
    }
  >
}

export type CopyFromR2WorkMutationVariables = Exact<{
  input: InputMaybe<CopyR2WorkToCoreWorkInput>
}>

export type CopyFromR2WorkMutation = { __typename: 'RootMutationType' } & {
  copyR2WorkToCoreWork: Maybe<
    { __typename: 'CopyR2WorkToCoreWorkPayload' } & Pick<CopyR2WorkToCoreWorkPayload, 'result'> & {
        errors: Maybe<
          Array<
            | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'message' | 'path'>)
            | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
            | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path'>)
            | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<UniqueIsrcPublicationError, 'message' | 'path'>)
            | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path'>)
          >
        >
      }
  >
}

export type SaveBannerDraftMutationVariables = Exact<{
  input: CreateOrUpdateBannerDraftInput
}>

export type SaveBannerDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateBannerDraft: Maybe<
    { __typename: 'CreateOrUpdateBannerDraftPayload' } & {
      result: Maybe<
        { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishBannerMutationVariables = Exact<{
  input: PublishBannerInput
}>

export type PublishBannerMutation = { __typename: 'RootMutationType' } & {
  publishBanner: Maybe<
    { __typename: 'PublishBannerPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type UnpublishBannerMutationVariables = Exact<{
  input: UnpublishBannerInput
}>

export type UnpublishBannerMutation = { __typename: 'RootMutationType' } & {
  unpublishBanner: Maybe<
    { __typename: 'UnpublishBannerPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type DeleteBannerDraftMutationVariables = Exact<{
  input: DeleteBannerDraftInput
}>

export type DeleteBannerDraftMutation = { __typename: 'RootMutationType' } & {
  deleteBannerDraft: Maybe<
    { __typename: 'DeleteBannerDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetBannerByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetBannerByIdQuery = { __typename: 'RootQueryType' } & {
  banner: Maybe<
    { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
            }
        >
        published: Maybe<
          { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              pictures: Maybe<
                { __typename: 'PictureConnection' } & {
                  edges: Array<
                    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                      }
                  >
                }
              >
            }
        >
      }
  >
}

export type BannerDataFragment = { __typename: 'BannerData' } & Pick<BannerData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
        }
    >
    published: Maybe<
      { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          pictures: Maybe<
            { __typename: 'PictureConnection' } & {
              edges: Array<
                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                  }
              >
            }
          >
        }
    >
  }

export type BannerDraftInfoFragment = { __typename: 'BannerDraft' } & Pick<BannerDraft, 'updatedAt'> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    description: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    link: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

export type BannerInfoFragment = { __typename: 'Banner' } & Pick<Banner, 'updatedAt'> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    description: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    link: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

export type SaveCuratedPageDraftMutationVariables = Exact<{
  input: CreateOrUpdateCuratedContentDraftInput
}>

export type SaveCuratedPageDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCuratedContentDraft: Maybe<
    { __typename: 'CreateOrUpdateCuratedContentDraftPayload' } & {
      result: Maybe<
        { __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedContentDraft' } & Pick<CuratedContentDraft, 'updatedAt' | 'slug'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishCuratedPageMutationVariables = Exact<{
  input: PublishCuratedContentInput
}>

export type PublishCuratedPageMutation = { __typename: 'RootMutationType' } & {
  publishCuratedContent: Maybe<
    { __typename: 'PublishCuratedContentPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedContentDraft' } & Pick<CuratedContentDraft, 'updatedAt' | 'slug'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  contentContainers: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type GetCuratedPageByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCuratedPageByIdQuery = { __typename: 'RootQueryType' } & {
  curatedContent: { __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id' | 'status'> & {
      draft: Maybe<
        { __typename: 'CuratedContentDraft' } & Pick<CuratedContentDraft, 'updatedAt' | 'slug'> & {
            editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            contentContainers: Array<
              | ({ __typename: 'Album' } & Pick<Album, 'id'>)
              | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
              | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
              | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
              | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
              | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
              | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
              | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
              | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
              | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
              | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
              | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
              | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
              | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
              | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
              | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
              | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
              | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
              | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
              | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
              | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
              | ({ __typename: 'Label' } & Pick<Label, 'id'>)
              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
              | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
              | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
              | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
              | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
              | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
              | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
              | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
              | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
              | ({ __typename: 'Track' } & Pick<Track, 'id'>)
              | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
              | ({ __typename: 'User' } & Pick<User, 'id'>)
              | ({ __typename: 'Video' } & Pick<Video, 'id'>)
              | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
              | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
            >
          }
      >
      published: Maybe<
        { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
            editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
            contentContainers: Array<
              | ({ __typename: 'Album' } & Pick<Album, 'id'>)
              | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
              | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
              | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
              | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
              | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
              | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
              | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
              | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
              | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
              | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
              | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
              | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
              | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
              | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
              | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
              | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
              | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
              | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
              | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
              | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
              | ({ __typename: 'Label' } & Pick<Label, 'id'>)
              | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
              | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
              | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
              | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
              | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
              | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
              | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
              | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
              | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
              | ({ __typename: 'Track' } & Pick<Track, 'id'>)
              | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
              | ({ __typename: 'User' } & Pick<User, 'id'>)
              | ({ __typename: 'Video' } & Pick<Video, 'id'>)
              | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
              | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
              | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
            >
          }
      >
    }
}

export type GetAllCuratedPagesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}>

export type GetAllCuratedPagesQuery = { __typename: 'RootQueryType' } & {
  curatedContents: Maybe<
    { __typename: 'CuratedContentDataConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >
      edges: Array<
        { __typename: 'CuratedContentDataEdge' } & Pick<CuratedContentDataEdge, 'cursor'> & {
            node: { __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id' | 'status'> & {
                draft: Maybe<
                  { __typename: 'CuratedContentDraft' } & Pick<CuratedContentDraft, 'updatedAt' | 'slug'> & {
                      editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                      contentContainers: Array<
                        | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                      >
                    }
                >
                published: Maybe<
                  { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
                      editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                      contentContainers: Array<
                        | ({ __typename: 'Album' } & Pick<Album, 'id'>)
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'id'>)
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                      >
                    }
                >
              }
          }
      >
    }
  >
}

export type CuratedContentDataFragment = { __typename: 'CuratedContentData' } & Pick<
  CuratedContentData,
  'id' | 'status'
> & {
    draft: Maybe<
      { __typename: 'CuratedContentDraft' } & Pick<CuratedContentDraft, 'updatedAt' | 'slug'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          contentContainers: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'>)
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'>)
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
  }

export type CuratedContentDraftInfoFragment = { __typename: 'CuratedContentDraft' } & Pick<
  CuratedContentDraft,
  'updatedAt' | 'slug'
> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CuratedContentInfoFragment = { __typename: 'CuratedContent' } & Pick<CuratedContent, 'slug'> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    contentContainers: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'>)
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'>)
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'>)
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'>)
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type SaveSliderDraftMutationVariables = Exact<{
  input: CreateOrUpdateCuratedSliderDraftInput
}>

export type SaveSliderDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateCuratedSliderDraft: Maybe<
    { __typename: 'CreateOrUpdateCuratedSliderDraftPayload' } & {
      result: Maybe<
        { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedSliderDraft' } & Pick<
                CuratedSliderDraft,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedSlider' } & Pick<
                CuratedSlider,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishSliderMutationVariables = Exact<{
  input: PublishCuratedSliderInput
}>

export type PublishSliderMutation = { __typename: 'RootMutationType' } & {
  publishCuratedSlider: Maybe<
    { __typename: 'PublishCuratedSliderPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedSliderDraft' } & Pick<
                CuratedSliderDraft,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedSlider' } & Pick<
                CuratedSlider,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type UnpublishSliderMutationVariables = Exact<{
  input: UnpublishCuratedSliderInput
}>

export type UnpublishSliderMutation = { __typename: 'RootMutationType' } & {
  unpublishCuratedSlider: Maybe<
    { __typename: 'UnpublishCuratedSliderPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedSliderDraft' } & Pick<
                CuratedSliderDraft,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedSlider' } & Pick<
                CuratedSlider,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type DeleteSliderDraftMutationVariables = Exact<{
  input: DeleteCuratedSliderDraftInput
}>

export type DeleteSliderDraftMutation = { __typename: 'RootMutationType' } & {
  deleteCuratedSliderDraft: Maybe<
    { __typename: 'DeleteCuratedSliderDraftPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'CuratedSliderDraft' } & Pick<
                CuratedSliderDraft,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
            published: Maybe<
              { __typename: 'CuratedSlider' } & Pick<
                CuratedSlider,
                'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
              > & {
                  editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
                  headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                  items: Array<
                    | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureWithEditorConnection' } & {
                              edges: Array<
                                { __typename: 'PictureWithEditorEdge' } & {
                                  node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                                }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                          name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                          pictures: Maybe<
                            { __typename: 'PictureConnection' } & {
                              edges: Array<
                                { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                    node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                                  }
                              >
                            }
                          >
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                  >
                }
            >
          }
      >
    }
  >
}

export type GetCuratedSliderByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCuratedSliderByIdQuery = { __typename: 'RootQueryType' } & {
  curatedSlider: Maybe<
    { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'CuratedSliderDraft' } & Pick<
            CuratedSliderDraft,
            'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
          > & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              items: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureWithEditorConnection' } & {
                          edges: Array<
                            { __typename: 'PictureWithEditorEdge' } & {
                              node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                            }
                          >
                        }
                      >
                    })
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
        published: Maybe<
          { __typename: 'CuratedSlider' } & Pick<
            CuratedSlider,
            'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
          > & {
              editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
              headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
              items: Array<
                | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureWithEditorConnection' } & {
                          edges: Array<
                            { __typename: 'PictureWithEditorEdge' } & {
                              node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                            }
                          >
                        }
                      >
                    })
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                      name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                      pictures: Maybe<
                        { __typename: 'PictureConnection' } & {
                          edges: Array<
                            { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                                node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                              }
                          >
                        }
                      >
                    })
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
              >
            }
        >
      }
  >
}

export type CuratedSliderDataFragment = { __typename: 'CuratedSliderData' } & Pick<
  CuratedSliderData,
  'id' | 'status'
> & {
    draft: Maybe<
      { __typename: 'CuratedSliderDraft' } & Pick<
        CuratedSliderDraft,
        'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
      > & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          items: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureWithEditorConnection' } & {
                      edges: Array<
                        { __typename: 'PictureWithEditorEdge' } & {
                          node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                        }
                      >
                    }
                  >
                })
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
    published: Maybe<
      { __typename: 'CuratedSlider' } & Pick<
        CuratedSlider,
        'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
      > & {
          editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
          headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          items: Array<
            | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureWithEditorConnection' } & {
                      edges: Array<
                        { __typename: 'PictureWithEditorEdge' } & {
                          node: { __typename: 'Picture' } & Pick<Picture, 'url'>
                        }
                      >
                    }
                  >
                })
            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
                  name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
            | ({ __typename: 'User' } & Pick<User, 'id'>)
            | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                  pictures: Maybe<
                    { __typename: 'PictureConnection' } & {
                      edges: Array<
                        { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                            node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                          }
                      >
                    }
                  >
                })
            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
          >
        }
    >
  }

export type CuratedSliderDraftInfoFragment = { __typename: 'CuratedSliderDraft' } & Pick<
  CuratedSliderDraft,
  'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    items: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureWithEditorConnection' } & {
                edges: Array<
                  { __typename: 'PictureWithEditorEdge' } & { node: { __typename: 'Picture' } & Pick<Picture, 'url'> }
                >
              }
            >
          })
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

export type CuratedSliderInfoFragment = { __typename: 'CuratedSlider' } & Pick<
  CuratedSlider,
  'updatedAt' | 'type' | 'partyId' | 'visibleFor' | 'description'
> & {
    editor: { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>
    headline: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
    items: Array<
      | ({ __typename: 'Album' } & Pick<Album, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureWithEditorConnection' } & {
                edges: Array<
                  { __typename: 'PictureWithEditorEdge' } & { node: { __typename: 'Picture' } & Pick<Picture, 'url'> }
                >
              }
            >
          })
      | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
      | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
      | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
      | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
      | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
      | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
      | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
      | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
      | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
      | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
      | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
      | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
      | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
      | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
      | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
      | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
      | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
      | ({ __typename: 'Label' } & Pick<Label, 'id'>)
      | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
      | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
      | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
      | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
      | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
      | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
      | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
      | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
      | ({ __typename: 'Track' } & Pick<Track, 'id'>)
      | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
      | ({ __typename: 'User' } & Pick<User, 'id'>)
      | ({ __typename: 'Video' } & Pick<Video, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
      | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
            pictures: Maybe<
              { __typename: 'PictureConnection' } & {
                edges: Array<
                  { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
                      node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
                    }
                >
              }
            >
          })
      | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
    >
  }

type CuratedSliderItems_Album_Fragment = { __typename: 'Album' } & Pick<Album, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureWithEditorConnection' } & {
        edges: Array<
          { __typename: 'PictureWithEditorEdge' } & { node: { __typename: 'Picture' } & Pick<Picture, 'url'> }
        >
      }
    >
  }

type CuratedSliderItems_Banner_Fragment = { __typename: 'Banner' } & Pick<Banner, 'id'>

type CuratedSliderItems_BannerData_Fragment = { __typename: 'BannerData' } & Pick<BannerData, 'id'>

type CuratedSliderItems_CoreArtist_Fragment = { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_CoreArtistData_Fragment = { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>

type CuratedSliderItems_CoreEpoch_Fragment = { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>

type CuratedSliderItems_CoreEpochData_Fragment = { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>

type CuratedSliderItems_CoreGenre_Fragment = { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>

type CuratedSliderItems_CoreGenreData_Fragment = { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>

type CuratedSliderItems_CoreGroup_Fragment = { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_CoreGroupData_Fragment = { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>

type CuratedSliderItems_CoreMovement_Fragment = { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>

type CuratedSliderItems_CorePartner_Fragment = { __typename: 'CorePartner' } & Pick<CorePartner, 'type' | 'id'> & {
    name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_CorePartnerData_Fragment = { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>

type CuratedSliderItems_CoreRole_Fragment = { __typename: 'CoreRole' } & Pick<CoreRole, 'id'>

type CuratedSliderItems_CoreWork_Fragment = { __typename: 'CoreWork' } & Pick<CoreWork, 'id'>

type CuratedSliderItems_CoreWorkData_Fragment = { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>

type CuratedSliderItems_CuratedContent_Fragment = { __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>

type CuratedSliderItems_CuratedContentData_Fragment = { __typename: 'CuratedContentData' } & Pick<
  CuratedContentData,
  'id'
>

type CuratedSliderItems_CuratedSlider_Fragment = { __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>

type CuratedSliderItems_CuratedSliderData_Fragment = { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>

type CuratedSliderItems_Label_Fragment = { __typename: 'Label' } & Pick<Label, 'id'>

type CuratedSliderItems_LiveConcert_Fragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_LiveConcertData_Fragment = { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>

type CuratedSliderItems_PerformanceWork_Fragment = { __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>

type CuratedSliderItems_PerformanceWorkData_Fragment = { __typename: 'PerformanceWorkData' } & Pick<
  PerformanceWorkData,
  'id'
>

type CuratedSliderItems_Picture_Fragment = { __typename: 'Picture' } & Pick<Picture, 'id'>

type CuratedSliderItems_R2Work_Fragment = { __typename: 'R2Work' } & Pick<R2Work, 'id'>

type CuratedSliderItems_Stage_Fragment = { __typename: 'Stage' } & Pick<Stage, 'id'>

type CuratedSliderItems_StageData_Fragment = { __typename: 'StageData' } & Pick<StageData, 'id'>

type CuratedSliderItems_Stream_Fragment = { __typename: 'Stream' } & Pick<Stream, 'id'>

type CuratedSliderItems_Track_Fragment = { __typename: 'Track' } & Pick<Track, 'id'>

type CuratedSliderItems_TrackSet_Fragment = { __typename: 'TrackSet' } & Pick<TrackSet, 'id'>

type CuratedSliderItems_User_Fragment = { __typename: 'User' } & Pick<User, 'id'>

type CuratedSliderItems_Video_Fragment = { __typename: 'Video' } & Pick<Video, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_VideoData_Fragment = { __typename: 'VideoData' } & Pick<VideoData, 'id'>

type CuratedSliderItems_VodConcert_Fragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
    pictures: Maybe<
      { __typename: 'PictureConnection' } & {
        edges: Array<
          { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
              node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
            }
        >
      }
    >
  }

type CuratedSliderItems_VodConcertData_Fragment = { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>

export type CuratedSliderItemsFragment =
  | CuratedSliderItems_Album_Fragment
  | CuratedSliderItems_Banner_Fragment
  | CuratedSliderItems_BannerData_Fragment
  | CuratedSliderItems_CoreArtist_Fragment
  | CuratedSliderItems_CoreArtistData_Fragment
  | CuratedSliderItems_CoreEpoch_Fragment
  | CuratedSliderItems_CoreEpochData_Fragment
  | CuratedSliderItems_CoreGenre_Fragment
  | CuratedSliderItems_CoreGenreData_Fragment
  | CuratedSliderItems_CoreGroup_Fragment
  | CuratedSliderItems_CoreGroupData_Fragment
  | CuratedSliderItems_CoreMovement_Fragment
  | CuratedSliderItems_CorePartner_Fragment
  | CuratedSliderItems_CorePartnerData_Fragment
  | CuratedSliderItems_CoreRole_Fragment
  | CuratedSliderItems_CoreWork_Fragment
  | CuratedSliderItems_CoreWorkData_Fragment
  | CuratedSliderItems_CuratedContent_Fragment
  | CuratedSliderItems_CuratedContentData_Fragment
  | CuratedSliderItems_CuratedSlider_Fragment
  | CuratedSliderItems_CuratedSliderData_Fragment
  | CuratedSliderItems_Label_Fragment
  | CuratedSliderItems_LiveConcert_Fragment
  | CuratedSliderItems_LiveConcertData_Fragment
  | CuratedSliderItems_PerformanceWork_Fragment
  | CuratedSliderItems_PerformanceWorkData_Fragment
  | CuratedSliderItems_Picture_Fragment
  | CuratedSliderItems_R2Work_Fragment
  | CuratedSliderItems_Stage_Fragment
  | CuratedSliderItems_StageData_Fragment
  | CuratedSliderItems_Stream_Fragment
  | CuratedSliderItems_Track_Fragment
  | CuratedSliderItems_TrackSet_Fragment
  | CuratedSliderItems_User_Fragment
  | CuratedSliderItems_Video_Fragment
  | CuratedSliderItems_VideoData_Fragment
  | CuratedSliderItems_VodConcert_Fragment
  | CuratedSliderItems_VodConcertData_Fragment

export type GetStageByIdQueryVariables = Exact<{
  slug: CuratedContentSlug
}>

export type GetStageByIdQuery = { __typename: 'RootQueryType' } & {
  stage: Maybe<
    { __typename: 'StageData' } & Pick<StageData, 'id' | 'status'> & {
        draft: Maybe<
          { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
              items: Array<
                { __typename: 'StageItem' } & {
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  item:
                    | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                }
              >
            }
        >
        published: Maybe<
          { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
              items: Array<
                { __typename: 'StageItem' } & {
                  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                  item:
                    | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                    | ({ __typename: 'User' } & Pick<User, 'id'>)
                    | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                        })
                    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                }
              >
            }
        >
      }
  >
}

export type SaveStageDraftMutationVariables = Exact<{
  input: CreateOrUpdateStageDraftInput
}>

export type SaveStageDraftMutation = { __typename: 'RootMutationType' } & {
  createOrUpdateStageDraft: Maybe<
    { __typename: 'CreateOrUpdateStageDraftPayload' } & {
      result: Maybe<
        { __typename: 'StageData' } & Pick<StageData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
                  items: Array<
                    { __typename: 'StageItem' } & {
                      title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      item:
                        | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
                  items: Array<
                    { __typename: 'StageItem' } & {
                      title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      item:
                        | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type PublishStageMutationVariables = Exact<{
  input: PublishStageInput
}>

export type PublishStageMutation = { __typename: 'RootMutationType' } & {
  publishStage: Maybe<
    { __typename: 'PublishStagePayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'StageData' } & Pick<StageData, 'id' | 'status'> & {
            draft: Maybe<
              { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
                  items: Array<
                    { __typename: 'StageItem' } & {
                      title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      item:
                        | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                    }
                  >
                }
            >
            published: Maybe<
              { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
                  items: Array<
                    { __typename: 'StageItem' } & {
                      title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                      item:
                        | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                        | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                        | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                        | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                        | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                        | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                        | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                        | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                        | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                        | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                        | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                        | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                        | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                        | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                        | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                        | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                        | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                        | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                        | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                        | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                        | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                        | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                        | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                        | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                        | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                        | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                        | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                        | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                        | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                        | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                        | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                        | ({ __typename: 'User' } & Pick<User, 'id'>)
                        | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                        | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                              title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                            })
                        | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                    }
                  >
                }
            >
          }
      >
    }
  >
}

export type GetAllStagesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}>

export type GetAllStagesQuery = { __typename: 'RootQueryType' } & {
  stages: Maybe<
    { __typename: 'StageDataConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
      >
      edges: Array<
        { __typename: 'StageDataEdge' } & Pick<StageDataEdge, 'cursor'> & {
            node: { __typename: 'StageData' } & Pick<StageData, 'id' | 'status'> & {
                draft: Maybe<
                  { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
                      items: Array<
                        { __typename: 'StageItem' } & {
                          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                          subtitle: Maybe<
                            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          >
                          label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                          item:
                            | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                            | ({ __typename: 'User' } & Pick<User, 'id'>)
                            | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                        }
                      >
                    }
                >
                published: Maybe<
                  { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
                      items: Array<
                        { __typename: 'StageItem' } & {
                          title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                          subtitle: Maybe<
                            { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
                          >
                          label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
                          item:
                            | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                            | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                            | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                            | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                            | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                            | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                            | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                            | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                            | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                            | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                            | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                            | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                            | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                            | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                            | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                            | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                            | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                            | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                            | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                            | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                            | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                            | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                            | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                            | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                            | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                            | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                            | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                            | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                            | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                            | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                            | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                            | ({ __typename: 'User' } & Pick<User, 'id'>)
                            | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                            | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                                  title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                                })
                            | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
                        }
                      >
                    }
                >
              }
          }
      >
    }
  >
}

export type StageDataFragment = { __typename: 'StageData' } & Pick<StageData, 'id' | 'status'> & {
    draft: Maybe<
      { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
          items: Array<
            { __typename: 'StageItem' } & {
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              item:
                | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
            }
          >
        }
    >
    published: Maybe<
      { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
          items: Array<
            { __typename: 'StageItem' } & {
              title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
              item:
                | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
                | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
                | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
                | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
                | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
                | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
                | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
                | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
                | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
                | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
                | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
                | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
                | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
                | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
                | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
                | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
                | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
                | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
                | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
                | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
                | ({ __typename: 'Label' } & Pick<Label, 'id'>)
                | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
                | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
                | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
                | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
                | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
                | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
                | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
                | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
                | ({ __typename: 'Track' } & Pick<Track, 'id'>)
                | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
                | ({ __typename: 'User' } & Pick<User, 'id'>)
                | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
                | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                      title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
                    })
                | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
            }
          >
        }
    >
  }

export type StageFragment = { __typename: 'Stage' } & Pick<Stage, 'id' | 'slug'> & {
    items: Array<
      { __typename: 'StageItem' } & {
        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        item:
          | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
          | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
          | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
          | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
          | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
          | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
          | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
          | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
          | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
          | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
          | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
          | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
          | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
          | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
          | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
          | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
          | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
          | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
          | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
          | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
          | ({ __typename: 'Label' } & Pick<Label, 'id'>)
          | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
          | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
          | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
          | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
          | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
          | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
          | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
          | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
          | ({ __typename: 'Track' } & Pick<Track, 'id'>)
          | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
          | ({ __typename: 'User' } & Pick<User, 'id'>)
          | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
          | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
      }
    >
  }

export type StageDraftFragment = { __typename: 'StageDraft' } & Pick<StageDraft, 'slug'> & {
    items: Array<
      { __typename: 'StageItem' } & {
        title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
        item:
          | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
          | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
          | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
          | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
          | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
          | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
          | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
          | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
          | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
          | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
          | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
          | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
          | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
          | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
          | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
          | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
          | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
          | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
          | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
          | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
          | ({ __typename: 'Label' } & Pick<Label, 'id'>)
          | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
          | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
          | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
          | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
          | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
          | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
          | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
          | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
          | ({ __typename: 'Track' } & Pick<Track, 'id'>)
          | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
          | ({ __typename: 'User' } & Pick<User, 'id'>)
          | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
          | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
                title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
              })
          | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
      }
    >
  }

export type StageItemFragment = { __typename: 'StageItem' } & {
  title: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
  subtitle: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
  label: Maybe<{ __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>>
  item:
    | ({ __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        })
    | ({ __typename: 'Banner' } & Pick<Banner, 'id'>)
    | ({ __typename: 'BannerData' } & Pick<BannerData, 'id'>)
    | ({ __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>)
    | ({ __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>)
    | ({ __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>)
    | ({ __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>)
    | ({ __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>)
    | ({ __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>)
    | ({ __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>)
    | ({ __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>)
    | ({ __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>)
    | ({ __typename: 'CorePartner' } & Pick<CorePartner, 'id'>)
    | ({ __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>)
    | ({ __typename: 'CoreRole' } & Pick<CoreRole, 'id'>)
    | ({ __typename: 'CoreWork' } & Pick<CoreWork, 'id'>)
    | ({ __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>)
    | ({ __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>)
    | ({ __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>)
    | ({ __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>)
    | ({ __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>)
    | ({ __typename: 'Label' } & Pick<Label, 'id'>)
    | ({ __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        })
    | ({ __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>)
    | ({ __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>)
    | ({ __typename: 'PerformanceWorkData' } & Pick<PerformanceWorkData, 'id'>)
    | ({ __typename: 'Picture' } & Pick<Picture, 'id'>)
    | ({ __typename: 'R2Work' } & Pick<R2Work, 'id'>)
    | ({ __typename: 'Stage' } & Pick<Stage, 'id'>)
    | ({ __typename: 'StageData' } & Pick<StageData, 'id'>)
    | ({ __typename: 'Stream' } & Pick<Stream, 'id'>)
    | ({ __typename: 'Track' } & Pick<Track, 'id'>)
    | ({ __typename: 'TrackSet' } & Pick<TrackSet, 'id'>)
    | ({ __typename: 'User' } & Pick<User, 'id'>)
    | ({ __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        })
    | ({ __typename: 'VideoData' } & Pick<VideoData, 'id'>)
    | ({ __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
          title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
        })
    | ({ __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>)
}

type ReferencedItem_Album_Fragment = { __typename: 'Album' } & Pick<Album, 'upc' | 'releaseDate' | 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type ReferencedItem_Banner_Fragment = { __typename: 'Banner' } & Pick<Banner, 'id'>

type ReferencedItem_BannerData_Fragment = { __typename: 'BannerData' } & Pick<BannerData, 'id'>

type ReferencedItem_CoreArtist_Fragment = { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'>

type ReferencedItem_CoreArtistData_Fragment = { __typename: 'CoreArtistData' } & Pick<CoreArtistData, 'id'>

type ReferencedItem_CoreEpoch_Fragment = { __typename: 'CoreEpoch' } & Pick<CoreEpoch, 'id'>

type ReferencedItem_CoreEpochData_Fragment = { __typename: 'CoreEpochData' } & Pick<CoreEpochData, 'id'>

type ReferencedItem_CoreGenre_Fragment = { __typename: 'CoreGenre' } & Pick<CoreGenre, 'id'>

type ReferencedItem_CoreGenreData_Fragment = { __typename: 'CoreGenreData' } & Pick<CoreGenreData, 'id'>

type ReferencedItem_CoreGroup_Fragment = { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'>

type ReferencedItem_CoreGroupData_Fragment = { __typename: 'CoreGroupData' } & Pick<CoreGroupData, 'id'>

type ReferencedItem_CoreMovement_Fragment = { __typename: 'CoreMovement' } & Pick<CoreMovement, 'id'>

type ReferencedItem_CorePartner_Fragment = { __typename: 'CorePartner' } & Pick<CorePartner, 'id'>

type ReferencedItem_CorePartnerData_Fragment = { __typename: 'CorePartnerData' } & Pick<CorePartnerData, 'id'>

type ReferencedItem_CoreRole_Fragment = { __typename: 'CoreRole' } & Pick<CoreRole, 'id'>

type ReferencedItem_CoreWork_Fragment = { __typename: 'CoreWork' } & Pick<CoreWork, 'id'>

type ReferencedItem_CoreWorkData_Fragment = { __typename: 'CoreWorkData' } & Pick<CoreWorkData, 'id'>

type ReferencedItem_CuratedContent_Fragment = { __typename: 'CuratedContent' } & Pick<CuratedContent, 'id'>

type ReferencedItem_CuratedContentData_Fragment = { __typename: 'CuratedContentData' } & Pick<CuratedContentData, 'id'>

type ReferencedItem_CuratedSlider_Fragment = { __typename: 'CuratedSlider' } & Pick<CuratedSlider, 'id'>

type ReferencedItem_CuratedSliderData_Fragment = { __typename: 'CuratedSliderData' } & Pick<CuratedSliderData, 'id'>

type ReferencedItem_Label_Fragment = { __typename: 'Label' } & Pick<Label, 'id'>

type ReferencedItem_LiveConcert_Fragment = { __typename: 'LiveConcert' } & Pick<LiveConcert, 'isrc' | 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type ReferencedItem_LiveConcertData_Fragment = { __typename: 'LiveConcertData' } & Pick<LiveConcertData, 'id'>

type ReferencedItem_PerformanceWork_Fragment = { __typename: 'PerformanceWork' } & Pick<PerformanceWork, 'id'>

type ReferencedItem_PerformanceWorkData_Fragment = { __typename: 'PerformanceWorkData' } & Pick<
  PerformanceWorkData,
  'id'
>

type ReferencedItem_Picture_Fragment = { __typename: 'Picture' } & Pick<Picture, 'id'>

type ReferencedItem_R2Work_Fragment = { __typename: 'R2Work' } & Pick<R2Work, 'id'>

type ReferencedItem_Stage_Fragment = { __typename: 'Stage' } & Pick<Stage, 'id'>

type ReferencedItem_StageData_Fragment = { __typename: 'StageData' } & Pick<StageData, 'id'>

type ReferencedItem_Stream_Fragment = { __typename: 'Stream' } & Pick<Stream, 'id'>

type ReferencedItem_Track_Fragment = { __typename: 'Track' } & Pick<Track, 'id'>

type ReferencedItem_TrackSet_Fragment = { __typename: 'TrackSet' } & Pick<TrackSet, 'id'>

type ReferencedItem_User_Fragment = { __typename: 'User' } & Pick<User, 'id'>

type ReferencedItem_Video_Fragment = { __typename: 'Video' } & Pick<Video, 'type' | 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type ReferencedItem_VideoData_Fragment = { __typename: 'VideoData' } & Pick<VideoData, 'id'>

type ReferencedItem_VodConcert_Fragment = { __typename: 'VodConcert' } & Pick<VodConcert, 'isrc' | 'airDate' | 'id'> & {
    title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
  }

type ReferencedItem_VodConcertData_Fragment = { __typename: 'VodConcertData' } & Pick<VodConcertData, 'id'>

export type ReferencedItemFragment =
  | ReferencedItem_Album_Fragment
  | ReferencedItem_Banner_Fragment
  | ReferencedItem_BannerData_Fragment
  | ReferencedItem_CoreArtist_Fragment
  | ReferencedItem_CoreArtistData_Fragment
  | ReferencedItem_CoreEpoch_Fragment
  | ReferencedItem_CoreEpochData_Fragment
  | ReferencedItem_CoreGenre_Fragment
  | ReferencedItem_CoreGenreData_Fragment
  | ReferencedItem_CoreGroup_Fragment
  | ReferencedItem_CoreGroupData_Fragment
  | ReferencedItem_CoreMovement_Fragment
  | ReferencedItem_CorePartner_Fragment
  | ReferencedItem_CorePartnerData_Fragment
  | ReferencedItem_CoreRole_Fragment
  | ReferencedItem_CoreWork_Fragment
  | ReferencedItem_CoreWorkData_Fragment
  | ReferencedItem_CuratedContent_Fragment
  | ReferencedItem_CuratedContentData_Fragment
  | ReferencedItem_CuratedSlider_Fragment
  | ReferencedItem_CuratedSliderData_Fragment
  | ReferencedItem_Label_Fragment
  | ReferencedItem_LiveConcert_Fragment
  | ReferencedItem_LiveConcertData_Fragment
  | ReferencedItem_PerformanceWork_Fragment
  | ReferencedItem_PerformanceWorkData_Fragment
  | ReferencedItem_Picture_Fragment
  | ReferencedItem_R2Work_Fragment
  | ReferencedItem_Stage_Fragment
  | ReferencedItem_StageData_Fragment
  | ReferencedItem_Stream_Fragment
  | ReferencedItem_Track_Fragment
  | ReferencedItem_TrackSet_Fragment
  | ReferencedItem_User_Fragment
  | ReferencedItem_Video_Fragment
  | ReferencedItem_VideoData_Fragment
  | ReferencedItem_VodConcert_Fragment
  | ReferencedItem_VodConcertData_Fragment

export type PackageAlbumsMutationVariables = Exact<{
  input: InputMaybe<PackageAlbumsInput>
}>

export type PackageAlbumsMutation = { __typename: 'RootMutationType' } & {
  packageAlbums: Maybe<
    { __typename: 'PackageAlbumsPayload' } & Pick<PackageAlbumsPayload, 'result'> & {
        errors: Maybe<
          Array<
            | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
            | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
            | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
            | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
                UniqueIsrcPublicationError,
                'message' | 'path' | 'reason' | 'isrc'
              > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
            | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
          >
        >
      }
  >
}

export type PackageAlbumsWithoutStreamsMutationVariables = Exact<{
  input: InputMaybe<PackageAlbumsWithoutStreamInput>
}>

export type PackageAlbumsWithoutStreamsMutation = { __typename: 'RootMutationType' } & {
  packageAlbumsWithoutStream: Maybe<
    { __typename: 'PackageAlbumsWithoutStreamPayload' } & Pick<PackageAlbumsWithoutStreamPayload, 'result'> & {
        errors: Maybe<
          Array<
            | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
            | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
            | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
            | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
                UniqueIsrcPublicationError,
                'message' | 'path' | 'reason' | 'isrc'
              > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
            | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
          >
        >
      }
  >
}

export type PackagerStatusQueryVariables = Exact<{ [key: string]: never }>

export type PackagerStatusQuery = { __typename: 'RootQueryType' } & {
  packageAlbumsStatus: Maybe<
    { __typename: 'PackageAlbumsStatusData' } & Pick<PackageAlbumsStatusData, 'status'> & {
        lastRunResult: Maybe<
          { __typename: 'LastRunResult' } & Pick<
            LastRunResult,
            'albumsPackagedTotal' | 'albumsPackagedErrors' | 'albumsPackagedSuccesses'
          > & {
              albumStatuses: Array<
                { __typename: 'AlbumStatus' } & Pick<AlbumStatus, 'atmosUpc' | 'isSuccess' | 'message' | 'stereoUpc'>
              >
            }
        >
      }
  >
}

export type ImporterStatusQueryVariables = Exact<{ [key: string]: never }>

export type ImporterStatusQuery = { __typename: 'RootQueryType' } & {
  importerStatus: Maybe<
    { __typename: 'ImporterStatus' } & Pick<ImporterStatus, 'state' | 'importStartedAt'> & {
        progress: Maybe<{ __typename: 'ImporterProgress' } & Pick<ImporterProgress, 'processed' | 'total'>>
        albumResults: Array<
          { __typename: 'ImporterAlbumResult' } & Pick<
            ImporterAlbumResult,
            'upc' | 'atmosUpc' | 'title' | 'importStatus' | 'importResult' | 'importMessage' | 'importRejectedIds'
          >
        >
        importSummary: Maybe<
          { __typename: 'ImporterImportSummary' } & Pick<
            ImporterImportSummary,
            | 'releasesInFile'
            | 'releasesSkipSubRelease'
            | 'releasesImport'
            | 'albumsSuccess'
            | 'albumsInserted'
            | 'albumsUpdated'
            | 'albumsRejected'
            | 'albumsError'
            | 'albumsUnknownStatus'
          >
        >
        lastRunStatus: Maybe<
          { __typename: 'ImporterLastRunStatus' } & Pick<
            ImporterLastRunStatus,
            'message' | 'status' | 'importStartedAt' | 'importFinishedAt'
          >
        >
      }
  >
}

export type UpdateLiveConcertSettingsMutationVariables = Exact<{
  input: UpdateLiveConcertSettingsInput
}>

export type UpdateLiveConcertSettingsMutation = { __typename: 'RootMutationType' } & {
  updateLiveConcertSettings: Maybe<
    { __typename: 'UpdateLiveConcertSettingsPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<{ __typename: 'LiveConcertSettings' } & Pick<LiveConcertSettings, 'cdnUrl'>>
    }
  >
}

export type LiveConcertSettingsQueryVariables = Exact<{ [key: string]: never }>

export type LiveConcertSettingsQuery = { __typename: 'RootQueryType' } & {
  liveConcertSettings: Maybe<{ __typename: 'LiveConcertSettings' } & Pick<LiveConcertSettings, 'cdnUrl'>>
}

export type PlayoutInfoFragment = { __typename: 'Playout' } & Pick<
  Playout,
  'channelId' | 'clipTime' | 'description' | 'endTime' | 'id' | 'setChannelSchedule' | 'startTime' | 'state'
>

export type SetScheduledPlayoutsMutationVariables = Exact<{
  input: StreamingSetPlayoutsInput
}>

export type SetScheduledPlayoutsMutation = { __typename: 'RootMutationType' } & {
  streamingSetPlayouts: Maybe<
    { __typename: 'StreamingSetPlayoutsPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<UniqueIsrcPublicationError, 'message' | 'path'>)
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path'>)
        >
      >
      result: Maybe<
        { __typename: 'PlayoutData' } & {
          playouts: Array<
            { __typename: 'Playout' } & Pick<
              Playout,
              'channelId' | 'clipTime' | 'description' | 'endTime' | 'id' | 'setChannelSchedule' | 'startTime' | 'state'
            >
          >
        }
      >
    }
  >
}

export type ScheduledPlayoutsQueryVariables = Exact<{ [key: string]: never }>

export type ScheduledPlayoutsQuery = { __typename: 'RootQueryType' } & {
  streamingChannels: Maybe<
    { __typename: 'PlayoutChannelData' } & {
      channels: Array<{ __typename: 'PlayoutChannel' } & Pick<PlayoutChannel, 'name' | 'id'>>
    }
  >
  streamingScheduledPlayouts: Maybe<
    { __typename: 'PlayoutData' } & {
      playouts: Array<
        { __typename: 'Playout' } & Pick<
          Playout,
          'channelId' | 'clipTime' | 'description' | 'endTime' | 'id' | 'setChannelSchedule' | 'startTime' | 'state'
        >
      >
    }
  >
}

export type ReindexSearchIndexMutationVariables = Exact<{
  input: ReindexSearchInput
}>

export type ReindexSearchIndexMutation = { __typename: 'RootMutationType' } & {
  reindexSearch: Maybe<
    { __typename: 'ReindexSearchPayload' } & Pick<ReindexSearchPayload, 'result'> & {
        errors: Maybe<
          Array<
            | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
            | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
            | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
            | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
                UniqueIsrcPublicationError,
                'message' | 'path' | 'reason' | 'isrc'
              > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
            | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
          >
        >
      }
  >
}

export type DeleteFromSearchIndexMutationVariables = Exact<{
  input: DeleteFromSearchIndexInput
}>

export type DeleteFromSearchIndexMutation = { __typename: 'RootMutationType' } & {
  deleteFromSearchIndex: Maybe<
    { __typename: 'DeleteFromSearchIndexPayload' } & Pick<DeleteFromSearchIndexPayload, 'result'> & {
        errors: Maybe<
          Array<
            | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
            | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
            | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
            | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
                UniqueIsrcPublicationError,
                'message' | 'path' | 'reason' | 'isrc'
              > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
            | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
          >
        >
      }
  >
}

export type UniqueIsrcPublicationErrorFieldsFragment = { __typename: 'UniqueIsrcPublicationError' } & Pick<
  UniqueIsrcPublicationError,
  'reason' | 'isrc'
> & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> }

export type PublicationErrorFieldsFragment = { __typename: 'PublicationError' } & Pick<PublicationError, 'reason'>

export type ValidationErrorFieldsFragment = { __typename: 'ValidationError' } & Pick<
  ValidationError,
  'field' | 'validation'
>

type AdminApiError_AuthenticationError_Fragment = { __typename: 'AuthenticationError' } & Pick<
  AuthenticationError,
  'code' | 'message' | 'path'
>

type AdminApiError_BasicError_Fragment = { __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>

type AdminApiError_PublicationError_Fragment = { __typename: 'PublicationError' } & Pick<
  PublicationError,
  'message' | 'path' | 'reason'
>

type AdminApiError_UniqueIsrcPublicationError_Fragment = { __typename: 'UniqueIsrcPublicationError' } & Pick<
  UniqueIsrcPublicationError,
  'message' | 'path' | 'reason' | 'isrc'
> & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> }

type AdminApiError_ValidationError_Fragment = { __typename: 'ValidationError' } & Pick<
  ValidationError,
  'message' | 'path' | 'field' | 'validation'
>

export type AdminApiErrorFragment =
  | AdminApiError_AuthenticationError_Fragment
  | AdminApiError_BasicError_Fragment
  | AdminApiError_PublicationError_Fragment
  | AdminApiError_UniqueIsrcPublicationError_Fragment
  | AdminApiError_ValidationError_Fragment

export type AllLocalesFragment = { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>

export type PictureInfoFragment = { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>

export type ReferencedPicturesFragment = { __typename: 'PictureConnection' } & {
  edges: Array<
    { __typename: 'PictureEdge' } & Pick<PictureEdge, 'status'> & {
        node: { __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>
      }
  >
}

export type ReferencedPartnersFragment = { __typename: 'CorePartnerConnection' } & {
  edges: Array<
    { __typename: 'CorePartnerEdge' } & Pick<CorePartnerEdge, 'status'> & {
        node: { __typename: 'CorePartner' } & Pick<CorePartner, 'id' | 'type'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          }
      }
  >
}

export type ReferencedGroupsFragment = { __typename: 'CoreGroupConnection' } & {
  edges: Array<
    { __typename: 'CoreGroupEdge' } & Pick<CoreGroupEdge, 'status'> & {
        node: { __typename: 'CoreGroup' } & Pick<CoreGroup, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          }
      }
  >
}

export type ReferencedArtistsFragment = { __typename: 'CoreArtistConnection' } & {
  edges: Array<
    { __typename: 'CoreArtistEdge' } & Pick<CoreArtistEdge, 'status'> & {
        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          }
      }
  >
}

export type ReferencedArtistsWithRoleFragment = { __typename: 'CoreArtistWithRoleConnection' } & {
  edges: Array<
    { __typename: 'CoreArtistWithRoleEdge' } & Pick<CoreArtistWithRoleEdge, 'status'> & {
        node: { __typename: 'CoreArtist' } & Pick<CoreArtist, 'id'> & {
            name: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'en'>
          }
        role: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype'>
      }
  >
}

export type ReferencedRolesFragment = { __typename: 'CoreRoleConnection' } & {
  edges: Array<
    { __typename: 'CoreRoleEdge' } & Pick<CoreRoleEdge, 'status'> & {
        node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
      }
  >
}

export type ReferencedListRolesFragment = { __typename: 'CoreRoleListConnection' } & {
  edges: Array<
    { __typename: 'CoreRoleListEdge' } & {
      node: { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>
    }
  >
}

export type ReferencedAlbumsFragment = { __typename: 'AlbumConnection' } & {
  edges: Array<
    { __typename: 'AlbumEdge' } & Pick<AlbumEdge, 'status'> & {
        node: { __typename: 'Album' } & Pick<Album, 'id'> & {
            title: { __typename: 'TranslatedString' } & Pick<TranslatedString, 'de' | 'en' | 'ja'>
          }
      }
  >
}

export type PageInfoFragment = { __typename: 'PageInfo' } & Pick<
  PageInfo,
  'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'
>

export type DraftEditorFragment = { __typename: 'AdminUser' } & Pick<AdminUser, 'id' | 'name' | 'email'>

export type RoleInfoFragment = { __typename: 'CoreRole' } & Pick<CoreRole, 'id' | 'type' | 'subtype' | 'displayName'>

export type StreamInfoFragment = { __typename: 'Stream' } & Pick<Stream, 'id' | 'url' | 'mvVideoUrl'>

export type CreatePictureMutationVariables = Exact<{
  input: InputMaybe<CreatePictureInput>
}>

export type CreatePictureMutation = { __typename: 'RootMutationType' } & {
  createPicture: Maybe<
    { __typename: 'CreatePicturePayload' } & {
      result: Maybe<{ __typename: 'Picture' } & Pick<Picture, 'id' | 'type' | 'url'>>
    }
  >
}

export type StartTranscodingBatchMutationVariables = Exact<{
  input: TranscodingStartJobBatchInput
}>

export type StartTranscodingBatchMutation = { __typename: 'RootMutationType' } & {
  transcodingStartJobBatch: Maybe<
    { __typename: 'TranscodingStartJobBatchPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<Array<{ __typename: 'JobStatus' } & Pick<JobStatus, 'isrc' | 'message' | 'status'>>>
    }
  >
}

export type GetSourceFilesBatchQueryVariables = Exact<{
  isrcs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type GetSourceFilesBatchQuery = { __typename: 'RootQueryType' } & {
  transcodingGetSourceFilesBatch: Maybe<
    Array<
      { __typename: 'TranscodingGetSourceFilesBatchPayload' } & Pick<TranscodingGetSourceFilesBatchPayload, 'isrc'> & {
          sourceFiles: Array<{ __typename: 'SourceFile' } & Pick<SourceFile, 'fileName' | 'size' | 'updatedAt'>>
        }
    >
  >
}

export type GetJobStatusBatchQueryVariables = Exact<{
  isrcs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type GetJobStatusBatchQuery = { __typename: 'RootQueryType' } & {
  transcodingGetJobStatusBatch: Array<
    { __typename: 'JobStatus' } & Pick<JobStatus, 'isrc' | 'message' | 'status' | 'url'>
  >
}

export type UserInfoFragment = { __typename: 'User' } & Pick<
  User,
  | 'id'
  | 'externalId'
  | 'email'
  | 'amazonCustomerId'
  | 'appleAppAccountToken'
  | 'title'
  | 'firstName'
  | 'lastName'
  | 'status'
  | 'accountConfirmed'
  | 'dateRegistered'
  | 'locale'
  | 'country'
  | 'affiliateGroup'
  | 'customerGroup'
  | 'registrationCountry'
  | 'lastAuthenticatedAt'
  | 'sendNewsletter'
  | 'hasTrialedBefore'
  | 'stripeCurrency'
  | 'stripeCustomerId'
  | 'mixpanelTrackingAllowed'
  | 'metaTrackingAllowed'
> & {
    ticket: Maybe<
      { __typename: 'Ticket' } & Pick<
        Ticket,
        | 'amazonOriginalReceiptId'
        | 'amazonProductId'
        | 'appleOriginalTransactionId'
        | 'appleProductId'
        | 'canceledAt'
        | 'deletedAt'
        | 'insertedAt'
        | 'interval'
        | 'paymentMethodType'
        | 'paymentProvider'
        | 'renewedAt'
        | 'status'
        | 'stripePriceId'
        | 'stripeProductId'
        | 'stripeSubscriptionId'
        | 'ticketType'
        | 'trialEndsAt'
        | 'validUntil'
        | 'validUntilCalculated'
        | 'voucherCode'
        | 'voucherDuration'
        | 'voucherDurationInMonths'
        | 'voucherId'
        | 'voucherPercentageOff'
      >
    >
    inactiveTickets: Maybe<
      Array<
        Maybe<
          { __typename: 'Ticket' } & Pick<
            Ticket,
            | 'amazonOriginalReceiptId'
            | 'amazonProductId'
            | 'appleOriginalTransactionId'
            | 'appleProductId'
            | 'canceledAt'
            | 'deletedAt'
            | 'insertedAt'
            | 'interval'
            | 'paymentMethodType'
            | 'paymentProvider'
            | 'renewedAt'
            | 'status'
            | 'stripePriceId'
            | 'stripeProductId'
            | 'stripeSubscriptionId'
            | 'ticketType'
            | 'trialEndsAt'
            | 'validUntil'
            | 'validUntilCalculated'
            | 'voucherCode'
            | 'voucherDuration'
            | 'voucherDurationInMonths'
            | 'voucherId'
            | 'voucherPercentageOff'
          >
        >
      >
    >
    devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
    postalAddress: Maybe<
      { __typename: 'PostalAddress' } & Pick<
        PostalAddress,
        'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
      >
    >
  }

export type DeleteAudienceUserMutationVariables = Exact<{
  input: DeleteUserInput
}>

export type DeleteAudienceUserMutation = { __typename: 'RootMutationType' } & {
  deleteUser: Maybe<
    { __typename: 'DeleteUserPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'User' } & Pick<
          User,
          | 'id'
          | 'externalId'
          | 'email'
          | 'amazonCustomerId'
          | 'appleAppAccountToken'
          | 'title'
          | 'firstName'
          | 'lastName'
          | 'status'
          | 'accountConfirmed'
          | 'dateRegistered'
          | 'locale'
          | 'country'
          | 'affiliateGroup'
          | 'customerGroup'
          | 'registrationCountry'
          | 'lastAuthenticatedAt'
          | 'sendNewsletter'
          | 'hasTrialedBefore'
          | 'stripeCurrency'
          | 'stripeCustomerId'
          | 'mixpanelTrackingAllowed'
          | 'metaTrackingAllowed'
        > & {
            ticket: Maybe<
              { __typename: 'Ticket' } & Pick<
                Ticket,
                | 'amazonOriginalReceiptId'
                | 'amazonProductId'
                | 'appleOriginalTransactionId'
                | 'appleProductId'
                | 'canceledAt'
                | 'deletedAt'
                | 'insertedAt'
                | 'interval'
                | 'paymentMethodType'
                | 'paymentProvider'
                | 'renewedAt'
                | 'status'
                | 'stripePriceId'
                | 'stripeProductId'
                | 'stripeSubscriptionId'
                | 'ticketType'
                | 'trialEndsAt'
                | 'validUntil'
                | 'validUntilCalculated'
                | 'voucherCode'
                | 'voucherDuration'
                | 'voucherDurationInMonths'
                | 'voucherId'
                | 'voucherPercentageOff'
              >
            >
            inactiveTickets: Maybe<
              Array<
                Maybe<
                  { __typename: 'Ticket' } & Pick<
                    Ticket,
                    | 'amazonOriginalReceiptId'
                    | 'amazonProductId'
                    | 'appleOriginalTransactionId'
                    | 'appleProductId'
                    | 'canceledAt'
                    | 'deletedAt'
                    | 'insertedAt'
                    | 'interval'
                    | 'paymentMethodType'
                    | 'paymentProvider'
                    | 'renewedAt'
                    | 'status'
                    | 'stripePriceId'
                    | 'stripeProductId'
                    | 'stripeSubscriptionId'
                    | 'ticketType'
                    | 'trialEndsAt'
                    | 'validUntil'
                    | 'validUntilCalculated'
                    | 'voucherCode'
                    | 'voucherDuration'
                    | 'voucherDurationInMonths'
                    | 'voucherId'
                    | 'voucherPercentageOff'
                  >
                >
              >
            >
            devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
            postalAddress: Maybe<
              { __typename: 'PostalAddress' } & Pick<
                PostalAddress,
                'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
              >
            >
          }
      >
    }
  >
}

export type BlockAudienceUserMutationVariables = Exact<{
  input: BlockUserInput
}>

export type BlockAudienceUserMutation = { __typename: 'RootMutationType' } & {
  blockUser: Maybe<
    { __typename: 'BlockUserPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'User' } & Pick<
          User,
          | 'id'
          | 'externalId'
          | 'email'
          | 'amazonCustomerId'
          | 'appleAppAccountToken'
          | 'title'
          | 'firstName'
          | 'lastName'
          | 'status'
          | 'accountConfirmed'
          | 'dateRegistered'
          | 'locale'
          | 'country'
          | 'affiliateGroup'
          | 'customerGroup'
          | 'registrationCountry'
          | 'lastAuthenticatedAt'
          | 'sendNewsletter'
          | 'hasTrialedBefore'
          | 'stripeCurrency'
          | 'stripeCustomerId'
          | 'mixpanelTrackingAllowed'
          | 'metaTrackingAllowed'
        > & {
            ticket: Maybe<
              { __typename: 'Ticket' } & Pick<
                Ticket,
                | 'amazonOriginalReceiptId'
                | 'amazonProductId'
                | 'appleOriginalTransactionId'
                | 'appleProductId'
                | 'canceledAt'
                | 'deletedAt'
                | 'insertedAt'
                | 'interval'
                | 'paymentMethodType'
                | 'paymentProvider'
                | 'renewedAt'
                | 'status'
                | 'stripePriceId'
                | 'stripeProductId'
                | 'stripeSubscriptionId'
                | 'ticketType'
                | 'trialEndsAt'
                | 'validUntil'
                | 'validUntilCalculated'
                | 'voucherCode'
                | 'voucherDuration'
                | 'voucherDurationInMonths'
                | 'voucherId'
                | 'voucherPercentageOff'
              >
            >
            inactiveTickets: Maybe<
              Array<
                Maybe<
                  { __typename: 'Ticket' } & Pick<
                    Ticket,
                    | 'amazonOriginalReceiptId'
                    | 'amazonProductId'
                    | 'appleOriginalTransactionId'
                    | 'appleProductId'
                    | 'canceledAt'
                    | 'deletedAt'
                    | 'insertedAt'
                    | 'interval'
                    | 'paymentMethodType'
                    | 'paymentProvider'
                    | 'renewedAt'
                    | 'status'
                    | 'stripePriceId'
                    | 'stripeProductId'
                    | 'stripeSubscriptionId'
                    | 'ticketType'
                    | 'trialEndsAt'
                    | 'validUntil'
                    | 'validUntilCalculated'
                    | 'voucherCode'
                    | 'voucherDuration'
                    | 'voucherDurationInMonths'
                    | 'voucherId'
                    | 'voucherPercentageOff'
                  >
                >
              >
            >
            devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
            postalAddress: Maybe<
              { __typename: 'PostalAddress' } & Pick<
                PostalAddress,
                'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
              >
            >
          }
      >
    }
  >
}

export type UnblockAudienceUserMutationVariables = Exact<{
  input: UnblockUserInput
}>

export type UnblockAudienceUserMutation = { __typename: 'RootMutationType' } & {
  unblockUser: Maybe<
    { __typename: 'UnblockUserPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'User' } & Pick<
          User,
          | 'id'
          | 'externalId'
          | 'email'
          | 'amazonCustomerId'
          | 'appleAppAccountToken'
          | 'title'
          | 'firstName'
          | 'lastName'
          | 'status'
          | 'accountConfirmed'
          | 'dateRegistered'
          | 'locale'
          | 'country'
          | 'affiliateGroup'
          | 'customerGroup'
          | 'registrationCountry'
          | 'lastAuthenticatedAt'
          | 'sendNewsletter'
          | 'hasTrialedBefore'
          | 'stripeCurrency'
          | 'stripeCustomerId'
          | 'mixpanelTrackingAllowed'
          | 'metaTrackingAllowed'
        > & {
            ticket: Maybe<
              { __typename: 'Ticket' } & Pick<
                Ticket,
                | 'amazonOriginalReceiptId'
                | 'amazonProductId'
                | 'appleOriginalTransactionId'
                | 'appleProductId'
                | 'canceledAt'
                | 'deletedAt'
                | 'insertedAt'
                | 'interval'
                | 'paymentMethodType'
                | 'paymentProvider'
                | 'renewedAt'
                | 'status'
                | 'stripePriceId'
                | 'stripeProductId'
                | 'stripeSubscriptionId'
                | 'ticketType'
                | 'trialEndsAt'
                | 'validUntil'
                | 'validUntilCalculated'
                | 'voucherCode'
                | 'voucherDuration'
                | 'voucherDurationInMonths'
                | 'voucherId'
                | 'voucherPercentageOff'
              >
            >
            inactiveTickets: Maybe<
              Array<
                Maybe<
                  { __typename: 'Ticket' } & Pick<
                    Ticket,
                    | 'amazonOriginalReceiptId'
                    | 'amazonProductId'
                    | 'appleOriginalTransactionId'
                    | 'appleProductId'
                    | 'canceledAt'
                    | 'deletedAt'
                    | 'insertedAt'
                    | 'interval'
                    | 'paymentMethodType'
                    | 'paymentProvider'
                    | 'renewedAt'
                    | 'status'
                    | 'stripePriceId'
                    | 'stripeProductId'
                    | 'stripeSubscriptionId'
                    | 'ticketType'
                    | 'trialEndsAt'
                    | 'validUntil'
                    | 'validUntilCalculated'
                    | 'voucherCode'
                    | 'voucherDuration'
                    | 'voucherDurationInMonths'
                    | 'voucherId'
                    | 'voucherPercentageOff'
                  >
                >
              >
            >
            devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
            postalAddress: Maybe<
              { __typename: 'PostalAddress' } & Pick<
                PostalAddress,
                'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
              >
            >
          }
      >
    }
  >
}

export type DeleteUserAddressMutationVariables = Exact<{
  input: DeleteUserAddressInput
}>

export type DeleteUserAddressMutation = { __typename: 'RootMutationType' } & {
  deleteUserAddress: Maybe<
    { __typename: 'DeleteUserAddressPayload' } & {
      errors: Maybe<
        Array<
          | ({ __typename: 'AuthenticationError' } & Pick<AuthenticationError, 'code' | 'message' | 'path'>)
          | ({ __typename: 'BasicError' } & Pick<BasicError, 'message' | 'path'>)
          | ({ __typename: 'PublicationError' } & Pick<PublicationError, 'message' | 'path' | 'reason'>)
          | ({ __typename: 'UniqueIsrcPublicationError' } & Pick<
              UniqueIsrcPublicationError,
              'message' | 'path' | 'reason' | 'isrc'
            > & { conflictingNodes: Array<{ __typename: 'ConflictingNode' } & Pick<ConflictingNode, 'id' | 'type'>> })
          | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'message' | 'path' | 'field' | 'validation'>)
        >
      >
      result: Maybe<
        { __typename: 'User' } & Pick<
          User,
          | 'id'
          | 'externalId'
          | 'email'
          | 'amazonCustomerId'
          | 'appleAppAccountToken'
          | 'title'
          | 'firstName'
          | 'lastName'
          | 'status'
          | 'accountConfirmed'
          | 'dateRegistered'
          | 'locale'
          | 'country'
          | 'affiliateGroup'
          | 'customerGroup'
          | 'registrationCountry'
          | 'lastAuthenticatedAt'
          | 'sendNewsletter'
          | 'hasTrialedBefore'
          | 'stripeCurrency'
          | 'stripeCustomerId'
          | 'mixpanelTrackingAllowed'
          | 'metaTrackingAllowed'
        > & {
            ticket: Maybe<
              { __typename: 'Ticket' } & Pick<
                Ticket,
                | 'amazonOriginalReceiptId'
                | 'amazonProductId'
                | 'appleOriginalTransactionId'
                | 'appleProductId'
                | 'canceledAt'
                | 'deletedAt'
                | 'insertedAt'
                | 'interval'
                | 'paymentMethodType'
                | 'paymentProvider'
                | 'renewedAt'
                | 'status'
                | 'stripePriceId'
                | 'stripeProductId'
                | 'stripeSubscriptionId'
                | 'ticketType'
                | 'trialEndsAt'
                | 'validUntil'
                | 'validUntilCalculated'
                | 'voucherCode'
                | 'voucherDuration'
                | 'voucherDurationInMonths'
                | 'voucherId'
                | 'voucherPercentageOff'
              >
            >
            inactiveTickets: Maybe<
              Array<
                Maybe<
                  { __typename: 'Ticket' } & Pick<
                    Ticket,
                    | 'amazonOriginalReceiptId'
                    | 'amazonProductId'
                    | 'appleOriginalTransactionId'
                    | 'appleProductId'
                    | 'canceledAt'
                    | 'deletedAt'
                    | 'insertedAt'
                    | 'interval'
                    | 'paymentMethodType'
                    | 'paymentProvider'
                    | 'renewedAt'
                    | 'status'
                    | 'stripePriceId'
                    | 'stripeProductId'
                    | 'stripeSubscriptionId'
                    | 'ticketType'
                    | 'trialEndsAt'
                    | 'validUntil'
                    | 'validUntilCalculated'
                    | 'voucherCode'
                    | 'voucherDuration'
                    | 'voucherDurationInMonths'
                    | 'voucherId'
                    | 'voucherPercentageOff'
                  >
                >
              >
            >
            devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
            postalAddress: Maybe<
              { __typename: 'PostalAddress' } & Pick<
                PostalAddress,
                'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
              >
            >
          }
      >
    }
  >
}

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetUserByIdQuery = { __typename: 'RootQueryType' } & {
  user: Maybe<
    { __typename: 'User' } & Pick<
      User,
      | 'id'
      | 'externalId'
      | 'email'
      | 'amazonCustomerId'
      | 'appleAppAccountToken'
      | 'title'
      | 'firstName'
      | 'lastName'
      | 'status'
      | 'accountConfirmed'
      | 'dateRegistered'
      | 'locale'
      | 'country'
      | 'affiliateGroup'
      | 'customerGroup'
      | 'registrationCountry'
      | 'lastAuthenticatedAt'
      | 'sendNewsletter'
      | 'hasTrialedBefore'
      | 'stripeCurrency'
      | 'stripeCustomerId'
      | 'mixpanelTrackingAllowed'
      | 'metaTrackingAllowed'
    > & {
        ticket: Maybe<
          { __typename: 'Ticket' } & Pick<
            Ticket,
            | 'amazonOriginalReceiptId'
            | 'amazonProductId'
            | 'appleOriginalTransactionId'
            | 'appleProductId'
            | 'canceledAt'
            | 'deletedAt'
            | 'insertedAt'
            | 'interval'
            | 'paymentMethodType'
            | 'paymentProvider'
            | 'renewedAt'
            | 'status'
            | 'stripePriceId'
            | 'stripeProductId'
            | 'stripeSubscriptionId'
            | 'ticketType'
            | 'trialEndsAt'
            | 'validUntil'
            | 'validUntilCalculated'
            | 'voucherCode'
            | 'voucherDuration'
            | 'voucherDurationInMonths'
            | 'voucherId'
            | 'voucherPercentageOff'
          >
        >
        inactiveTickets: Maybe<
          Array<
            Maybe<
              { __typename: 'Ticket' } & Pick<
                Ticket,
                | 'amazonOriginalReceiptId'
                | 'amazonProductId'
                | 'appleOriginalTransactionId'
                | 'appleProductId'
                | 'canceledAt'
                | 'deletedAt'
                | 'insertedAt'
                | 'interval'
                | 'paymentMethodType'
                | 'paymentProvider'
                | 'renewedAt'
                | 'status'
                | 'stripePriceId'
                | 'stripeProductId'
                | 'stripeSubscriptionId'
                | 'ticketType'
                | 'trialEndsAt'
                | 'validUntil'
                | 'validUntilCalculated'
                | 'voucherCode'
                | 'voucherDuration'
                | 'voucherDurationInMonths'
                | 'voucherId'
                | 'voucherPercentageOff'
              >
            >
          >
        >
        devices: Maybe<Array<Maybe<{ __typename: 'Device' } & Pick<Device, 'deviceInfo' | 'externalId'>>>>
        postalAddress: Maybe<
          { __typename: 'PostalAddress' } & Pick<
            PostalAddress,
            'firstLine' | 'secondLine' | 'city' | 'zipCode' | 'province' | 'district' | 'country'
          >
        >
      }
  >
}
